import React, {useEffect, useState} from 'react';
import {Directory} from '../../services';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Loader from '../../utils/loader';
import Export from "../../services/Export";
import FilterModal from "./FilterModal";

const ReportPage = () => {
    const [loading, setLoading] = useState(false);
    const [reportType, setReportType] = useState();
    const [reportTitle, setReportTitle] = useState();
    const [showFilter, setShowFilter] = useState(false);

    const handleDownload = () => {
        setLoading(true);
        Export.exportConfirmedDriverOrders()
            .then((response) => {
                setLoading(false);
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    const downloadReport = (filter) => {
        if (reportType === 'report2')
            downloadPriceComparisonOrders(filter);
        else if (reportType === 'report3')
            downloadProfitFromOrders(filter);
        else if(reportType==='report4')
            downloadInsuranceReport(filter);
    }

    const downloadPriceComparisonOrders = (filter) => {
        setLoading(true);
        setShowFilter(false);
        Export.exportPriceComparisonOrders('all', filter.startCreateAt, filter.endCreateAt)
            .then((response) => {
                setLoading(false);
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                setLoading(false);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    const downloadProfitFromOrders = (filter) => {
        setLoading(true);
        setShowFilter(false);
        Export.exportProfitFromOrders('all', filter.startShippingDate, filter.endShippingDate)
            .then((response) => {
                setLoading(false);
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                setLoading(false);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    const handleFilterModal = (type, title) => {
        setReportType(type);
        setReportTitle(title);
        setShowFilter(true);
    }

    const downloadInsuranceReport = (filter) => {
        setShowFilter(false);
        setLoading(true);
        Export.exportInsurance(filter.startDate, filter.endDate)
            .then((response) => {
                setLoading(false);
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                setLoading(false);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>Отчеты</span>
            </div>
            <div style={{marginTop: '16px'}}>
                <ListGroup>
                    <ListGroup.Item style={{padding: '16px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>Количества заказов по месяцам</div>
                            <div>
                                <Button size="sm" variant="outline-secondary" onClick={() => handleDownload()}
                                        style={{fontSize: '11px', marginLeft: '12px'}}>
                                    Скачать
                                </Button>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item style={{padding: '16px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>Отчет о цене клиента и цене алгоритма</div>
                            <div>
                                <Button size="sm" variant="outline-secondary"
                                        onClick={() => handleFilterModal('report2', 'Отчет о цене клиента и цене алгоритма')}
                                        style={{fontSize: '11px', marginLeft: '12px'}}>
                                    Скачать
                                </Button>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item style={{padding: '16px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>Отчет о прибыльности заказов за период для анализа сделок</div>
                            <div>
                                <Button size="sm" variant="outline-secondary"
                                        onClick={() => handleFilterModal('report3', 'Отчет о прибыльности заказов за период для анализа сделок')}
                                        style={{fontSize: '11px', marginLeft: '12px'}}>
                                    Скачать
                                </Button>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item style={{padding: '16px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>Отчет по страхованию</div>
                            <div>
                                <Button size="sm" variant="outline-secondary"
                                        onClick={() => handleFilterModal('report4', 'Отчет по страхованию')}
                                        style={{fontSize: '11px', marginLeft: '12px'}}>
                                    Скачать
                                </Button>
                            </div>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
                {loading && <Loader/>}
                {showFilter && <FilterModal reportType={reportType} reportTitle={reportTitle}
                                            onClose={() => setShowFilter(false)} onDownload={downloadReport}/>}
            </div>
        </>
    );
}

export default ReportPage;
