import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {Document, Page, pdfjs} from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {User} from "../../../services";

const BalanceModal = ({item, onClose}) => {
    const [balance, setBalance] = useState();
    const handleSaveBalance = () => {
        if (!balance) return;
        User.setBalance(item.id, balance)
            .then(response => {
                onClose(true);
            })
            .catch(error => {
                alert("Произошла ошибка, попробуйте позже");
                console.log(error);
            });
    }

    return (
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '340px',
                margin: '30px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff'}}>Пополнить личный счет</div>
                    <div style={{cursor: 'pointer'}} onClick={() => onClose(false)}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    <div style={{padding: '16px'}}>
                        <div>
                            <Form.Control value={balance} onChange={(e) => setBalance(e.target.value)} size="lg"
                                          type="number"
                                          placeholder="Сумма" style={{fontSize: '16px', padding: '12px 16px'}}/>
                        </div>
                        <div
                            style={{marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => onClose(false)} variant="light" style={{marginRight: '20px'}}>
                                Отменить
                            </Button>
                            <Button onClick={handleSaveBalance} variant="primary">Отправить</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default BalanceModal;
