import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertFromRaw, convertToRaw, EditorState, convertFromHTML} from "draft-js";
import draftToHtml from "draftjs-to-html";
import News from "../../../services/News";
import {useSelector} from "react-redux";
import './createNewsModal.scss';
import Loader from "../../../utils/loader";
import moment from "moment";
import {LANG_LIST, NEWS_TYPES} from "../models";

const CreateNewsModal = ({editMode, item, onClose}) => {
    const {user} = useSelector(state => state);
    const [title, setTitle] = useState(item ? item.title : '');
    const [shortDescription, setShortDescription] = useState(item ? item?.shortDescription : '')
    const [type, setType] = useState(item ? item.type : 'news');
    const [lang, setLang] = useState(item ? item.lang : 'kz');
    const [url, setUrl] = useState(item ? item?.url : '');

    const [metaName, setMetaName] = useState(item ? item?.metaName : '');
    const [metaContent, setMetaContent] = useState(item ? item?.metaContent : '');
    const [h1Title, setH1Title] = useState(item ? item?.h1Title : '');

    const [updatedAt, setUpdatedAt] = useState(item ? moment(item.updatedAt).format('yyyy-MM-DD') : '');
    const [errorTitle, setErrorTitle] = useState();
    const [successView, setSuccessView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();
    const [fileURL, setFileURL] = useState();
    const [photoData, setPhotoData] = useState(undefined);
    const [editorState, setEditorState] = useState(() => {
        return item ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(item.description))) : EditorState.createEmpty();
    });

    const [description, setDescription] = useState();

    useEffect(() => {
        if (item) {
            getPhoto();
        }
    }, [item])

    const getPhoto = () => {
        News.getPhoto(item.id)
            .then(res => res.data)
            .then(data => {
                setPhotoData(data)
            })
            .finally(() => {
                // setLoading(false);
            })
    }

    const onChangeInput = e => {
        if (!e?.target?.files[0]) {
            setFileURL(undefined);
            setFile(undefined);
            return;
        }
        setPhotoData(undefined);
        setFile(e.target.files[0]);
        const _fileURL = URL.createObjectURL(e.target.files[0]);
        setFileURL(_fileURL);
    }

    const uploadFile = (file, newsId) => {
        const formData = new FormData()
        formData.append("file", file);
        formData.append("type", 0);
        formData.append("newsId", newsId);
        News.uploadPhoto(formData)
            .then(res => res.data)
            .then(() => {
                setLoading(false);
                onClose(true);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onSubmit = () => {
        // Convert editor content to HTML
        const contentState = editorState.getCurrentContent();
        const htmlContent = convertToRaw(contentState);
        const rawContentState = convertToRaw(contentState);
        const markup = draftToHtml(rawContentState);
        const uDate = new Date(updatedAt);

        if (markup) {
            setErrorTitle();
            if (!item) {
                setLoading(true);
                News.create({
                    user: user.username,
                    title: title,
                    url: url,
                    shortDescription: shortDescription,
                    description: markup,
                    updatedAt: uDate,
                    metaName:metaName,
                    metaContent:metaContent,
                    h1Title:h1Title,
                    views: 0,
                    order: 0,
                    type: type,
                    lang: lang
                }).then(response => {
                    if (file) {
                        uploadFile(file, response.data.id)
                    } else {
                        setLoading(false);
                        onClose(true);
                    }
                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                });
            } else {
                setLoading(true);
                News.update(item.id, {
                    user: user.username,
                    title: title,
                    url: url,
                    shortDescription: shortDescription,
                    description: markup,
                    updatedAt: uDate,
                    metaName:metaName,
                    metaContent:metaContent,
                    h1Title:h1Title,
                    views: 0,
                    order: 0,
                    type: type,
                    lang: lang
                }).then(response => {
                    if (file) {
                        uploadFile(file, response.data.id)
                    } else {
                        setLoading(false);
                        onClose(true);
                    }
                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                });
            }
        } else {
            setErrorTitle('Необходимо заполнить все поля')
        }
    }

    return (
        <div className='modal-overlay'>
            <div className='news-modal'>
                <div className='news-modal__header'>
                    <h3>
                        {item ? 'Редактировать' : 'Cоздание новостей'}
                    </h3>
                    <span onClick={() => onClose(false)}>X</span>
                </div>
                <div className='news-modal__content'>
                    <div className='content-row'>
                        <label htmlFor='upload-photo' className='select-photo'>
                            {(!file && !photoData) && <img className='photo' src='/images/photo.png' alt='photo'/>}
                            {fileURL && <img className='photo' src={fileURL}/>}
                            {photoData && <img className='photo'
                                               src={photoData?.base64 ? `data:${photoData?.contentType};base64, ${photoData?.base64}` : '/images/no-photo.png'}
                                               alt="news photo"/>}
                            <input className='hidden' type="file" id="upload-photo"
                                   accept="image/png, image/gif, image/jpeg"
                                   onChange={onChangeInput}/>
                        </label>
                        <div className="content-column">
                            <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Тип</Form.Label>
                                    <Form.Control as="select" value={type} onChange={e => {
                                        setType(e.target.value)
                                    }}>
                                        {NEWS_TYPES.map(item => {
                                            return (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Язык</Form.Label>
                                    <Form.Control as="select" value={lang} onChange={e => {
                                        setLang(e.target.value)
                                    }}>
                                        {LANG_LIST.map(item => {
                                            return (
                                                <option key={item.value} value={item.value}>{item.name}</option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Дата</Form.Label>
                                    <Form.Control type="date" value={updatedAt} placeholder="Введите дата"
                                                  onChange={(e) => setUpdatedAt(e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Url</Form.Label>
                                    <Form.Control as="input" value={url} placeholder="Введите url"
                                                  onChange={(e) => setUrl(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <Form.Group className="mb-3 w-100">
                                <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Заголовок</Form.Label>
                                <Form.Control as="textarea" rows={3} value={title}
                                              onChange={(e) => setTitle(e.target.value)}
                                              placeholder="Введите заголовок"/>
                            </Form.Group>
                        </div>
                    </div>
                    <div className='content-row'>
                        <div className='content-column'>
                            <Form.Group className="mb-3 w-100">
                                <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Краткое описание</Form.Label>
                                <Form.Control as="textarea" rows={3} value={shortDescription}
                                              onChange={(e) => setShortDescription(e.target.value)}
                                              placeholder="Введите краткое описание"/>
                            </Form.Group>
                        </div>
                    </div>

                    <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                        <Form.Group className="mb-3 w-100">
                            <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Мета теги(title)</Form.Label>
                            <Form.Control as="input" value={metaName} placeholder="Введите мета теги"
                                          onChange={(e) => setMetaName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3 w-100">
                            <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Мета теги(description)</Form.Label>
                            <Form.Control as="input" value={metaContent} placeholder="Введите мета теги"
                                          onChange={(e) => setMetaContent(e.target.value)}/>
                        </Form.Group>
                    </div>

                    <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                        <Form.Group className="mb-3 w-100">
                            <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>H1 заголовок </Form.Label>
                            <Form.Control as="input" value={h1Title} placeholder="Введите H1 заголовок"
                                          onChange={(e) => setH1Title(e.target.value)}/>
                        </Form.Group>
                    </div>

                    <div className="editor">
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                        />
                    </div>
                    {errorTitle ? <div>{errorTitle}</div> : null}
                    <div className='btn-bottom'>
                        <Button onClick={onSubmit} variant="success">Сохранить</Button>
                        <Button onClick={() => onClose(false)} variant="secondary">Отменить</Button>
                    </div>
                </div>
                {loading && <Loader/>}
            </div>
        </div>
    );
}

export default CreateNewsModal;
