import axios from "axios";

const apiClient = axios.create({
    baseURL: 'https://admin-service.biny.co/api/'
});

apiClient.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('authData') || "{}").token;

  return ({
    ...config,
    headers: {
      Authorization: `Bearer ${token}`
    },
  })
},
  error => Promise.reject(error),
);

apiClient.interceptors.response.use((response) =>
  response,
  async (error) => {
    if (error && error.response && error.response.status && error.response.status === 401) {
      localStorage.removeItem('authData')
      window.location.href = "/login";
      return Promise.reject();
    }
    const errorResponse = (((error || {}).response || {}).data || {}) || error;
    return Promise.reject(errorResponse);
  },
);

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy };
