import React, {useEffect, useState, useRef} from "react";
import {
    MapContainer,
    Tooltip,
    TileLayer,
    Marker,
    Popup,
    Polyline,
    CircleMarker,
} from "react-leaflet";
import Button from "react-bootstrap/Button";
import "leaflet/dist/leaflet.css";
import {Order, User, File} from "../../services";
import {numberToFinanceFormat, phoneNumberFormat} from "../../utils";
import L from "leaflet";
import moment from "moment";

import generatePOA from "../../utils/generate_poa";
import user from "../../services/User";
import {useSelector} from "react-redux";
import FileUploader from "./FileUploader";
import Vehicle from "../../services/Vehicle";
import DriverInfo from "../drivers/Components/DriverInfo";
import OrderDetails from "./Components/OrderDetails";
import LoadingCargoPhotos from "./Components/LoadingCargoPhotos";
import UnloadingCargoPhotos from "./Components/UnloadingCargoPhotos";
import StatusInfo from "./Components/StatusInfo";
import './orderModal.scss';
import CargoDetails from "./Components/CargoDetails";
import OrderView from "../../services/OrderView";
import OrderViews from "./Components/OrderViews";
import CheckCities from "./Components/CheckCities";
import Tender from "../../services/Tender";
import TenderDetails from "./Components/TenderDetails";
import UpdateShippingUploadingDate from "./Components/UpdateShippingUploadingDate";
import Feedback from "./Components/Feedback";

const OrderInfoModal = ({item, onClose, getOrders}) => {
    const {user} = useSelector(state => state);
    const {driverId, orderId} = item;
    const [client, setClient] = useState();
    const [driver, setDriver] = useState();
    const [vehicle, setVehicle] = useState();
    const [recipient, setRecipient] = useState();
    const [cargoDetails, setCargoDetails] = useState();
    // const [tender, setTender] = useState();

    const [orderView, setOrderView] = useState([]);
    const [cityFrom, setCityFrom] = useState();
    const [cityTo, setCityTo] = useState();
    const [driverPosition, setDriverPosition] = useState();

    const [coordinate, setСoordinate] = useState();
    const [orderStateData, setOrderStateData] = useState([]);
    const [driverPhotos, setDriverPhotos] = useState([]);
    const [isCheckCities, setIsCheckCities] = useState(false);

    useEffect(() => {
        if (item) {
            User.getById(item.userId)
                .then((response) => {
                    setClient(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });

            Order.getRecipient(item.recipientId)
                .then((response) => {
                    if (response.status == 200) {
                        setRecipient(response.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // Cargo details
            Order.getCargo(item.cargoId)
                .then((response) => {

                    if (response.status == 200) {
                        setCargoDetails(response.data);
                    } else {
                        setCargoDetails({});
                    }

                })
                .catch((error) => {
                    console.log(error);
                });

            // LOCATION
            Order.getCity(item.cityIdFrom)
                .then((response) => {
                    if (response.status == 200) {
                        setCityFrom(response.data);
                    } else {
                        setCityFrom();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            Order.getCity(item.cityIdTo)
                .then((response) => {
                    if (response.status == 200) {
                        setCityTo(response.data);
                    } else {
                        setCityTo();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // order views
            OrderView.getByOrderId(item.id).then(response => {
                console.log('order views', response.data);
                if (response.data && response.data.length > 0) {
                    setOrderView(response.data);
                } else {
                    setOrderView([]);
                }

            }).catch(error => {
                setOrderView([]);
            })
        }
    }, []);

    useEffect(() => {
        if (item) {
            if ((item.status === "onthetrip" || item.status === "completed")
                && item.driverId) {
                setDriverPosition([]);
                Order.getLastPosition(item.id, item.driverId)
                    .then((response) => {
                        setDriverPosition([
                            response.data.latitude,
                            response.data.longitude,
                        ]);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

            orderStates();
            getCoordinates();
            driverOrderPhotos();

            if (item.driverId) {
                User.getById(item.driverId)
                    .then((response) => {
                        setDriver(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                Vehicle.getByDriverId(item.driverId).then(response => {
                    setVehicle(response.data);
                }).catch(error => {
                    console.log('get vehicle type error', error);
                })
            }
        }
    }, [item]);

    const orderStates = () => {
        Order.orderStates(item.id).then((response) => {
            setOrderStateData(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }
    const getCoordinates = () => {
        Order.getCoordinates(item.number).then((response) => {
            if (response.status == 200) {
                const post = [];
                if (response.data && response.data.length) {
                    response.data.map((m) => {
                        post.push({
                            lat: m.latitude,
                            lng: m.longitude,
                            date: m.createdAtStr ? m.createdAtStr : ""
                            // date: m.createdAt ? m.createdAt.split(".")[0] : "",
                        });
                    });
                }
                if (post.length) setСoordinate(post);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const driverOrderPhotos = () => {
        File.getOrderDocsDriverPhotos(item.id).then(response => {
            if (response.status === 200 || response.status === 201) {
                setDriverPhotos(response.data);
            }
        }).catch(error => {
            console.log('error order docs', error);
        })
    }

    const getDeliveryIcon = (iconSize) => {
        return L.icon({
            iconUrl: require("./map/delivery.png"),
            iconSize: iconSize,
        });
    };

    const getPickUpIcon = (iconSize) => {
        return L.icon({
            iconUrl: require("./map/pickup.png"),
            iconSize: iconSize,
        });
    };

    const getDriverIcon = (iconSize) => {
        return L.icon({
            iconUrl: require("./map/truck.png"),
            iconSize: iconSize,
        });
    };

    const formApp = (id) => {
        File.getDocApp(id)
            .then((response) => {
                console.log(response);
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });
    };

    const renderDeliveryMarker = () => {
        if (cityTo) {
            if (cityTo.latitude && cityTo.longitude) {
                return (
                    <Marker position={[cityTo.latitude, cityTo.longitude]}
                            icon={getIcon(15, 0)}>
                        <Tooltip
                            direction="top"
                            height="50px"
                            offset={[0, 0]}
                            opacity={1}
                            permanent>
                            <div style={{padding: "4px 7px", fontWeight: "bold"}}>
                                {cityTo?.name}
                            </div>
                        </Tooltip>
                        <Popup>
                            {item.countryToName} г. {cityTo.name}
                            <br/>
                            {item.addressTo}
                        </Popup>
                    </Marker>
                );
            }
        } else return null;
    };

    const renderPickupMarker = () => {
        if (cityFrom) {
            if (cityFrom.latitude && cityFrom.longitude) {
                return (
                    <Marker
                        position={[cityFrom.latitude, cityFrom.longitude]}
                        icon={getIcon(15, 0)}>
                        <Tooltip
                            direction="top"
                            height="50px"
                            offset={[0, 0]}
                            opacity={1}
                            permanent>
                            <div style={{padding: "4px 7px", fontWeight: "bold"}}>
                                {cityFrom.name}
                            </div>
                        </Tooltip>
                    </Marker>
                );
            }
        } else return null;
    };

    const getIcon = (iconSize, index) => {
        return L.icon({
            iconUrl: require("./map/stationIcon.png"),
            iconSize: iconSize,
        });
    };

    const renderPositions = () => {
        return (
            <>
                <Polyline
                    color="rgb(163, 25, 91)"
                    weight="5"
                    smoothFactor="2"
                    opacity="1"
                    positions={coordinate}
                />
                {coordinate.map((position, index) => (
                    <Marker
                        position={[position.lat, position.lng]}
                        icon={getIcon(15, index)}>
                        {/*{index == 0 && item.status !== "inprocess" ? (*/}
                        {/*    <Tooltip*/}
                        {/*        direction="top"*/}
                        {/*        height="50px"*/}
                        {/*        offset={[0, 0]}*/}
                        {/*        opacity={1}*/}
                        {/*        permanent>*/}
                        {/*        <div style={{ padding: '4px 7px', fontWeight: 'bold' }}>{cityTo?.name}</div>*/}
                        {/*    </Tooltip>*/}
                        {/*) : index == coordinate.length - 1 ? (*/}
                        {/*    <Tooltip direction="top" offset={[0, 0]} opacity={1} permanent>*/}
                        {/*        <div style={{ padding: '4px 7px', fontWeight: 'bold' }}>{cityFrom?.name}</div>*/}
                        {/*    </Tooltip>*/}
                        {/*) : (*/}
                        {/*    <Tooltip direction="top" offset={[0, 0]} opacity={1}>*/}
                        {/*        {position.date ? position.date : ""}*/}
                        {/*    </Tooltip>*/}
                        {/*)}*/}
                    </Marker>
                ))}
            </>
        );
    };

    const getDriverInfoAndDownloadPOA = () => {

        User.getDriverById(driverId)
            .then((response) => {

                let num = item.number;
                if (item.att && item.att != 0) {
                    num = `${item.number}/${item.att}`;
                }
                if (!response.data) {
                    alert('Недостаточно информации о водителе, заполните всю информацию о водителе');
                }
                generatePOA(
                    response.data.user,
                    driver,
                    vehicle,
                    item.senderName,
                    item.recipientName,
                    num,
                    moment(item.shippingDate).format("YYYY-MM-DD"),
                    moment(item.unloadingDate).format("YYYY-MM-DD")
                );

            }).catch(error => {
            alert("Произошла ошибка, попробуйте позже");
        });
    }

    const onCheckCities = () => {
        setIsCheckCities(true);
    }


    return (
        <div className="modal-overlay">
            <div className="order-modal">
                <div className="header">
                    <div style={{fontSize: "16px"}}>
                        Информация о заказе
                        <span style={{fontWeight: "600", paddingLeft: "5px", paddingRight: '10px'}}>{item.number}</span>
                        количество просмотров:
                        <span style={{fontWeight: "600", paddingLeft: "5px"}}> {orderView ? orderView.length : 0}</span>
                    </div>
                    <div style={{padding: "0 5px", cursor: "pointer"}} onClick={onClose}>X</div>
                </div>
                <div className="container">
                    <div style={{height: '320px', width: '100%', backgroundColor: 'red'}}>
                        {coordinate && coordinate.length ? (
                            <MapContainer
                                center={[47.238949, 68.889709]}
                                zoom={4}
                                scrollWheelZoom={false}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                {coordinate ? renderPositions() : ""}
                                {renderPickupMarker()}
                                {renderDeliveryMarker() /*{item.status === "inprocess" ? renderDeliveryMarker() : ''}*/}
                            </MapContainer>
                        ) : (
                            <MapContainer
                                center={[47.238949, 68.889709]}
                                zoom={4}
                                scrollWheelZoom={false}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                {renderPickupMarker()}
                                {renderDeliveryMarker()}
                                {/*{renderDriverMarker()}*/}
                            </MapContainer>)}
                    </div>
                    <StatusInfo user={user} order={item} getOrders={getOrders} onCheckCities={onCheckCities}/>
                    {(['booked', 'confirmed','processloadingcargo', 'loadingcargomoderation', 'rejectedloadingcargomoderation', 'waitstarttrip', 'onthetrip',
                        'processunloadingcargo', 'unloadingcargomoderation', 'rejectedunloadingcargomoderation',
                        'completed'].includes(item.status) ? (
                        <div className="online-doc">
                            <div className="title">
                                Документы
                            </div>
                            <div className="row">
                                <div className="col-6" style={{paddingBottom: "12px", margin: "0 auto"}}>
                                    <Button onClick={() => formApp(item.id)}
                                            variant="outline-primary"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                minHeight: "56px",
                                            }}>
                                        Сформировать заявку
                                    </Button>
                                </div>
                                <div className="col-6"
                                     style={{paddingBottom: "12px", margin: "0 auto", display: "block"}}>
                                    <Button onClick={getDriverInfoAndDownloadPOA} variant="outline-primary"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                minHeight: "56px",
                                            }}>
                                        Скачать Доверенность
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <span></span>
                    ))}
                    <div className="order-photos">
                        {(['loadingcargomoderation', 'rejectedloadingcargomoderation', 'waitstarttrip', 'onthetrip',
                            'processunloadingcargo', 'unloadingcargomoderation', 'rejectedunloadingcargomoderation',
                            'completed'].includes(item.status)) && <LoadingCargoPhotos files={driverPhotos}/>}

                        {['unloadingcargomoderation', 'rejectedunloadingcargomoderation',
                            'completed'].includes(item.status) && <UnloadingCargoPhotos files={driverPhotos}/>}
                    </div>
                    {driver && <div className="driver-info">
                        <span>Водитель</span>
                        <DriverInfo driver={driver} vehicle={vehicle}/>
                    </div>}
                    <OrderDetails order={item} orderStateData={orderStateData} cargoDetails={cargoDetails}/>
                    {item.isAuction ? (
                        <TenderDetails order={item} user={user}/>
                    ) : null}
                    <CargoDetails cargoDetails={cargoDetails} order={item}/>

                    <div className="sender">
                        <span>
                            Отправитель
                        </span>
                        {client && <div className="row" style={{width: '100%'}}>
                            <div className="col-6" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Название компании
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {client.companyName}
                                </div>
                            </div>
                            <div className="col-6" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Контактный телефон
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {phoneNumberFormat(client.phoneNumber)}
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="recipient">
                        <span>Получатель</span>
                        {recipient && (
                            <div className="row" style={{width: '100%'}}>
                                <div className="col-6" style={{paddingBottom: "12px"}}>
                                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Название компании
                                    </div>
                                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                                        {recipient.name}
                                    </div>
                                </div>
                                <div className="col-6" style={{paddingBottom: "12px"}}>
                                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Контактный телефон
                                    </div>
                                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                                        {recipient.phoneNumber}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {<OrderViews orderView={orderView} order={item}/>}
                    {<Feedback order={item}/>}
                    {(item) && <FileUploader orderId={item.id}/>}
                </div>
            </div>
        </div>
    );
};

export default OrderInfoModal;
