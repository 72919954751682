import {get, post, put, destroy} from './config';

const User = {
    getById: (userId) =>
        get(`v1/users/by-${userId}`),
    getListDate: (id) =>
        get(`v1/orders/states/${id}`),
    getСoordinate: (number) =>
        get(`v1/orders/positions/list?orderNo=${number} `),
    confirm: (userId, body) =>
        post(`v1/users/confirm/${userId}`, body),
    confirmDriver: (driverId, data) =>
        post(`v1/users/confirm-driver/${driverId}`, data),
    setBalance: (userId, balanc) =>
        get(`v1/users/balance/${userId}?sum=${balanc}`),
    getBalanceList: (userId) =>
        get(`v1/users/balance/list?userId=${userId}`),
    reject: (userId, data) =>
        post(`v1/users/reject/${userId}`, data),
    getUsers: (state, type, page, limit, name, phoneNumber, bin, email) =>
        get(`v1/users/list?state=${state}&type=${type}&page=${page}&limit=${limit}&name=${name}&phoneNumber=${phoneNumber}&bin=${bin}&email=${email}`),
    getDriversByExpeditorId: (id) =>
        get(`v1/users/drivers/by-expeditor-${id}`),
    getDriverById: (userId) =>
        get(`v1/users/driver/by-${userId}`),
    addSmsCode: (driverId, code) =>
        post(`v1/users/drivers/add-smscode/${driverId}?code=${code}`),
    getAppRegisterList: (state = 'all', page = 1, limit = 15) =>
        get(`v1/users/drivers/app-register-list?state=${state}&page=${page}&limit=${limit}`),
    getVehicleType: (driverId) =>
        get(`v1/users/driver/vehicleType/${driverId}`),
    deleteUser: (userId, userType, user, reason) =>
        destroy(`v1/users/${userId}?type=${userType}&username=${user}&reason=${reason}`),
    updateAppForRegisterDriver: (id, state) =>
        post(`v1/users/drivers/update-app-register/${id}?state=${state}`),
    deleteAppForRegisterDriver: (id) =>
        destroy(`v1/users/drivers/delete-app-register/${id}`),
    // NEW ROUTE TO UPDATE DRIVER INFO
    updateDriver: (driverId, data) =>
        post(`v1/users/confirm-driver-update/${driverId}`, data),
    getEmployees: (companyId, page = 1, limit = 15) =>
        get(`v1/users/client/employees/${companyId}?page=${page}&limit=${limit}`),
}

export default User;
