import { get, post, put, destroy } from './config';

const Payment = {
    getAll: (status, page) =>
        get(`v1/payments/all?payments=${status}&page=${page}`),
    updateStatus: (paymentId,isPaid) =>
        post(`v1/payments/update-status/${paymentId}?isPaid=${isPaid}`),
    getPayments: (page, limit) =>
        get(`v1/payments/list?payments=&page=${page}&limit=${limit}`),
}

export default Payment;
