import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Directory} from '../../services';
import Loader from '../../utils/loader';

const CityCreateModal = ({onClose, editMode, selectedItem}) => {
    const [countryIds, setCountryIds] = useState();
    const [countryObj, setCountryObj] = useState();
    const [selectedCountryId, setSelectedCountryId] = useState(selectedItem.countryId || undefined);

    const [name, setName] = useState(selectedItem.name || '');
    const [nameEn, setNameEn] = useState(selectedItem.nameEn || '');
    const [latitude, setLatitude] = useState(selectedItem?.latitude || undefined);
    const [longitude, setLongitude] = useState(selectedItem?.longitude || undefined);
    const [scale, setScale] = useState((selectedItem.scale !== 0) ? 'large' : 'small');
    const [scale1Checked, setScale1Checked] = useState(selectedItem?.scale === 0 ? true : false);
    const [scale2Checked, setScale2Checked] = useState(selectedItem?.scale !== 0 ? true : false);

    const [inputRating, setInputRating] = useState(selectedItem?.inputRating || '0');
    const [outputRating, setOutputRating] = useState(selectedItem?.outputRating || '0');

    const [successView, setSuccessView] = useState(false);
    const [errorTitle, setErrorTitle] = useState();

    useEffect(() => {
        Directory.getCountries().then(response => {
            console.log("COUNTRIES", response)
            if (response.status == 200) {
                if (response.data.length > 0) {

                    let arr = [];
                    let obj = [];

                    for (let i = 0; i < response.data.length; i++) {
                        arr.push(response.data[i].id);
                        obj[response.data[i].id] = response.data[i].name
                    }

                    setSelectedCountryId(response.data[0].id);

                    setCountryIds(arr);
                    setCountryObj(obj);
                } else {
                    setCountryIds([]);
                    setCountryObj({});
                }
            } else {
                setCountryIds([]);
                setCountryObj({});

            }
        }).catch(error => {
            console.log(error)
        })
    }, []);

    const onSubmit = () => {

        if (name && latitude && longitude && selectedCountryId && scale && inputRating && outputRating) {
            setErrorTitle();

            const data = {
                name,
                nameEn,
                latitude,
                longitude,
                "countryId": selectedCountryId,
                scale,
                inputRating,
                outputRating
            }

            if (editMode === 'create') {
                Directory.addCity(data).then(response => {
                    console.log("CITY create response ---->", response);
                    setSuccessView(true);
                    onClose(true);
                }).catch(error => {
                    console.log(error)
                    alert('Произошла ошибка, попробуйте позже')
                })
            } else {
                Directory.updateCity(selectedItem.id, data)
                    .then(response => {
                        console.log("CITY create response ---->", response);
                        setSuccessView(true);
                        onClose(true);
                    }).catch(error => {
                    alert('Произошла ошибка, попробуйте позже');
                    console.log(error);
                });
            }
        } else {
            setErrorTitle('Необходимо заполнить все поля')
        }
    }

    const renderContent = () => {
        if (successView) {
            return (
                <div style={{textAlign: 'center', padding: '32px'}}>
                    <div>
                        <div style={{
                            display: 'inline-block',
                            backgroundColor: 'rgba(19, 182, 94, 0.1)',
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%'
                        }}>
                            <div style={{height: '20px', marginTop: '28px'}}>
                                <img src="/icons/tick-success.svg" height="22px" alt="success"/>
                            </div>
                        </div>
                    </div>

                    <div style={{fontSize: '20px', fontWeight: '700', marginTop: '16px'}}>
                        Категория успешно добавлена!
                    </div>
                    <div style={{
                        fontSize: '15px',
                        marginTop: '4px',
                        textAlign: 'center',
                        color: '#A3ACB6',
                        padding: '0 32px'
                    }}>
                        Теперь данная категория доступна при создании заказа
                    </div>
                    <div style={{marginTop: '20px'}}>
                        <Button onClick={onClose} size="sm" variant="primary">
                            Перейти к списку
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{padding: '24px'}}>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Укажите страну</Form.Label>
                        {
                            selectedCountryId && countryObj && countryIds ?
                                <Form.Control
                                    as="select"
                                    value={selectedCountryId}
                                    onChange={e => {
                                        setSelectedCountryId(e.target.value)
                                    }}
                                    style={{border: '1px solid #ced4da', width: '100%', fontSize: '1rem'}}>
                                    {countryIds.map(item => {
                                        if (selectedItem.countryId && selectedItem.countryId === item) {
                                            return (
                                                <option value={item} selected>{countryObj[item]}</option>
                                            );
                                        } else {
                                            return (
                                                <option value={item}>{countryObj[item]}</option>
                                            );
                                        }
                                    })}
                                </Form.Control> :
                                <Loader/>
                        }

                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Название города</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}
                                      placeholder="Введите название"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Название города(en)</Form.Label>
                        <Form.Control type="text" value={nameEn} onChange={(e) => setNameEn(e.target.value)}
                                      placeholder="Введите название(en)"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Latitude</Form.Label>
                        <Form.Control type="text" value={latitude} onChange={(e) => setLatitude(e.target.value)}
                                      placeholder="Введите координату"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Longitude</Form.Label>
                        <Form.Control type="text" value={longitude} onChange={(e) => setLongitude(e.target.value)}
                                      placeholder="Введите коррдинату"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Scale</Form.Label>
                        <div>
                            <Form.Check
                                inline
                                defaultChecked={scale2Checked}
                                label="large"
                                name="group1"
                                value={scale}
                                type="radio"
                                onClick={() => setScale('large')}
                            />
                            <Form.Check
                                inline
                                defaultValue={scale1Checked}
                                label="small"
                                name="group1"
                                type="radio"
                                value={scale}
                                onClick={() => setScale('small')}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Input raiting</Form.Label>
                        <Form.Control type="text" value={inputRating} onChange={(e) => setInputRating(e.target.value)}
                                      placeholder="Введите рейтинг"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Output raiting</Form.Label>
                        <Form.Control type="text" value={outputRating} onChange={(e) => setOutputRating(e.target.value)}
                                      placeholder="Введите рейтинг"/>
                    </Form.Group>
                    {errorTitle ? <div style={{color: 'red'}}>{errorTitle}</div> : null}
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '30px'}}>
                        <Button onClick={() => onClose} variant="info">Отменить</Button>
                        <Button onClick={onSubmit} variant="success">Сохранить</Button>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '500px',
                margin: '24px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff', fontWeight: '600'}}>Добавление города
                    </div>
                    <div onClick={onClose}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default CityCreateModal;
