import {get, post, put, destroy} from './config';

const Directory = {
    getCategories: () =>
        get('/v1/directories/cargo/categories'),
    addCategory: (data) =>
        post('v1/directories/cargo/categories', data),
    updateCategory: (data) =>
        post('v1/directories/cargo/categories/update', data),
    deleteCategory: (id) =>
        destroy(`v1/directories/cargo/categories/${id}`),
    getTypes: () =>
        get('v1/directories/vehicle/types'),
    addType: (data) =>
        post('v1/directories/vehicle/types', data),
    getCountries: () =>
        get('v1/directories/countries'),
    addCountry: (data) =>
        post('v1/directories/countries', data),
    getCities: (countryId) =>
        get(`v1/directories/cities/by-${countryId}`),
    getAllCities: () =>
        get(`v1/directories/cities/all`),
    getCity: (cityId) =>
        get(`v1/directories/city/by-${cityId}`),
    addCity: (data) =>
        post('v1/directories/cities', data),
    updateCity: (id, data) =>
        put(`v1/directories/cities/${id}`, data),
    updateCityFromModerator: (id, body) =>
        put(`v1/directories/cities/from-moderator/${id}`, body),
    getContacts: () =>
        get('v1/directories/contacts'),
    updateContact: (data) =>
        post('v1/directories/contacts/update', data),
    getOrderCancelMessages: () =>
        get('v1/directories/order/cancelmessages'),
    addOrderCancelMessage: (data) =>
        post('v1/directories/order/cancelmessages', data),
    updateOrderCancelMessage: (data) =>
        post('v1/directories/order/cancelmessages/update', data),
    deleteOrderCancelMessage: (id) =>
        destroy(`v1/directories/order/cancelmessages/${id}`),
    getCitiesByIds: (body) =>
        post(`v1/directories/cities/by-ids`, body),


}

export default Directory;
