import { get, post, put, destroy } from './config';

const ExportReport = {
    getDriverOrdersCount: () =>
        get('/v1/export/driver-orders', { responseType: 'blob' }),
    getOrderWithStatus: () =>
        get('/v1/export/driver-orders-count', { responseType: 'blob' })
}

export default ExportReport;
