// CustomHook.js
import {useState} from 'react';

function useCustomHook() {
    const [data, setData] = useState();
    const [dataBook, setDataBook] = useState();
    const customFunction = (newData) => {
        // Custom logic
        setData(newData);
    };

    const customBookFunction = (newData) => {
        // Custom logic
        setDataBook(newData);
    };

    return {data,dataBook, customFunction, customBookFunction};
}

export default useCustomHook;
