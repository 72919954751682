import React, { useEffect, useState } from 'react';
import { Directory } from '../../services';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import CountryCreateModal from './CountryCreateModal';
import Loader from '../../utils/loader';

const CountriesPage = () => {

    const [countries, setCountries] = useState();
    const [showCreateModal, setShowCreateModal] = useState(false);

    useEffect(() => {
        getCountries();
    }, []);

    const getCountries = () => {
        Directory.getCountries().then(response => {
            console.log("COUNTRIES", response)
            if(response.status == 200) {
                if(response.data.length > 0) setCountries(response.data)
                else setCountries([]);
            }else {
                setCountries([])
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    const renderList = () => {
        if(countries) {
            if(countries.length > 0) {
                const list = countries.map(item => {
                    return (
                        <ListGroup.Item style={{ padding: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{item.name}</div>
                                <div style={{ marginLeft: '12px', flex: '1' }}><Badge bg="secondary">{item.code}</Badge></div>
                                <div>
                                    <Button size="sm" variant="outline-danger" style={{ fontSize: '11px' }}>Удалить</Button>
                                    <Button size="sm" variant="outline-secondary" style={{ fontSize: '11px', marginLeft: '12px' }}>Редактировать</Button>
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                });

                return list;
            }else {
                return <div>Стран нет</div>
            }
        }else {
            return <Loader />
        }
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'left', fontWeight: '700', fontSize: '24px' }}>Страны</div>
                <div>
                    <Button onClick={() => setShowCreateModal(true)} size="sm">Добавить страну</Button>
                </div>
            </div>
            <div style={{ marginTop: '20px' }}>
                <ListGroup>
                    {renderList()}
                </ListGroup>
            </div>

            {
                showCreateModal && <CountryCreateModal onClose={() => setShowCreateModal(false)} update={getCountries} />
            }
        </>
    );
}

export default CountriesPage;