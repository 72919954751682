import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {SystemUsers, SystemRoles} from '../../services';

const UserStates = [{value: "Active", name: 'Активно'}, {value: 'UnActive', name: 'Неактивно'}];

const UserCreateModal = ({onClose, onSuccess, currentItem}) => {

    const [username, setUsername] = useState(currentItem ? currentItem.username : null);
    const [fullname, setFullname] = useState(currentItem ? currentItem.name : null);
    const [isEdit, setIsEdit] = useState(currentItem ? true : false);
    const [password, setPassword] = useState();
    const [state, setState] = useState(currentItem && currentItem.state ? currentItem.state : 'Active');
    const [selectedRoleId, setSelectedRoleId] = useState();
    const [rolesObj, setRolesObj] = useState();
    const [rolesIds, setRolesIds] = useState();

    const [errorTitle, setErrorTitle] = useState();

    useEffect(() => {
        SystemRoles.getAll()
            .then(response => {
                console.log(response.data);
                if (response.status == 200) {
                    if (response.data.length > 0) {

                        let arr = [];
                        let obj = []

                        for (let i = 0; i < response.data.length; i++) {
                            arr.push(response.data[i].id);
                            obj[response.data[i].id] = response.data[i].name
                        }

                        if (currentItem)
                            setSelectedRoleId(currentItem.roleId);
                        else
                            setSelectedRoleId(response.data[0].id);

                        setRolesIds(arr);
                        setRolesObj(obj);


                    } else {
                        setRolesIds([]);
                        setRolesObj({});
                    }
                } else {
                    setRolesIds([]);
                    setRolesObj({});

                }
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    const onSubmit = () => {
        if (!isEdit) {
            if (username && fullname && password && selectedRoleId) {
                setErrorTitle();
                const data = {
                    "name": fullname,
                    "username": username,
                    "password": password,
                    "roleId": selectedRoleId,
                    "state":"Active"
                };

                SystemUsers.addUser(data).then(response => {
                    console.log("SYSTEM USER create response ---->", response);
                    onSuccess();
                }).catch(error => {
                    console.log(error)
                    alert('Произошла ошибка, попробуйте позже')
                });

            } else {
                setErrorTitle('Необходимо заполнить все поля')
            }
        } else {
            if (username && fullname && selectedRoleId) {
                const data = {
                    "id": currentItem.id,
                    "name": fullname,
                    "username": username,
                    "roleId": selectedRoleId,
                    "state": state
                };
                SystemUsers.updateUser(data).then(response => {
                    console.log("SYSTEM USER create response ---->", response);
                    onSuccess();
                }).catch(error => {
                    console.log(error);
                    alert('Произошла ошибка, попробуйте позже')
                });
            } else setErrorTitle('Необходимо заполнить все поля')

        }
    }

    const renderContent = () => {
        return (
            <div style={{padding: '24px'}}>
                <Form.Group className="mb-3">
                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Логин</Form.Label>
                    <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                                  placeholder="Введите логин пользователя"/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Имя, фамилия</Form.Label>
                    <Form.Control type="text" value={fullname} onChange={(e) => setFullname(e.target.value)}
                                  placeholder="Введите имя и фамилию пользователя"/>
                </Form.Group>
                {!isEdit && <Form.Group className="mb-3">
                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Пароль</Form.Label>
                    <Form.Control type="text" value={password} onChange={(e) => setPassword(e.target.value)}
                                  placeholder="Введите пароль пользователя"/>
                </Form.Group>}
                <Form.Group className="mb-3">
                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Выберите роль</Form.Label>
                    {
                        selectedRoleId && rolesObj && rolesIds ?
                            <Form.Control
                                as="select"
                                value={selectedRoleId}
                                onChange={e => {
                                    setSelectedRoleId(e.target.value)
                                }}
                                style={{
                                    border: '1px solid #ced4da',
                                    width: '100%',
                                    fontSize: '1rem'
                                }}
                            >
                                {rolesIds.map(item => {
                                    return (
                                        <option value={item}>{rolesObj[item]}</option>
                                    );
                                })}
                            </Form.Control> :
                            <div>Загрузка..</div>
                    }

                </Form.Group>
                {currentItem && (
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Cтатус</Form.Label>
                        {
                            <Form.Control
                                as="select"
                                value={state}
                                onChange={e => {
                                    setState(e.target.value)
                                }}
                                style={{
                                    border: '1px solid #ced4da',
                                    width: '100%',
                                    fontSize: '1rem'
                                }}>
                                {UserStates.map(item => {
                                    return (
                                        <option value={item.value}>{item.name}</option>
                                    );
                                })}
                            </Form.Control>
                        }

                    </Form.Group>
                )}
                {errorTitle ? <div style={{color: 'red'}}>{errorTitle}</div> : null}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '30px', gap: '10px'}}>
                    <div><Button onClick={onSubmit} variant="success">Сохранить</Button></div>
                    <div><Button onClick={onClose} variant="secondary">Отменить</Button></div>
                </div>
            </div>
        );
    }

    return (
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '500px',
                margin: '24px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff', fontWeight: '600'}}>
                        {!isEdit ? 'Создание пользователя' : 'Редактировать'}
                    </div>
                    <div onClick={onClose}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default UserCreateModal;
