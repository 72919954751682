import React, {useEffect, useState} from 'react';
import {Directory} from '../../services';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CityCreateModal from './CityCreateModal';
import Loader from '../../utils/loader';

const CitiesPage = () => {

    const [cities, setCities] = useState();
    const [countries, setCountries] = useState();
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [editMode, setEditMode] = useState('create');
    const [showCreateModal, setShowCreateModal] = useState(false);

    useEffect(() => {
        Directory.getCountries().then(response => {
            console.log("COUNTRIES", response)
            if (response.status == 200) {
                if (response.data.length > 0) {
                    setCountries(response.data);
                    setSelectedCountry(response.data[0]);
                } else setCountries([]);
            } else {
                setCountries([]);
            }
        })
            .catch(error => {
                console.log(error)
            })
    }, []);

    useEffect(() => {
        console.log(selectedCountry);
        if (selectedCountry) {
            getCities();
        }

    }, [selectedCountry]);

    const getCities = () => {
        Directory.getCities(selectedCountry.id).then(response => {
            console.log("CITIES", response)
            if (response.status == 200) {
                if (response.data.length > 0) setCities(response.data)
                else setCities([]);
            } else {
                setCities([])
            }
        })
            .catch(error => {
                console.log(error)
            })
    }

    const onHandleCreate = () => {
        setSelectedItem({});
        setEditMode('create');
        setShowCreateModal(true);
    }

    const onHandleEdit = (item) => {
        Directory.getCity(item.id).then(response => {
            console.log('response', response);
            setSelectedItem(response.data);
            setEditMode('edit');
            setShowCreateModal(true);
        }).catch(error => {
            console.log('err', error);
        })
    }

    const onHandleClose = (isSuccess) => {
        setShowCreateModal(false);
        setSelectedItem(undefined);

        if (isSuccess) {
            getCities();
        }
    }

    const renderList = () => {
        if (cities) {
            if (cities.length > 0) {
                const list = cities.map(item => {
                    return (
                        <ListGroup.Item style={{padding: '16px'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>{item.name}</div>
                                <div style={{marginLeft: '12px', flex: '1'}}>
                                    <Badge bg="secondary">{item.code}</Badge>
                                </div>
                                <div>
                                    <Button size="sm" variant="outline-danger" style={{fontSize: '11px'}}>
                                        Удалить
                                    </Button>
                                    <Button size="sm" variant="outline-secondary" onClick={() => onHandleEdit(item)}
                                            style={{fontSize: '11px', marginLeft: '12px'}}>
                                        Редактировать
                                    </Button>
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                });

                return list;
            } else {
                return <div>Городов нет</div>
            }
        } else {
            return <Loader/>
        }
    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>Города</div>
                <div>
                    <Button onClick={onHandleCreate} size="sm"
                            style={{backgroundColor: '#A3195B', border: '#A3195B'}}>Добавить город</Button>
                </div>
            </div>
            {
                selectedCountry ?
                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '4px'}}>
                        {
                            countries.map(item => {
                                return (
                                    <div style={{marginRight: '12px', marginTop: '12px'}}>
                                        <Button onClick={() => setSelectedCountry(item)} size="sm"
                                                variant={selectedCountry.id === item.id ? 'primary' : 'outline-primary'}>{item.name}</Button>
                                    </div>
                                );
                            })
                        }
                    </div> : null
            }

            <div style={{marginTop: '16px'}}>
                <ListGroup>
                    {renderList()}
                </ListGroup>
            </div>
            {
                showCreateModal &&
                <CityCreateModal onClose={onHandleClose} editMode={editMode}
                                 selectedItem={selectedItem}/>
            }
        </>
    );
}

export default CitiesPage;
