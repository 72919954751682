import { get, post, put, destroy } from './config';

const UserCommunications = {
    getByUserId: (userId) =>
        get(`v1/usercommunications/${userId}`),
    create: (body) =>
        post(`v1/usercommunications`,body)
}

export default UserCommunications;
