import React, {useState} from "react";
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import {ORDER_STATUSES} from "../../orders/Statuses";

const StatusTableItem = ({item}) => {

    return (
        <tr>
            <td>
                {item.number}
            </td>
            <td>
                {moment(item.createdAt).format("DD.MM.YYYY")}<br/>
            </td>
            <td>
                {item.senderName ?? ''}
            </td>
            <td>
                {item.recipientName ?? ''}
            </td>
            <td>
                {moment(item.shippingDate).format("DD.MM.YYYY")}
            </td>
            <td>
                <div style={{padding: "5px 0", wordWrap: "break-word", maxWidth: "160px"}}>
                    {`${item.countryFromName}, г. ${item.cityFromName}, ${item.addressFrom}`}
                </div>
            </td>
            <td>
                <div style={{padding: "5px 0", wordWrap: "break-word", maxWidth: "160px"}}>
                    {`${item.countryToName}, г. ${item.cityToName}, ${item.addressTo}`}
                </div>
            </td>
            <td>
                <div style={{padding: "5px 0"}}>
                    {moment(item.unloadingDate).format("DD.MM.YYYY")}
                </div>
            </td>
            <td>
                <div style={{padding: "5px 0", maxWidth: "max-content"}}>
                    <Badge bg="primary">{ORDER_STATUSES[item.status]}</Badge>
                </div>
            </td>
            <td>{item.netPrice} ₸</td>
        </tr>
    )
}
export default StatusTableItem;
