import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Directory} from '../../services';

const CategoryCreateModal = ({item, onClose, update}) => {

    const [name, setName] = useState(item?.name || '');
    const [nameKz, setNameKz] = useState(item?.nameKz || '');
    const [value, setValue] = useState(item?.value || '');
    const [insuranceCategory, setInsuranceCategory] = useState(item?.insuranceCategory || 1);
    const [errorTitle, setErrorTitle] = useState();
    const [successView, setSuccessView] = useState(false);
    const insuranceCategories = [{id: 1, name: 'Категория 1'}, {id: 2, name: 'Категория 2'}, {
        id: 3,
        name: 'Категория 3'
    }, {id: 4, name: 'Особый груз'}, {id: 5, name: 'Опасный груз'}, {id: 6, name: 'Автомобиль'}];
    const onSubmit = () => {
        if (name && value) {
            setErrorTitle();
            if (!item) {
                Directory.addCategory({name, nameKz, value, insuranceCategory}).then(response => {
                    if (response.status == 200) {
                        setSuccessView(true);
                        update();
                    } else alert('Произошла ошибка, попробуйте позже');
                }).catch(error => {
                    console.log(error)
                });
            } else {
                item.name = name;
                item.nameKz = nameKz;
                item.value = value;
                item.insuranceCategory = insuranceCategory;
                Directory.updateCategory(item).then(response => {
                    if (response.status == 200) {
                        setSuccessView(true);
                        update();
                    } else alert('Произошла ошибка, попробуйте позже');
                }).catch(error => {
                    console.log(error)
                });
            }
        } else {
            setErrorTitle('Необходимо заполнить все поля')
        }
    }

    const renderContent = () => {
        if (successView) {
            return (
                <div style={{textAlign: 'center', padding: '32px'}}>
                    <div>
                        <div style={{
                            display: 'inline-block',
                            backgroundColor: 'rgba(19, 182, 94, 0.1)',
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%'
                        }}>
                            <div style={{height: '20px', marginTop: '28px'}}>
                                <img src="/icons/tick-success.svg" height="22px"/>
                            </div>
                        </div>
                    </div>

                    <div style={{fontSize: '20px', fontWeight: '700', marginTop: '16px'}}>
                        {item ? 'Категория успешно сохранена!' : 'Категория успешно добавлена!'}
                    </div>
                    <div style={{
                        fontSize: '15px',
                        marginTop: '4px',
                        textAlign: 'center',
                        color: '#A3ACB6',
                        padding: '0 32px'
                    }}>Теперь данная категория доступна при создании заказа
                    </div>
                    <div style={{marginTop: '20px'}}><Button onClick={onClose} size="sm" variant="primary">Перейти к
                        списку</Button></div>
                </div>
            );
        } else {
            return (
                <div style={{padding: '24px'}}>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Название категории</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}
                                      placeholder="Введите название"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Название категории(каз)</Form.Label>
                        <Form.Control type="text" value={nameKz || ''} onChange={(e) => setNameKz(e.target.value)}
                                      placeholder="Введите название(каз)"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Значение категории</Form.Label>
                        <Form.Control type="text" value={value} onChange={(e) => setValue(e.target.value)}
                                      placeholder="Введите значение"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Укажите категория груза
                            (Страховка)</Form.Label>
                        {
                            insuranceCategories &&
                            <Form.Control
                                as="select"
                                value={insuranceCategory}
                                onChange={e => {
                                    setInsuranceCategory(e.target.value)
                                }}>
                                {insuranceCategories.map(item => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    );
                                })}
                            </Form.Control>
                        }
                    </Form.Group>
                    {errorTitle ? <div>{errorTitle}</div> : null}
                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '30px'}}>
                        <div><Button onClick={onClose} variant="secondary">Отменить</Button></div>
                        <div><Button onClick={onSubmit} variant="success">Сохранить</Button></div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '500px',
                margin: '24px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff', fontWeight: '600'}}>
                        {item ? 'Редактирование категории' : 'Создание категории'}
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={onClose}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default CategoryCreateModal;
