import moment from "moment/moment";
import React from "react";
import {DRIVER_STATUSES_DATA} from "./DriversPage";

const DriversItem = ({item, activeTab, role, onSelected, onDelete}) => {

    const getColor = (item) => {
        if (DRIVER_STATUSES_DATA[item.state]) {
            return DRIVER_STATUSES_DATA[item.state].color;
        } else return '';
    }

    const getTitle = (item) => {
        if (DRIVER_STATUSES_DATA[item.state]) {
            return DRIVER_STATUSES_DATA[item.state].title;
        } else return '';
    }
    return (
        <tr key={item.id}>
            <td onClick={() => onSelected(item)}>
                <div style={{padding: '5px 0'}}>{moment(item.createdAt).format('DD.MM.YYYY')}</div>
            </td>
            <td onClick={() => onSelected(item)}>
                <div style={{padding: '5px 0'}}>{item.surname} {item.name} {item.patronymic}</div>
            </td>
            <td onClick={() => onSelected(item)}>
                <div style={{padding: '5px 0'}}>+{item.phoneNumber}</div>
            </td>
            <td onClick={() => onSelected(item)}>
                <div style={{padding: '5px 0'}}>{item.vehicleType}</div>
            </td>
            <td>
                {item.companyId && <input type="checkbox" readOnly='true' checked/>}
                {!item.companyId && <input type="checkbox" disabled/>}
            </td>
            <td onClick={() => onSelected(item)}>
                <div style={{padding: '5px 0'}}>
                    <div style={{
                        backgroundColor: `${getColor(item)}`,
                        color: '#ffffff',
                        fontSize: '11px',
                        padding: '1px 6px',
                        fontWeight: '600',
                        borderRadius: '6px',
                        display: 'inline-block'
                    }}>
                        {getTitle(item)}
                    </div>
                </div>
            </td>
            {activeTab === 'CONFIRMED_DRIVER' && <td>{item.completedOrders}</td>}
            <td>
                {(['superAdmin', 'moderator'].includes(role)) &&
                    <div
                        style={{
                            width: "30px",
                            textAlign: "center",
                            padding: "5px 0",
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            onDelete(item.id);
                        }}>
                        <img height="17px" src="/icons/delete.png"/>
                    </div>
                }
            </td>
        </tr>
    )
}
export default DriversItem;
