import {Directory} from "../../../services";
import OrderFeedback from "../../../services/OrderFeedback";
import React, {useEffect, useState} from "react";
import OrderListItem from "../OrderListItem";
import moment from "moment";

const Feedback = ({order}) => {

    const [feedbackData, setFeedbackData] = useState();

    useEffect(() => {
        OrderFeedback.getByOrderId(order.id).then(response => {
            setFeedbackData(response.data);
        }).catch(error => {
            console.log('update city error', error);
        })
    }, [order]);

    const gradeIcons = () => {
        const grade = feedbackData.grade;
        let icons = [1, 2, 3, 4, 5].map(item => {
            if (item <= grade) {
                return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M20.8269 8.19459L15.5759 7.4342L13.228 2.70241C13.1028 2.48869 12.9234 2.31136 12.7078 2.18811C12.4922 2.06486 12.2479 2 11.9993 2C11.7506 2 11.5063 2.06486 11.2907 2.18811C11.0751 2.31136 10.8957 2.48869 10.7705 2.70241L8.42185 7.43556L3.17289 8.19459C2.92 8.23114 2.68243 8.33734 2.48703 8.50118C2.29163 8.66501 2.14619 8.87996 2.06716 9.12171C1.98813 9.36346 1.97865 9.62239 2.03979 9.86922C2.10094 10.1161 2.23027 10.3409 2.41317 10.5185L6.21233 14.2044L5.31563 19.4044C5.28211 19.6 5.29185 19.8006 5.34418 19.9921C5.3965 20.1837 5.49015 20.3615 5.6186 20.5134C5.74706 20.6653 5.90724 20.7875 6.08799 20.8715C6.26875 20.9556 6.46574 20.9994 6.66526 21C6.88792 20.9995 7.10712 20.9452 7.30402 20.8418L12.0006 18.3864L16.6956 20.8424C16.9219 20.9608 17.177 21.0138 17.4321 20.9955C17.6872 20.9772 17.932 20.8882 18.1389 20.7387C18.3457 20.5892 18.5064 20.3851 18.6028 20.1494C18.6991 19.9138 18.7272 19.656 18.684 19.4053L17.7881 14.2029L21.5864 10.5185C21.7695 10.341 21.8989 10.1161 21.9601 9.86917C22.0214 9.62229 22.0119 9.3633 21.9329 9.12147C21.8539 8.87965 21.7084 8.66464 21.5129 8.50077C21.3175 8.3369 21.0798 8.2307 20.8269 8.19418V8.19459Z"
                        fill="#FA1861"/>
                </svg>
            } else {
                return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M20.8269 8.19459L15.5759 7.4342L13.228 2.70241C13.1028 2.48869 12.9234 2.31136 12.7078 2.18811C12.4922 2.06486 12.2479 2 11.9993 2C11.7506 2 11.5063 2.06486 11.2907 2.18811C11.0751 2.31136 10.8957 2.48869 10.7705 2.70241L8.42185 7.43556L3.17289 8.19459C2.92 8.23114 2.68243 8.33734 2.48703 8.50118C2.29163 8.66501 2.14619 8.87996 2.06716 9.12171C1.98813 9.36346 1.97865 9.62239 2.03979 9.86922C2.10094 10.1161 2.23027 10.3409 2.41317 10.5185L6.21233 14.2044L5.31563 19.4044C5.28211 19.6 5.29185 19.8006 5.34418 19.9921C5.3965 20.1837 5.49015 20.3615 5.6186 20.5134C5.74706 20.6653 5.90724 20.7875 6.08799 20.8715C6.26875 20.9556 6.46574 20.9994 6.66526 21C6.88792 20.9995 7.10712 20.9452 7.30402 20.8418L12.0006 18.3864L16.6956 20.8424C16.9219 20.9608 17.177 21.0138 17.4321 20.9955C17.6872 20.9772 17.932 20.8882 18.1389 20.7387C18.3457 20.5892 18.5064 20.3851 18.6028 20.1494C18.6991 19.9138 18.7272 19.656 18.684 19.4053L17.7881 14.2029L21.5864 10.5185C21.7695 10.341 21.8989 10.1161 21.9601 9.86917C22.0214 9.62229 22.0119 9.3633 21.9329 9.12147C21.8539 8.87965 21.7084 8.66464 21.5129 8.50077C21.3175 8.3369 21.0798 8.2307 20.8269 8.19418V8.19459Z"
                        fill="#808080"/>
                </svg>
            }
        });
        return icons;
    }

    const feedback = () => {
        let feeds = feedbackData.feedback ? feedbackData.feedback.split(',') : [];

        let f = feeds.map(item => {
            return <span>{item};</span>;
        })

        if (feedbackData.comment && feedbackData.comment !== '') {
            let fcomment = [feedbackData.comment].map(item => {
                return <span>{item};</span>;
            })
            f = f.concat(fcomment);
        }

        return f;
    }

    return feedbackData ? (<>
        <div style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
            paddingBottom: "10px",
            marginTop: "24px",
            width: '100%'
        }}>
            Оценка и отзыв от водителя
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: '4px'}}>
            <div className="order-grade">
                {gradeIcons()}
                {moment(feedbackData.createdAt).format("DD.MM.YYYY HH:mm")}
            </div>
            <div className="order-feedback">
                {feedback()}
            </div>
        </div>
    </>) : <></>
}

export default Feedback;
