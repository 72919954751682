import {Accordion, AccordionActions, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StatusTable from "./components/StatusTable";
import {useEffect, useState} from "react";
import {Order} from "../../services";
import {numberToFinanceFormat} from "../../utils";

const OrderTabsPage = () => {

    const [onthetrip, setOnthetrip] = useState(0);
    const [processloadingcargo, setProcessloadingcargo] = useState(0);
    const [processunloadingcargo, setProcessunloadingcargo] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [total, setTotal] = useState();
    const fnTotal = (type, event) => {

        if (type === 'onthetrip') setOnthetrip(event);
        if (type === 'processloadingcargo') setProcessloadingcargo(event);
        if (type === 'processunloadingcargo') setProcessunloadingcargo(event);
        if (type === 'completed') setCompleted(event);
    }

    useEffect(() => {
        Order.getTotalNetPrice()
            .then(response => {
                setTotal(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const getSum = (status) => {
        const find = total.find(x => x.status === status);
        return find.totalNetPrice;
    }

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1-content"
                    id="panel1-header">
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <span>На погрузке</span>
                        {total && <div style={{display: 'flex', gap: '10px'}}>
                            <span>Сумма:{numberToFinanceFormat(getSum('processloadingcargo'))} ₸</span>
                            <span>Количество: {processloadingcargo}</span>
                        </div>}

                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <StatusTable status={'processloadingcargo'}
                                 fnTotal={(event) => fnTotal('processloadingcargo', event)}/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2-content"
                    id="panel2-header">
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <span>В пути</span>
                        {total && <div style={{display: 'flex', gap: '10px'}}>
                            <span>Сумма:{numberToFinanceFormat(getSum('onthetrip'))} ₸</span>
                            <span>Количество: {onthetrip}</span>
                        </div>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <StatusTable status={'onthetrip'} fnTotal={(event) => fnTotal('onthetrip', event)}/>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel3-content"
                    id="panel3-header">
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <span>На выгрузке</span>
                        {total && <div style={{display: 'flex', gap: '10px'}}>
                            <span>Сумма:{numberToFinanceFormat(getSum('processunloadingcargo'))} ₸</span>
                            <span>Количество: {processunloadingcargo}</span>
                        </div>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <StatusTable status={'processunloadingcargo'}
                                 fnTotal={(event) => fnTotal('processunloadingcargo', event)}/>
                </AccordionDetails>

            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel3-content"
                    id="panel3-header"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <span>К оплате</span>
                        {total && <div style={{display:'flex',gap:'10px'}}>
                            <span>Сумма:{numberToFinanceFormat(getSum('completed'))} ₸</span>
                            <span>Количество: {completed}</span>
                        </div>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <StatusTable status={'completed'} fnTotal={(event) => fnTotal('completed', event)}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default OrderTabsPage;
