import React, {useEffect, useRef, useState} from 'react';
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import OrderInfoModal from './OrderInfoModal';
import {File, Order} from '../../services';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import OrderListItem from './OrderListItem';
import Pagination from 'react-responsive-pagination';
import * as signalR from '@microsoft/signalr';
import {useSignalR} from "../../context/signalR.context";
import './order-page.scss';
import {toast} from "react-toastify";
import sound from '../../utils/sound/coin.mp3';
import sound_neworder from '../../utils/sound/new_order.mp3';
import sound_bookedorder from '../../utils/sound/booked_order.mp3';
import useCustomHook from "./CustomHook";
import {ORDER_STATUSES, ORDER_STATUSES_KEYS} from "./Statuses";
import Export from "../../services/Export";
import Loader from "../../utils/loader";
import UpdateShippingUploadingDate from "./Components/UpdateShippingUploadingDate";
import CanceledOrderModal from "./Components/CanceledOrderModal";

const OrdersPage = () => {
    const connection = useSignalR();
    const [status, setStatus] = useState('all');
    const [orders, setOrders] = useState();
    const [selectedOrder, setSelectedOrder] = useState();

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();
    const [statusList, setStatusList] = useState([]);
    const [orderNo, setOrderNo] = useState('');
    const [startCreateAt, setStartCreateAt] = useState('');
    const [endCreateAt, setEndCreateAt] = useState('');
    const [sender, setSender] = useState('');
    const [recipient, setRecipient] = useState('');
    const audioRef = useRef(null);
    const audioBookRef = useRef(null);
    const {data, customFunction} = useCustomHook();
    const {dataBook, customBookFunction} = useCustomHook();
    const [isWidth, setWidth] = useState(100);
    const [isHeight, setHeight] = useState(180);
    const [isUpdateDateItem, setIsUpdateDateItem] = useState(null);
    const [canceledOrderItem, setCanceledOrderItem] = useState(null);

    const handleButtonClick = () => {
        setWidth((prevSize) => (prevSize === 100 ? 0 : 100));
        setHeight((prevSize) => (prevSize === 180 ? 0 : 180));
    };

    useEffect(() => {
        getOrders(orderNo, sender, recipient, startCreateAt, endCreateAt);
        Order.quantityByStatus().then(response => {
            const data = [];
            let total = 0;
            ORDER_STATUSES_KEYS.forEach(item => {
                let count = response.data.find(x => x.status === item) ? response.data.find(x => x.status === item).count : 0;
                total += count;
                data.push({title: ORDER_STATUSES[item], status: item, count: count});
            });

            const item = data.find(x => x.status === 'all');
            item.count = total;
            setStatusList(data);

        }).catch(error => {
            console.log('error', error);
        })
    }, [status, currentPage]);

    useEffect(() => {
        if (data && data !== '') {
            getFilter();
            handlePlay();
            customFunction('');
        }
    }, [data]);

    useEffect(() => {
        if (dataBook && dataBook !== '') {
            getFilter();
            handleBookPlay();
            customBookFunction('');
        }
    }, [dataBook]);

    useEffect(() => {
        if (connection) {

            connection.start().then(() => {

                connection.on("ReceiveMessage", (message) => {

                    if (message === 'addedNewOrder') {

                        if (message === 'addedNewOrder') {
                            setTimeout(() => {
                                toast.info('Появился новый заказ');
                                customFunction(message);
                            }, 100);
                        }

                    }
                    if (message === 'newBookOrder') {
                        setTimeout(() => {
                            toast.info('Бронирование заказа появилось');
                            customBookFunction(message);
                        }, 100);
                    }
                });
            }).catch((error) => console.log(error));
        }

        // Cleanup the connection when the component unmounts
        return () => {
            if (connection.state === signalR.HubConnectionState.Connected) {
                connection.stop();
            }
        };
    }, [connection]);

    const getFilter = () => {
        setCurrentPage(1);
        getOrders(orderNo, sender, recipient, startCreateAt, endCreateAt);
    }

    const handlePlay = () => {
        if (audioRef.current) {
            audioRef.current.muted = false; // Unmute the audio
            audioRef.current.play().catch((error) => {
                // Autoplay was prevented. Handle this case.
                console.error('Autoplay prevented:', error);
            });
        }
    };
    const handleBookPlay = () => {
        if (audioBookRef.current) {
            audioBookRef.current.muted = false; // Unmute the audio
            audioBookRef.current.play().catch((error) => {
                // Autoplay was prevented. Handle this case.
                console.error('Autoplay prevented:', error);
            });
        }
    };

    const clearFilter = () => {
        setOrderNo('');
        setStartCreateAt('');
        setEndCreateAt();
        setSender('');
        setRecipient('');
        getOrders('', '', '', '', '');
        handlePlay();
    }

    const getOrders = (orderNo, sender, recipient, startCreateAt, endCreateAt) => {
        Order.getOrders(status === 'all' ? '' : status, currentPage, limit, orderNo, sender, recipient, startCreateAt ? moment(startCreateAt).format("YYYY-MM-DD") : '', endCreateAt ? moment(endCreateAt).format("YYYY-MM-DD") : '')
            .then(response => {
                setOrders(response.data.rows);
                setTotalPages(Math.ceil(response.data.total / limit));
            })
            .catch(error => {
                console.log(error)
            })
    }

    const loadOrder = () => {
        Order.getOrders(status === 'all' ? '' : status, currentPage, limit, orderNo, sender, recipient, startCreateAt ? moment(startCreateAt).format("YYYY-MM-DD") : '', endCreateAt ? moment(endCreateAt).format("YYYY-MM-DD") : '')
            .then(response => {
                if (response.status === 200) {
                    setOrders(response.data.rows);
                    setTotalPages(Math.ceil(response.data.total / limit));
                    response.data.rows.map(m => {
                        if (m.number == selectedOrder.number) {
                            setSelectedOrder(m)
                        }
                    })
                }

            })
            .catch(error => {
                console.log(error);
            });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const handleFilter = (value) => {
        setCurrentPage(1);
        setStatus(value);
    }

    const renderList = () => {
        if (orders && orders.length > 0) {

            let list = orders.map(item => {
                return <OrderListItem key={item.id} item={item} selectOrder={(item) => setSelectedOrder(item)}
                                      getOrders={() => loadOrder()}
                                      editDate={(item) => setIsUpdateDateItem(item)}
                                      onCanceledOrder={() =>setCanceledOrderItem(item)}/>
            });

            return list;
        } else {
            return (
                <tr>
                    <td colSpan={7}>
                        <div style={{padding: '32px', textAlign: 'center'}}>По данному статусу заказов пока нет
                        </div>
                    </td>
                </tr>
            )
        }
    }

    const downloadOrderExport = () => {
        setLoading(true);
        Export.exportOrders(status === 'all' ? '' : status, currentPage, limit, orderNo, sender, recipient, startCreateAt ? moment(startCreateAt).format("YYYY-MM-DD") : '', endCreateAt ? moment(endCreateAt).format("YYYY-MM-DD") : '')
            .then((response) => {
                console.log(response);
                setLoading(false);
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });
    };

    const onCloseUpdateDate = (success) => {
        setIsUpdateDateItem(null);
        if (success) {
            getOrders(orderNo, sender, recipient, startCreateAt, endCreateAt);
        }
    }

    const onCloseCanceledOrder = (success) => {
        setCanceledOrderItem(null);
        if (success) {
            getOrders(orderNo, sender, recipient, startCreateAt, endCreateAt);
        }
    }

    return (
        <>
            <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px', flex: '1'}}>Заказы</div>
                    <div style={{fontSize: '15px'}}>Статус заказа:</div>
                    <div style={{marginLeft: '16px'}}>
                        <Form.Control
                            as="select"
                            value={status}
                            onChange={e => {
                                setStatus(e.target.value)
                            }}
                            style={{
                                fontSize: '14px',
                                border: 'none',
                                boxShadow: '0 2px 4px -2px rgb(228, 228, 228)',
                                width: '200px'
                            }}>
                            {ORDER_STATUSES_KEYS.map(item => {
                                return (
                                    <option key={item} value={item}>{ORDER_STATUSES[item]}</option>
                                );
                            })}
                        </Form.Control>
                    </div>
                </div>
                <div style={{textAlign: 'left', fontWeight: '600', fontSize: '18px', marginTop: '10px'}}>
                    Фильтр заказов
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{width: '100%', display: 'flex'}}>
                        <Form.Group style={{marginRight: '20px'}}>
                            <Form.Label style={{color: 'rgb(163, 172, 182)', fontSize: '12px', marginBottom: '5px'}}>№
                                заказа</Form.Label>
                            <Form.Control value={orderNo} onChange={(event) => setOrderNo(event.target.value)}
                                          style={{fontSize: '14px', width: '120px'}} placeholder='№ заказа'/>
                        </Form.Group>
                        <Form.Group style={{marginRight: '20px'}}>
                            <Form.Label style={{color: 'rgb(163, 172, 182)', fontSize: '12px', marginBottom: '5px'}}>
                                Дата начала созд.
                            </Form.Label>
                            <DatePicker
                                selected={startCreateAt}
                                onChange={(date) => setStartCreateAt(date)}
                                dateFormat="yyyy-MM-dd"
                                locale={ru}
                                placeholderText="Укажите дату"
                            />
                        </Form.Group>
                        <Form.Group style={{marginRight: '20px'}}>
                            <Form.Label style={{
                                color: 'rgb(163, 172, 182)',
                                fontSize: '12px',
                                marginBottom: '5px',
                                width: '130px'
                            }}>
                                Дата завершения созд.
                            </Form.Label>
                            <DatePicker
                                selected={endCreateAt}
                                onChange={(date) => setEndCreateAt(date)}
                                dateFormat="yyyy-MM-dd"
                                locale={ru}
                                placeholderText="Укажите дату"
                            />
                        </Form.Group>
                        <Form.Group style={{marginRight: '20px'}}>
                            <Form.Label style={{
                                color: 'rgb(163, 172, 182)',
                                fontSize: '12px',
                                marginBottom: '5px'
                            }}>Отправитель</Form.Label>
                            <Form.Control value={sender} onChange={(event) => setSender(event.target.value)}
                                          style={{fontSize: '14px', width: '200px'}} placeholder='Отправитель'/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label style={{
                                color: 'rgb(163, 172, 182)',
                                fontSize: '12px',
                                marginBottom: '5px'
                            }}>Получатель</Form.Label>
                            <Form.Control value={recipient} onChange={(event) => setRecipient(event.target.value)}
                                          style={{fontSize: '14px', width: '200px'}} placeholder='Получатель'/>
                        </Form.Group>
                    </div>
                    <div className="btn-groups">
                        <Button variant="primary" onClick={getFilter} className="btn-filter">Применить фильтр</Button>
                        <Button variant="primary" onClick={clearFilter} className="btn-clear">Очистить фильтр</Button>

                        <Button variant="primary" onClick={downloadOrderExport} style={{marginLeft: '20px'}}
                                className="btn-filter">
                            Выгрузить в Excel
                        </Button>
                    </div>
                </div>
                <Button onClick={handleButtonClick} type='button' style={{
                    marginTop: '10px',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    color: '#000',
                    width: '100%',
                    border: '1px solid #c9c8c8',
                    background: 'transparent'
                }}>Расширенный поиск</Button>
                <div
                    className='transition-container'
                    style={{
                        width: `${isWidth}%`,
                        height: `${isHeight}px`,
                        transition: 'width 1s, height 1s',
                        border: 'none',
                    }}
                >
                    <div className='fast-filter' style={{transition: "all 0.5s"}}>
                        {statusList.map((item) => (
                            <Button className={`item ${item.status === status ? 'active' : ''}`}
                                    onClick={() => handleFilter(item.status)}>
                                {item.title} <span>{item.count}</span>
                            </Button>
                        ))}
                    </div>
                </div>
                <div style={{marginTop: '20px'}}>
                    <Table bordered style={{fontSize: '14px', textAlign: 'left', backgroundColor: '#ffffff'}}>
                        <thead style={{textAlign: 'center'}}>
                        <tr style={{backgroundColor: '#ededed'}}>
                            <th>№ заказа</th>
                            <th>Дата создания</th>
                            <th>Отправитель</th>
                            <th>Получатель</th>
                            <th>Дата погрузки</th>
                            <th>Откуда</th>
                            <th>Куда</th>
                            <th>Дата выгрузки</th>
                            <th>Статус</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </Table>
                </div>
                {
                    orders &&
                    <Pagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={page => handlePageChange(page)}
                    />
                }
            </div>
            {selectedOrder &&
                <OrderInfoModal key='info-modal' item={selectedOrder} onClose={() => setSelectedOrder(false)}
                                getOrders={() => loadOrder()}/>}
            <audio ref={audioRef} muted src={sound_neworder}/>
            <audio ref={audioBookRef} muted src={sound_bookedorder}/>
            {loading === true && <Loader/>}
            {isUpdateDateItem && <UpdateShippingUploadingDate item={isUpdateDateItem} onClose={onCloseUpdateDate}/>}
            {canceledOrderItem && <CanceledOrderModal item={canceledOrderItem} onClose={onCloseCanceledOrder}/>}
        </>
    );
}
export default OrdersPage;
