import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {Document, Page, pdfjs} from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const PdfViewer = ({imgUrl, onClose,role}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    return (
        <>
            <div className="modal-overlay">
                <div style={{
                    backgroundColor: "#ffffff",
                    height: "600px",
                    width: "750px",
                    margin: "20px auto",
                    borderRadius: "6px",
                }}>
                    <div style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>

                        <div className="pdf-viewer">
                            <Document
                                file={window.URL.createObjectURL(imgUrl)}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={console.error}
                                className="pdf-document"
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Button style={{width: '200px'}} variant="outline-primary" onClick={() => {
                            onClose()
                        }}>Закрыть
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PdfViewer;
