export const ORDER_STATUSES = {
    all: "Все заказы",
    moderation: "На модерации",
    awaitingpayment: "Ожидает оплаты",
    published: "Опубликован",
    booked: "Забронирован водителем",
    confirmed: "Ожидание начала погрузки",
    processloadingcargo: "Идет погрузка груза",
    loadingcargomoderation: "Ожидает модерации погрузка",
    rejectedloadingcargomoderation: "Модератор отклонил погрузка",
    waitstarttrip: "Ожидание начала поездки",
    onthetrip: "В пути",
    processunloadingcargo: "Идет разгрузка",
    unloadingcargomoderation: "Ожидает модерации разгрузка",
    rejectedunloadingcargomoderation: "Модератор отклонил разгрузка",
    completed: "Завершен",
    rejected: "Отклонён",
    canceled: "Отменен"
};

export const ORDER_STATUSES_KEY = {
    moderation: "moderation",
    awaitingpayment: "awaitingpayment",
    published: "published",
    booked: "booked",
    confirmed: "confirmed",
    processloadingcargo: "processloadingcargo",
    loadingcargomoderation: "loadingcargomoderation",
    rejectedloadingcargomoderation: "rejectedloadingcargomoderation",
    waitstarttrip: "waitstarttrip",
    onthetrip: "onthetrip",
    processunloadingcargo: "processunloadingcargo",
    unloadingcargomoderation: "unloadingcargomoderation",
    rejectedunloadingcargomoderation: "rejectedunloadingcargomoderation",
    completed: "completed",
    rejected: "rejected",
    canceled: "canceled"
}

export const ORDER_STATUSES_KEYS = ['all',"moderation", "awaitingpayment", "published", "booked", "confirmed", "processloadingcargo", "loadingcargomoderation",
    "rejectedloadingcargomoderation", "waitstarttrip", "onthetrip", "processunloadingcargo", "unloadingcargomoderation",
    "rejectedunloadingcargomoderation", "completed", "rejected", "canceled"];

export const REJECT_STATUSES = {
    reject: "reject",
    rejectbook: "rejectbook",
    rejectloadingcargo: "rejectloadingcargo",
    rejectunloadingcargo: "rejectunloadingcargo"
}

export const acceptstatus = (status) => {
    switch (status) {
        case ORDER_STATUSES_KEY.moderation:
            return ORDER_STATUSES_KEY.awaitingpayment;
            break;
        case ORDER_STATUSES_KEY.booked:
            return ORDER_STATUSES_KEY.confirmed;
            break;
        case ORDER_STATUSES_KEY.loadingcargomoderation:
            return ORDER_STATUSES_KEY.waitstarttrip;
            break;
        case ORDER_STATUSES_KEY.unloadingcargomoderation:
            return ORDER_STATUSES_KEY.completed;
            break;
    }
}

export const rejectstatus = (status) => {
    switch (status) {
        case ORDER_STATUSES_KEY.moderation:
            return REJECT_STATUSES.reject;
            break;
        case ORDER_STATUSES_KEY.booked:
            return REJECT_STATUSES.rejectbook;
            break;
        case ORDER_STATUSES_KEY.loadingcargomoderation:
            return REJECT_STATUSES.rejectloadingcargo;
            break;
        case ORDER_STATUSES_KEY.unloadingcargomoderation:
            return REJECT_STATUSES.rejectunloadingcargo;
            break;
    }
}
