import React, {useEffect, useState} from "react";
import News from "../../../services/News";
import moment from "moment";
import {NEWS_TYPES} from "../models";

const NewsListItem = ({item, role, onDelete, onUpdate}) => {

    const [isLoading, setLoading] = useState(false);
    const [avatarData, setAvatarData] = useState(false);

    const getAvatarById = () => {
        setLoading(true)
        News.getPhoto(item.id)
            .then(res => res.data)
            .then(data => {
                setAvatarData(data)
            })
            .catch(e => {
                // requestErrorDisplay(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (item?.id) {

            getAvatarById();
        }
    }, [item?.id])

    const getTypeName = () => {
        return NEWS_TYPES.find(x => x.id === item.type).name;
    }

    return (
        <tr>
            <td style={{width: '170px'}}>
                <img className='photo'
                     src={avatarData?.base64 ? `data:${avatarData?.contentType};base64, ${avatarData?.base64}` : '/images/no-photo.png'}
                     alt="news photo"/>
            </td>
            <td style={{width: '80px'}}>
                {moment(item.updatedAt).format('DD.MM.yyyy')}
            </td>
            <td style={{width: '40px'}}>
                {item.lang}
            </td>
            <td style={{width: '180px'}}>
                {getTypeName()}
            </td>
            <td style={{width: '180px'}}>
                {item.url}
            </td>
            <td style={{width: '180px'}}>
                {item.title}
            </td>
            <td>
                {item.shortDescription}
            </td>
            <td>
                <p><b>{item.metaName}</b>: {item.metaContent}</p>
                <p>{item.h1Title}</p>
            </td>
            <td style={{width: '100px'}}>

                <div className='action'>
                    <img height="24px" src="/icons/edit.svg" onClick={() => onUpdate(item)}/>
                    {role === "superAdmin" && <img height="22px" src="/icons/delete.png" onClick={(e) => {
                        e.preventDefault();
                        onDelete(item.id);
                    }}/>}

                </div>
            </td>
        </tr>
    )
}
export default NewsListItem;
