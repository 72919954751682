import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import moment from "moment/moment";

const FilterModal = ({reportType, reportTitle, onClose, onDownload}) => {
    const [startCreateAt, setStartCreateAt] = useState('');
    const [endCreateAt, setEndCreateAt] = useState('');

    const [startShippingDate, setStartShippingDate] = useState('');
    const [endShippingDate, setEndShippingDate] = useState('');

    const handleDownload = () => {
        if (reportType === 'report2') {
            if (startCreateAt && endCreateAt)
                onDownload({
                    startCreateAt: moment(startCreateAt).format("YYYY-MM-DD"),
                    endCreateAt: moment(endCreateAt).format("YYYY-MM-DD")
                });
        } else if (reportType === 'report3') {
            if (startShippingDate && endShippingDate)
                onDownload({
                    startShippingDate: moment(startShippingDate).format("YYYY-MM-DD"),
                    endShippingDate: moment(endShippingDate).format("YYYY-MM-DD")
                });
        } else if (reportType === 'report4') {
            if (startCreateAt && endCreateAt)
                onDownload({
                    startDate: moment(startCreateAt).format("YYYY-MM-DD"),
                    endDate: moment(endCreateAt).format("YYYY-MM-DD")
                });
        }
    }

    return (
        <div className="modal-overlay">
            <div
                style={{
                    backgroundColor: "#ffffff",
                    height: "auto",
                    minHeight: "150px",
                    width: "370px",
                    margin: "200px auto",
                    borderRadius: "6px",
                }}>
                <div
                    style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                    <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                        <span style={{fontWeight: "600"}}>{reportTitle}</span>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={() => onClose(false)}>X</div>
                </div>
                {['report2', 'report4'].includes(reportType) && (
                    <div style={{margin: '10px', display: 'flex', justifyContent: 'center'}}>
                        <Form.Group style={{marginRight: '20px'}}>
                            <Form.Label style={{color: 'rgb(163, 172, 182)', fontSize: '12px', marginBottom: '5px'}}>
                                Дата начала созд.
                            </Form.Label>
                            <DatePicker
                                selected={startCreateAt}
                                onChange={(date) => setStartCreateAt(date)}
                                dateFormat="yyyy-MM-dd"
                                locale={ru}
                                placeholderText="Укажите дату"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label style={{
                                color: 'rgb(163, 172, 182)',
                                fontSize: '12px',
                                marginBottom: '5px',
                                width: '130px'
                            }}>
                                Дата завершения созд.
                            </Form.Label>
                            <DatePicker
                                selected={endCreateAt}
                                onChange={(date) => setEndCreateAt(date)}
                                dateFormat="yyyy-MM-dd"
                                locale={ru}
                                placeholderText="Укажите дату"
                            />
                        </Form.Group>
                    </div>
                )}
                {reportType === 'report3' && (<div style={{margin: '10px', display: 'flex', justifyContent: 'center'}}>
                    <Form.Group style={{marginRight: '20px'}}>
                        <Form.Label style={{color: 'rgb(163, 172, 182)', fontSize: '12px', marginBottom: '5px'}}>
                            Дата погрузки от
                        </Form.Label>
                        <DatePicker
                            selected={startShippingDate}
                            onChange={(date) => setStartShippingDate(date)}
                            dateFormat="yyyy-MM-dd"
                            locale={ru}
                            placeholderText="Укажите дату"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{
                            color: 'rgb(163, 172, 182)',
                            fontSize: '12px',
                            marginBottom: '5px',
                            width: '130px'
                        }}>
                            Дата погрузки с
                        </Form.Label>
                        <DatePicker
                            selected={endShippingDate}
                            onChange={(date) => setEndShippingDate(date)}
                            dateFormat="yyyy-MM-dd"
                            locale={ru}
                            placeholderText="Укажите дату"
                        />
                    </Form.Group>
                </div>)}
                <div style={{padding: '10px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={handleDownload} style={{marginRight: '20px'}} variant="primary">
                        Скачать
                    </Button>
                    <Button onClick={() => onClose(false)} variant="light" style={{marginRight: '20px'}}>
                        Закрыть
                    </Button>
                </div>

            </div>
        </div>
    )
        ;
};

export default FilterModal;
