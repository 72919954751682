import React, {useEffect, useState} from 'react';
import {SystemRoles} from '../../services';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import RoleCreateModal from './RoleCreateModal';
import {PERMISSIONS_DATA} from './permissions';
import Loader from '../../utils/loader';

const RolesPage = () => {

    const [roles, setRoles] = useState();
    const [showRolesModal, setShowRolesModal] = useState(false);
    const [activeRole, setActiveRole] = useState();
    const [modalType, setModalType] = useState();

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = () => {
        SystemRoles.getAll()
            .then(response => {
                console.log('ROLES ====>', response)
                if (response.status == 200) setRoles(response.data);
                else setRoles([]);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const renderPermissions = (data) => {
        if (data.length > 0) {
            const list = data.map(item => {
                return <Badge bg="primary" style={{marginRight: '6px'}}>{PERMISSIONS_DATA[item]}</Badge>
            });

            return list;
        } else {
            return <div>Разрешений нет</div>
        }
    }

    const renderList = () => {
        if (roles) {
            if (roles.length > 0) {
                const list = roles.map(item => {
                    return (
                        <ListGroup.Item style={{padding: '16px'}}>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '200px', fontWeight: '700'}}>{item.name}</div>
                                <div style={{flex: '1'}}>
                                    {renderPermissions(item.permissions)}
                                </div>
                                <div onClick={() => {
                                    setActiveRole(item);
                                    setModalType('edit')
                                }} style={{padding: '0 12px', cursor: 'pointer'}}>
                                    <img src="/icons/edit.svg"/>
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                });

                return list;
            } else {
                return (
                    <ListGroup.Item style={{padding: '16px'}}>Ролей нет</ListGroup.Item>
                );
            }
        } else {
            return (
                <ListGroup.Item style={{padding: '16px'}}> <Loader /></ListGroup.Item>
            );
        }
    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>Роли</div>
                <div>
                    <Button onClick={() => setModalType('create')} size="sm" variant="success">Добавить роль</Button>
                </div>
            </div>
            <div style={{marginTop: '20px'}}>
                <ListGroup>
                    {renderList()}
                </ListGroup>
            </div>
            {
                modalType &&
                <RoleCreateModal type={modalType} item={activeRole} onClose={() => {
                    setModalType();
                    getRoles();
                }} onSuccess={() => {
                    setModalType();
                    getRoles();
                }}/>
            }
        </>
    );
}

export default RolesPage
