import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {User} from "../../../services";

const PhotoViewer = ({driver, image, driverInfo, docType, handleChange, handleDownload, onClose}) => {

    const renderModerationFieldsUnderImage = () => {
        if (driver.state === "Moderation") {
            return (
                <div style={{
                    marginTop: "10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    {(docType.type === 3 && docType.side === 0) && <Form.Control
                        value={driverInfo.iin || ''}
                        onChange={(e) => handleChange('iin', e.target.value)}
                        size="lg"
                        type="text"
                        placeholder={"ИИН"}
                        style={{
                            fontSize: "16px",
                            padding: "12px 16px",
                            margin: "0px 16px",
                        }}
                    />}
                    {(docType.type === 3 && docType.side === 1) && <Form.Control
                        value={driverInfo.idNumber || ''}
                        onChange={(e) => handleChange('idNumber', e.target.value)}
                        size="lg"
                        type="text"
                        placeholder={"Номер удостоверения"}
                        style={{
                            fontSize: "16px",
                            padding: "12px 16px",
                            margin: "0px 8px",
                        }}
                    />}
                    {(docType.type === 3 && docType.side === 1) &&
                        <Form.Control
                            value={driverInfo.issuedBy || ''}
                            onChange={(e) => handleChange('issuedBy', e.target.value)}
                            size="lg"
                            type="text"
                            placeholder={"Кем выдан"}
                            style={{
                                fontSize: "16px",
                                padding: "12px 16px",
                                margin: "0px 8px",
                            }}
                        />}
                    {(docType.type === 3 && docType.side === 1) &&
                        <Form.Control
                            value={driverInfo.issuedDate}
                            onChange={(e) => handleChange('issuedDate', e.target.value)}
                            size="lg"
                            type="date"
                            placeholder={"когда выдан"}
                            style={{
                                fontSize: "16px",
                                padding: "12px 16px",
                                margin: "0px 8px",
                            }}
                        />}
                    {(docType.type === 4) &&
                        <Form.Control
                            value={driverInfo.carNumber || ''}
                            onChange={(e) => handleChange('carNumber', e.target.value)}
                            size="lg"
                            type="text"
                            placeholder={"Номер авто"}
                            style={{
                                fontSize: "16px",
                                padding: "12px 16px",
                                margin: "0px 16px",
                            }}
                        />
                    }
                    {(docType.type === 4) &&
                        <Form.Control
                            value={driverInfo.carModel || ''}
                            onChange={(e) => handleChange('carModel', e.target.value)}
                            size="lg"
                            type="text"
                            placeholder={"модель авто"}
                            style={{
                                fontSize: "16px",
                                padding: "12px 16px",
                                margin: "0px 16px",
                            }}
                        />}
                    {(docType.type === 7) &&
                        <Form.Control
                            value={driverInfo.trailerNumber || ''}
                            onChange={(e) => handleChange('trailerNumber', e.target.value)}
                            size="lg"
                            type="text"
                            placeholder={"Гос номер прицепа"}
                            style={{
                                fontSize: "16px",
                                padding: "12px 16px",
                                margin: "0px 16px",
                            }}
                        />}
                    {(docType.type === 7) &&
                        <Form.Control
                            value={driverInfo.trailerModel}
                            onChange={(e) => handleChange('trailerModel', e.target.value)}
                            size="lg"
                            type="text"
                            placeholder={"Марка, модель прицепа"}
                            style={{
                                fontSize: "16px",
                                padding: "12px 16px",
                                margin: "0px 16px",
                            }}
                        />}
                    {image ? (
                        <Button
                            onClick={handleDownload}
                            variant="primary"
                            style={{
                                marginRight: "12px",
                                background: "rgb(163, 25, 91)",
                                borderColor: "rgb(163, 25, 91)",
                            }}>
                            Скачать
                        </Button>
                    ) : (
                        ""
                    )}
                    <Button
                        onClick={onClose}
                        variant="primary"
                        style={{marginRight: "12px"}}
                    >
                        Закрыть
                    </Button>
                </div>
            );
        } else
            return (
                <div style={{
                    marginTop: "10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    {image ? (
                        <Button onClick={handleDownload}
                                variant="primary"
                                style={{
                                    marginRight: "12px",
                                    background: "rgb(163, 25, 91)",
                                    borderColor: "rgb(163, 25, 91)",
                                }}>
                            Скачать
                        </Button>
                    ) : (
                        ""
                    )}
                    <Button onClick={onClose} variant="primary" style={{marginRight: "12px"}}>
                        Закрыть
                    </Button>
                </div>
            );
    };

    return (
        <>
            <div className="modal-overlay">
                <div style={{
                    backgroundColor: "#ffffff",
                    height: "600px",
                    width: "750px",
                    margin: "20px auto",
                    borderRadius: "6px",
                }}>
                    <div style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                        <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                            <img width="700px" height="500px" style={{objectFit: 'cover'}} src={image} alt=""/>
                        </div>
                    </div>
                    {renderModerationFieldsUnderImage()}
                </div>
            </div>
        </>
    );
};

export default PhotoViewer;
