import React, { useEffect, useLayoutEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import NotificationsIcon from '@mui/icons-material/Notifications';

import './NotificationBell.scss';
import { NavLink } from 'react-router-dom';
import NotifyService from '../../../services/Notification';


const NotificationBell = () => {
    const [notificationsTotal, setNotificationsTotal] = useState(undefined);

    const updateNotificationsState = () => {
        NotifyService.getQuantityNotifications({ isRead: false })
            .then((res) => {
                setNotificationsTotal(res.data.total)
            })
            .catch(error => {
                console.log(error)
            });
    }

    useEffect(() => {
        updateNotificationsState();
        const intervalId = setInterval(updateNotificationsState, 2 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='notification__body'>
            <NavLink className='notification' to={"/notification"}>
                <div className='notification__icon'><NotificationsIcon sx={{ fontSize: 40 }} /></div>
                <div className='notification__count'>{notificationsTotal}</div>
            </NavLink>
        </div>
    );
}

export default NotificationBell;