import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {SystemRoles, User} from "../../services";
import Button from "react-bootstrap/Button";
import Loader from "../../utils/loader";
import Table from "react-bootstrap/Table";
import Pagination from "react-responsive-pagination";
import CreateModal from "./Components/CreateModal";
import DeleteModal from "./Components/DeleteModal";
import ClientContract from "../../services/ClientContract";
import ListItem from "./Components/ListItem";
import {toast} from "react-toastify";

const ClientContractPage = ({status, title}) => {
    const {user} = useSelector(state => state);
    const [contractList, setContractList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    const [deleteId, setDeleteId] = useState();
    const [role, setRole] = useState();
    const [editMode, setEditMode] = useState(undefined);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [isDelete, setIsDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState(false);

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    if (response.data) setRole(response.data.code)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    useEffect(() => {
        getList();
    }, [currentPage]);

    useEffect(() => {
        getClients();
    }, [])

    const getList = () => {
        setLoading(true);
        ClientContract.list(currentPage, limit).then(response => {
            setContractList(response.data.rows);
            setTotalPages(Math.ceil(response.data.total / limit));
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        });
    }

    const getClients = () => {
        User.getUsers('', 'client', 1, 1000, '', '', '', '')
            .then(response => {
                response.data.rows.forEach(item => {
                    item.label = `${item.name} - ${item.bin} (${item.email})`;
                });
                setClients(response.data.rows);
            }).catch(res => {
        });
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const renderList = () => {
        if (contractList && role) {
            const list = contractList.map(item => {
                return (<ListItem key={item.id} item={item} role={role} onUpdate={handleUpdate}
                                  onDelete={handleDelete}/>);
            });
            return list;
        }
    }

    const onClose = (isSuccess) => {
        setEditMode(undefined);
        setSelectedItem(null);
        if (isSuccess) {
            getList();
        }
    }

    const handleUpdate = (item) => {
        ClientContract.getById(item.id)
            .then(res => {
                if (res.data) {
                    setSelectedItem(res.data);
                    setEditMode('update');
                }
            })
            .finally(() => {
                // setLoading(false);
            })
    }

    const handleDelete = (id) => {
        setDeleteId(id);
        setIsDelete(true);
    }

    const onDeleteYes = (id) => {
        if (id) {
            setLoading(true);
            ClientContract.delete(id)
                .then((response) => {
                    setLoading(false);
                    onDeleteClose(true);
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error('Произошла ошибка, попробуйте позже');
                });
        }
    }

    const onDeleteClose = (isSuccess) => {
        setDeleteId(undefined);
        setIsDelete(false);
        if (isSuccess) {
            getList();
        }
    }

    return (
        <>
            <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>Журнал договоров с Контрагентами</div>
            <div style={{marginTop: '20px'}}>
                <div style={{marginBottom: '20px'}}>
                    {/*<div style={{textAlign: 'left', fontWeight: '600', fontSize: '18px', marginTop: '10px'}}>*/}
                    {/*    Фильтр*/}
                    {/*</div>*/}
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{
                            marginTop: '20px',
                            marginLeft: '20px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button variant="primary" onClick={() => setEditMode('create')} style={{
                                backgroundColor: 'rgb(60, 179, 113)',
                                padding: '0px 15px 6px 15px',
                                border: 'none',
                                borderRadius: '8px',
                                color: '#ffffff',
                                marginRight: '20px'
                            }}>Создать журнал договоров</Button>
                        </div>
                    </div>
                </div>
                {loading ? <Loader/> : (
                    <Table bordered className='news-table'>
                        <thead style={{textAlign: 'center'}}>
                        <tr style={{backgroundColor: '#ededed'}}>
                            <th>№</th>
                            <th>Название договора</th>
                            <th>Контрагент</th>
                            <th>Дата заключения</th>
                            <th>Срок действия</th>
                            <th>Условия оплаты</th>
                            <th>Сумма договора</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </Table>
                )}
            </div>
            {
                contractList &&
                (!loading &&
                    <Pagination current={currentPage} total={totalPages}
                                onPageChange={page => handlePageChange(page)}/>)
            }
            {editMode &&
                <CreateModal item={selectedItem} clients={clients} role={role} editMode={editMode} onClose={onClose}/>}
            {isDelete && <DeleteModal deleteId={deleteId} onYes={onDeleteYes} onClose={onDeleteClose}/>}
        </>
    );
}

export default ClientContractPage;
