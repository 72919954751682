import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Loader from "../../../utils/loader";
import Pagination from "react-responsive-pagination";
import DeleteModal from "../../common/DeleteModal";
import React, {useEffect, useState} from "react";
import Insurance from "../../../services/Insurance";
import {toast} from "react-toastify";
import ListItemComponent from "./ListItemComponent";

const ListComponent = ({role}) => {
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    const [deleteId, setDeleteId] = useState();

    const [isDelete, setIsDelete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [orderNumber, setOrderNumber] = useState('');

    useEffect(() => {
        getList(orderNumber);
    }, [currentPage]);

    const getList = (no) => {
        setLoading(true);
        Insurance.list(currentPage, limit, no).then(response => {
            setList(response.data.rows);
            setTotalPages(Math.ceil(response.data.total / limit));
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })

    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const onHandleClear = () => {
        setOrderNumber();
        getList('')
    }

    const onHandleFilter = () => {
        getList(orderNumber);
    }

    const onHandleDownload = (item) => {

        const responseData = JSON.parse(item.responseData);
        setLoading(true);

        if (responseData.data.external_id) {
            Insurance.contracts(responseData.data.external_id).then(response => {

                let data = response.data;
                if (response.data && response.data.success === true) {
                    // download file
                    download(response.data.data.number);
                } else {
                    setLoading(false);
                }

            }).catch(error => {
                if (error.errorText) {
                    console.log('err', error);
                    const errObj = JSON.parse(error.errorText);
                    if (errObj && errObj.message) {
                        toast.error(errObj.message);
                    }
                }
                setLoading(false);
            });
        } else {
            download(responseData.data.number);
        }
    }

    const download = (number) => {
        Insurance.downloadContracts(number).then(response2 => {

            const file = new Blob([response2.data], {
                type: response2.data.type,
            });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        }).catch(error => {
            toast('Договор не найден!');
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleDelete = (id) => {
        setDeleteId(id);
        setIsDelete(true);
    }

    const onHandleDeleteClose = () => {
        setDeleteId(undefined);
        setIsDelete(false);
    }

    const onHandleDeleteYes = (id) => {
        setLoading(true);
        Insurance.delete(id).then(response => {
            setLoading(false);
            setDeleteId(undefined);
            setIsDelete(false);
            getList();
        }).catch(error => {
            setLoading(false);
        })
    }

    const renderList = () => {
        if (list && role) {

            return list.map(item => {
                return (<ListItemComponent key={item.id} item={item} role={role} onDownload={onHandleDownload}
                                           onDelete={handleDelete}/>);
            });
        }
    }

    return (<>
        <div className="insurance__filter">
            <Form.Group style={{marginRight: '20px'}}>
                <Form.Control value={orderNumber} onChange={(event) => setOrderNumber(event.target.value)}
                              placeholder='Номер заказа'/>
            </Form.Group>
            <Button variant="primary" onClick={onHandleFilter} className="btn btn-filter">
                Применить фильтр
            </Button>
            <Button variant="primary" onClick={onHandleClear} className='btn btn-clear-filter'>
                Очистить фильтр
            </Button>
        </div>
        <Table bordered className='insurance__table'>
            <thead style={{textAlign: 'center'}}>
            <tr style={{backgroundColor: '#ededed'}}>
                <th>Дата</th>
                <th>Номер заказа</th>
                <th style={{width: '200px'}}>Запрос</th>
                <th style={{width: '200px'}}>Результат</th>
                <th>Статус</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {renderList()}
            </tbody>
        </Table>
        {loading && <Loader/>}
        <div className='insurance__pagination'>
            {list && <Pagination current={currentPage} total={totalPages}
                                 onPageChange={page => handlePageChange(page)}/>}
        </div>
        {isDelete && <DeleteModal deleteId={deleteId} onYes={onHandleDeleteYes} onClose={onHandleDeleteClose}/>}
    </>)
}

export default ListComponent;