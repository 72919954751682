import {destroy, get, post, put} from "./config";

const News = {
    list: (page, limit) =>
        get(`v1/news/list?page=${page}&limit=${limit}`),
    getById: (newsId) =>
        get(`v1/news/by-${newsId}`),
    create: (body) =>
        post(`v1/news`, body),
    update: (id, body) =>
        put(`v1/news/${id}`, body),
    delete: (id) =>
        destroy(`v1/news/${id}`),
    uploadPhoto: (data) =>
        post(`v1/newsfile/document`, data),
    getPhoto: (newsId) =>
        get(`v1/newsfile/base64/${newsId}`),
}

export default News;
