import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {PERMISSIONS, PERMISSIONS_DATA} from './permissions';
import {SystemRoles} from '../../services';

const RoleCreateModal = ({type, item, onClose, onSuccess}) => {

    useEffect(() => {
        if (item) {
            setName(item.name);
            setCode(item.code || '');
            setActivePermissions(item.permissions)
        }
    }, []);

    const [name, setName] = useState();
    const [code, setCode] = useState();
    const [activePermission, setActivePermissions] = useState([]);
    const [errorTitle, setErrorTitle] = useState();

    const onCheckboxClick = (value) => {
        let tmpPermission = activePermission;
        if (tmpPermission.includes(value)) {
            let index = tmpPermission.indexOf(value);
            if (index !== -1) {
                tmpPermission.splice(index, 1);
                setActivePermissions([...tmpPermission]);
            }
        } else {
            console.log('else')
            tmpPermission.push(value);
            console.log(tmpPermission);
            setActivePermissions([...tmpPermission]);
        }
    }

    const onSubmit = () => {
        if (!name) setErrorTitle('Необходимо заполнить название роли');
        else if (activePermission.length == 0) setErrorTitle('Необходимо выбрать хотя бы одно разрешение');
        else {
            setErrorTitle();
            if (type === 'create') {
                const data = {
                    'name': name,
                    'code': code,
                    'permissions': activePermission
                }
                SystemRoles.addRole(data).then(response => {
                    if (response.status == 200) {
                        onSuccess();
                    } else alert('Произошла ошибка, попробуйте позже');
                }).catch(error => {
                    console.log(error);
                    alert('Произошла ошибка, попробуйте позже')
                });
            } else if (type === 'edit') {

                const data = {
                    'id': item.id,
                    'name': name,
                    'code': code,
                    'permissions': activePermission
                }

                SystemRoles.updateRole(data).then(response => {
                    if (response.status == 200) {
                        onSuccess();
                    } else alert('Произошла ошибка, попробуйте позже');
                }).catch(error => {
                    console.log(error)
                    alert('Произошла ошибка, попробуйте позже')
                });
            } else return alert('Попробуйте позже!')

        }
    }

    const renderList = () => {
        const list = PERMISSIONS.map(item => {
            return (
                <div className='col-6' style={{padding: '6px 0'}}>
                    <Form.Check
                        type="switch"
                        label={`${PERMISSIONS_DATA[item]}`}
                        checked={activePermission.includes(item)}
                        onChange={() => onCheckboxClick(item)}
                        style={{fontSize: '15px'}}/>
                </div>
            );
        });

        return <div className='row' style={{margin: '0', marginTop: '6px'}}>{list}</div>;
    }

    return (
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '700px',
                margin: '24px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff'}}>
                        {item ? 'Редактирование роли' : 'Добавление роли'}
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={onClose}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    <div style={{padding: '16px'}}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Название роли</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}
                                          placeholder="Введите название"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Код</Form.Label>
                            <Form.Control type="text" value={code} onChange={(e) => setCode(e.target.value)}
                                          placeholder="Введите код"/>
                        </Form.Group>
                        <div style={{color: '#A3ACB6', fontSize: '15px', marginTop: '20px'}}>
                            Выберите доступные разрешения
                        </div>
                        {renderList()}
                        {errorTitle ? <div style={{marginTop: '16px', color: 'red'}}>{errorTitle}</div> : null}
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '30px'}}>
                            <div><Button onClick={onClose} variant="secondary">Отменить</Button></div>
                            <div><Button onClick={onSubmit}
                                         variant="success">{type === 'create' ? 'Добавить роль' : 'Сохранить изменения'}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RoleCreateModal;
