import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import {Invoice, User, SystemRoles} from '../../services';
import moment from 'moment';
import Pagination from 'react-responsive-pagination';
import Button from "react-bootstrap/Button";
import Loader from "../../utils/loader";
import CreateNewsModal from "./Components/CreateNewsModal";
import News from "../../services/News";
import DeleteNewsModal from "./Components/DeleteNewsModal";
import './newsPage.scss';
import NewsListItem from "./Components/NewsListItem";

const NewsPage = ({status, title}) => {
    const {user} = useSelector(state => state);
    const [newsList, setNewsList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    const [deleteId, setDeleteId] = useState();
    const [role, setRole] = useState();
    const [editMode, setEditMode] = useState(undefined);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [isDelete, setIsDelete] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    if (response.data) setRole(response.data.code)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    useEffect(() => {
        getList();
    }, [currentPage]);

    const getList = () => {
        setLoading(true);
        News.list(currentPage, limit).then(response => {
            setNewsList(response.data.rows);
            setTotalPages(Math.ceil(response.data.total / limit));
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })

    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const renderList = () => {
        if (newsList && role) {
            const list = newsList.map(item => {
                return (<NewsListItem key={item.id} item={item} role={role} onUpdate={handleUpdate}
                                      onDelete={handleDelete}/>);
            });
            return list;
        }
    }

    const onClose = (isSuccess) => {
        setEditMode(undefined);
        if (isSuccess) {
            getList();
        }
    }

    const handleUpdate = (item) => {
        News.getById(item.id)
            .then(res => {
                if (res.data) {
                    setSelectedItem(res.data);
                    setEditMode('update');
                }
            })
            .finally(() => {
                // setLoading(false);
            })
    }

    const handleDelete = (id) => {
        setDeleteId(id);
        setIsDelete(true);

    }
    const onDeleteClose = (isSuccess) => {
        setDeleteId(undefined);
        setIsDelete(false);
        if (isSuccess) {
            getList();
        }
    }

    return (
        <>
            <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>{title}</div>
            <div style={{marginTop: '20px'}}>
                <div style={{marginBottom: '20px'}}>
                    <div style={{textAlign: 'left', fontWeight: '600', fontSize: '18px', marginTop: '10px'}}>
                        Фильтр новостей
                    </div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{
                            marginTop: '20px',
                            marginLeft: '20px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button variant="primary" onClick={() => setEditMode('create')} style={{
                                backgroundColor: 'rgb(60, 179, 113)',
                                padding: '0px 15px 6px 15px',
                                border: 'none',
                                borderRadius: '8px',
                                color: '#ffffff',
                                marginRight: '20px'
                            }}>Создать</Button>
                        </div>
                    </div>
                </div>
                {loading ? <Loader/> : (
                    <Table bordered className='news-table'>
                        <thead style={{textAlign: 'center'}}>
                        <tr style={{backgroundColor: '#ededed'}}>
                            <th style={{verticalAlign:'middle'}}>Фото</th>
                            <th style={{verticalAlign:'middle'}}>Дата</th>
                            <th style={{verticalAlign:'middle'}}>Язык</th>
                            <th style={{verticalAlign:'middle'}}>Тип</th>
                            <th style={{verticalAlign:'middle'}}>Url</th>
                            <th style={{verticalAlign:'middle'}}>Заголовок</th>
                            <th style={{verticalAlign:'middle'}}>Краткое описание</th>
                            <th style={{verticalAlign:'middle'}}>Мета теги и H1 заголовок</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </Table>
                )}
            </div>
            {
                newsList &&
                (!loading &&
                    <Pagination current={currentPage} total={totalPages}
                                onPageChange={page => handlePageChange(page)}/>)
            }
            {editMode && <CreateNewsModal item={selectedItem} editMode={editMode} onClose={onClose}/>}
            {isDelete && <DeleteNewsModal deleteId={deleteId} onClose={onDeleteClose}/>}
        </>
    );
}

export default NewsPage;
