import React, {useEffect, useState} from 'react';
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import Badge from 'react-bootstrap/Badge';
import {SystemRoles, User, Order} from '../../services';
import ClientInfoModal from './ClientInfoModal';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-responsive-pagination';
import {useSelector} from "react-redux";
import Form from "react-bootstrap/Form";
import Loader from '../../utils/loader';
import DeleteModal from "./DeleteModal";
import {phoneNumberFormat} from "../../utils";
import Export from "../../services/Export";

export const DRIVER_STATUSES_DATA = {
    "Draft": {title: 'Черновик', color: '#737373'},
    "Pending": {title: 'Зарешение регистрации', color: '#f0be39'},
    "Moderation": {title: 'Ожидает модерации', color: '#0275d8'},
    'Confirmed': {title: 'Верифицирован', color: '#5cb85c'},
    "Rejected": {title: 'Модерация отклонена', color: 'red'},
    "UnActive": {title: 'Не активен', color: 'red'}
}

export const CLIENT_STATUSES = [{id: '', name: 'Все'},
    {id: 'pending', name: 'Зарешение регистрации'},
    {id: 'moderation', name: 'Ожидает модерации'},
    {id: 'confirmed', name: 'Верифицирован'},
    {id: 'rejected', name: 'Модерация отклонена'}];

const ClientsPage = ({status, title}) => {
    const {user} = useSelector(state => state);
    const [clients, setClients] = useState();
    const [selectedClient, setSelectedClient] = useState();
    const [isDelete, setIsDelete] = useState(false);
    const [activeTab, setActiveTab] = useState('client');
    const [state, setState] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [bin, setBin] = useState('');
    const [email, setEmail] = useState('');
    const [stateList, setStateList] = useState(CLIENT_STATUSES);

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState(120);
    const [role, setRole] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    if (response.data) setRole(response.data.code)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    const getClient = (state, name, phoneNumber, bin, email) => {
        setLoading(true);
        User.getUsers(state, activeTab, currentPage, limit, name, phoneNumber, bin, email)
            .then(response => {
                setClients(response.data.rows);
                setTotalPages(Math.ceil(response.data.total / limit));
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getClient(state, name, phoneNumber, bin, email);
    }, [state, activeTab, currentPage]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const selectedClientItem = (item) => {
        setSelectedClient(item);
    }

    const handleCloseDeleteModal = (success) => {
        setSelectedClient(null);
        setIsDelete(false);
        if (success) {
            getClient(state,name, phoneNumber, bin, email);
        }
    }

    const downloadOrderExport = () => {
        setLoading(true);
        Export.exportClients(status, activeTab, name, phoneNumber, bin, email)
            .then((response) => {
                console.log(response);
                setLoading(false);
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    const handleSelect = (value) => {
        setActiveTab(value);
        setCurrentPage(1);
    }

    const handleFilter = () => {
        setCurrentPage(1);
        getClient('all',name, phoneNumber, bin, email);
    }

    const handleClearFilter = () => {
        setName('');
        setEmail('');
        setBin('');
        setPhoneNumber('');
        setCurrentPage(1);
        getClient('all', '', '', '','');
    }
    const renderTabs = () => {
        return (
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '4px'}}>
                <div className="tabs">
                    <Button onClick={() => handleSelect('client')} size="sm"
                            className={activeTab === 'client' ? 'active' : ''}>
                        Компания клиент
                    </Button>
                    <Button onClick={() => handleSelect('expeditor')} size="sm"
                            className={activeTab === 'expeditor' ? 'active' : ''}>
                        Транспортная компания
                    </Button>
                </div>
            </div>
        );
    }

    const renderList = () => {
        if (clients) {
            const list = clients.map(item => {
                return (
                    <tr onDoubleClick={() => selectedClientItem(item)}>
                        <td onClick={() => selectedClientItem(item)}>
                            <div
                                style={{padding: '5px 0'}}>{item.companyName} {item.companyId !== null && `(${item.name} ${item.surname})`}</div>
                        </td>
                        <td onClick={() => selectedClientItem(item)}>
                            <div style={{padding: '5px 0'}}>{item.bin}</div>
                        </td>
                        <td onClick={() => selectedClientItem(item)}>
                            <div style={{padding: '5px 0'}}>{phoneNumberFormat(item.phoneNumber)}</div>
                        </td>
                        <td onClick={() => selectedClientItem(item)}>
                            <div style={{padding: '5px 0'}}>{item.email}</div>
                        </td>
                        <td onClick={() => selectedClientItem(item)}>
                            <div style={{padding: '5px 0'}}>
                                <div style={{
                                    backgroundColor: `${DRIVER_STATUSES_DATA[item.state].color}`,
                                    color: '#ffffff',
                                    fontSize: '11px',
                                    padding: '1px 6px',
                                    fontWeight: '600',
                                    borderRadius: '6px',
                                    display: 'inline-block'
                                }}>{DRIVER_STATUSES_DATA[item.state].title}</div>
                            </div>
                        </td>
                        <td>
                            {
                                role == "superAdmin" ?
                                    <div style={{
                                        width: "30px",
                                        textAlign: "center",
                                        padding: "5px 0",
                                        cursor: "pointer",
                                    }} onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedClient(item);
                                        setIsDelete(true);
                                    }}>
                                        <img height="17px" src="/icons/delete.png"/>
                                    </div>
                                    : ''
                            }
                        </td>
                    </tr>
                );
            });
            return list;
        }
    }

    return (
        <>
            <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>{title}</div>
            {renderTabs()}
            <div style={{marginTop: '20px'}}>
                <div>
                    <div style={{textAlign: 'left', fontWeight: '600', fontSize: '18px', marginTop: '10px'}}>
                        Фильтр клиентов
                    </div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: '100%', display: 'flex'}}>
                            <Form.Group style={{marginRight: '20px'}}>
                                <Form.Label style={{
                                    color: 'rgb(163, 172, 182)',
                                    fontSize: '12px',
                                    marginBottom: '5px'
                                }}>Статус</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={state}
                                    onChange={e => setState(e.target.value)}
                                    style={{
                                        fontSize: '14px',
                                        border: 'none',
                                        boxShadow: '0 2px 4px -2px rgb(228, 228, 228)',
                                        width: '200px'
                                    }}>
                                    {stateList.map(item => {
                                        return (
                                            <option value={item.id}>{item.name}</option>
                                        );
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group style={{marginRight: '20px'}}>
                                <Form.Label
                                    style={{color: 'rgb(163, 172, 182)', fontSize: '12px', marginBottom: '5px'}}>
                                    Наз. организации
                                </Form.Label>
                                <Form.Control style={{fontSize: '14px', width: '200px'}} placeholder='Наз. организации'
                                              value={name} onChange={(event) => setName(event.target.value)}/>
                            </Form.Group>
                            <Form.Group style={{marginRight: '20px'}}>
                                <Form.Label style={{
                                    color: 'rgb(163, 172, 182)',
                                    fontSize: '12px',
                                    marginBottom: '5px'
                                }}>БИН</Form.Label>
                                <Form.Control style={{fontSize: '14px', width: '150px'}} placeholder='БИН'
                                              value={bin} onChange={(event) => setBin(event.target.value)}/>
                            </Form.Group>
                            <Form.Group style={{marginRight: '20px'}}>
                                <Form.Label
                                    style={{color: 'rgb(163, 172, 182)', fontSize: '12px', marginBottom: '5px'}}>
                                    Номер телефона
                                </Form.Label>
                                <Form.Control style={{fontSize: '14px', width: '150px'}} placeholder='Номер телефона'
                                              value={phoneNumber}
                                              onChange={(event) => setPhoneNumber(event.target.value)}/>
                            </Form.Group>
                            <Form.Group style={{marginRight: '20px'}}>
                                <Form.Label style={{
                                    color: 'rgb(163, 172, 182)',
                                    fontSize: '12px',
                                    marginBottom: '5px'
                                }}>Email</Form.Label>
                                <Form.Control type="email" style={{fontSize: '14px', width: '150px'}}
                                              placeholder='Email' value={email}
                                              onChange={(event) => setEmail(event.target.value)}/>
                            </Form.Group>
                        </div>
                        <div className="btn-groups">
                            <Button variant="primary" className="btn-filter" onClick={handleFilter}>
                                Применить фильтр
                            </Button>
                            <Button variant="primary" className="btn-clear" onClick={handleClearFilter}>
                                Очистить фильтр
                            </Button>
                            <Button variant="primary" className="btn-filter" style={{marginLeft: '10px'}}
                                    onClick={downloadOrderExport}>
                                Выгрузить в Excel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{marginTop: '20px'}}>
                <Table bordered style={{fontSize: '14px', textAlign: 'left', backgroundColor: '#ffffff'}}>
                    <thead style={{textAlign: 'center'}}>
                    <tr style={{backgroundColor: '#ededed'}}>
                        <th>Название организации</th>
                        <th>БИН</th>
                        <th>Номер телефона</th>
                        <th>Email</th>
                        <th>Статус</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderList()}
                    </tbody>
                </Table>
                {loading && <Loader/>}
            </div>
            {
                clients &&
                (!loading &&
                    <Pagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={page => handlePageChange(page)}
                    />)
            }
            {(!isDelete && selectedClient) && (<ClientInfoModal activeTab={activeTab} item={selectedClient}
                                                                onClose={() => setSelectedClient()}
                                                                getClient={() => getClient()}
                                                                role={role}/>)}
            {(isDelete && selectedClient) &&
                <DeleteModal activeTab={activeTab} client={selectedClient}
                             user={user} onClose={handleCloseDeleteModal}/>}
        </>
    );
}

export default ClientsPage;
