import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment/moment";
import {Order} from "../../../services";

const UpdateShippingUploadingDate = ({item, onClose}) => {
    const [shippingDate, setShippingDate] = useState(item ? moment(item.shippingDate).format('yyyy-MM-DD') : '');
    const [unloadingDate, setUnloadingDate] = useState(item ? moment(item.unloadingDate).format('yyyy-MM-DD') : '');

    const onSave = () => {
        Order.updateShippingUnloadingDate(item.id, {shippingDate: shippingDate, unloadingDate: unloadingDate})
            .then((response) => {
                onClose(true);
            })
            .catch((error) => {
                console.log(error);
                alert("Произошла ошибка, попробуйте позже")
            });
    }
    return (
        <>
            <div className="modal-overlay">
                <div style={{
                    backgroundColor: "#ffffff",
                    height: "300px",
                    width: "400px",
                    margin: "20px auto",
                    borderRadius: "6px",
                }}>
                    <div style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                    </div>
                    <div style={{padding: '10px'}}>
                        <Form.Group className="mb-3 w-90">
                            <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>
                                Дата погрузки
                            </Form.Label>
                            <Form.Control type="date" value={shippingDate} placeholder="Введите дата"
                                          onChange={(e) => setShippingDate(e.target.value)}/>
                        </Form.Group>
                    </div>
                    <div style={{padding: '10px'}}>
                        <Form.Group className="mb-3 w-90">
                            <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>
                                Дата выгрузки
                            </Form.Label>
                            <Form.Control type="date" value={unloadingDate} placeholder="Введите дата"
                                          onChange={(e) => setUnloadingDate(e.target.value)}/>
                        </Form.Group>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '5px',
                        padding: '10px'
                    }}>
                        <Button style={{width: '200px'}} variant="outline-primary" onClick={onSave}>Сохранить
                        </Button>
                        <Button style={{width: '200px'}} variant="outline-primary" onClick={() => {
                            onClose()
                        }}>Закрыть
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateShippingUploadingDate;
