import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {Document, Page, pdfjs} from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Table from "react-bootstrap/Table";

const ExpeditorDrivers = ({drivers}) => {

    const renderList = () => {
        if (drivers.length > 0) {
            return drivers.map(item => {
                return (
                    <tr>
                        <td>
                            <div style={{padding: '5px 0'}}>{item.surname} {item.name} {item.patronymic}</div>
                        </td>
                        <td>
                            <div style={{padding: '5px 0'}}>{item.phoneNumber}</div>
                        </td>
                        <td>
                            <div style={{padding: '5px 0'}}>{item.email}</div>
                        </td>
                    </tr>
                );
            });
        }
    }

    return (
        <>
            <div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                    paddingBottom: "10px",
                    marginTop: "14px",
                }}>
                    <div style={{
                        fontSize: "15px",
                        color: "#A3ACB6",
                        lineHeight: "1.3",
                        fontWeight: "600",
                    }}>
                        Водители
                    </div>
                </div>
                <div className="row" style={{marginTop: "10px"}}>
                    <Table bordered style={{
                        fontSize: '14px',
                        textAlign: 'left',
                        backgroundColor: '#ffffff'
                    }}>
                        <thead style={{textAlign: 'center'}}>
                        <tr style={{backgroundColor: '#ededed'}}>
                            <th>ФИО</th>
                            <th>Номер телефона</th>
                            <th>Email</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default ExpeditorDrivers;
