import React, {useEffect, useState} from "react";
import News from "../../../services/News";
import {NEWS_TYPES} from "../../news/models";
import moment from "moment/moment";

const ListItem = ({item, role, onDelete, onUpdate}) => {
    const [isLoading, setLoading] = useState(false);
    const [avatarData, setAvatarData] = useState(false);

    return (
        <tr>
            <td style={{width: '40px'}}>
                {item.number}
            </td>
            <td style={{width: '180px'}}>
                {item.name}
            </td>
            <td style={{width: '80px'}}>
                {item.clientName}
            </td>
            <td style={{width: '80px'}}>
                {moment(item.dateSigned).format('DD.MM.yyyy')}
            </td>
            <td style={{width: '180px'}}>
                {moment(item.startDate).format('DD.MM.yyyy')} - {moment(item.endDate).format('DD.MM.yyyy')}
            </td>
            <td>
                {item.paymentTerms}
            </td>
            <td>
                {item.Sum}
            </td>
            <td style={{width: '100px'}}>

                <div className='action'>
                    <img height="24px" src="/icons/edit.svg" onClick={() => onUpdate(item)}/>
                    {role === "superAdmin" && <img height="22px" src="/icons/delete.png" onClick={(e) => {
                        e.preventDefault();
                        onDelete(item.id);
                    }}/>}

                </div>
            </td>
        </tr>
    )
}
export default ListItem;
