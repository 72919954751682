import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { Metric, ExportReport } from '../../services';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import stringToAtomicBuffer from '../../utils/stringToAtomicBuffer'
import Loader from '../../utils/loader';




const MetricsPage = () => {
    const [metricsDictGlobal, setMetricsDictGlobal] = useState('');
    const [metricsDictWindow, setMetricsDictWindow] = useState('');
    const [createdAtFrom, setCreatedAtFrom] = useState('');
    const [createdAtTo, setCreatedAtTo] = useState('');
    const [loadingGlobal, setLoadingGlobal] = useState(true);
    const [loadingWindow, setLoadingWindow] = useState(false);

    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        getMetricsDict('', '', setMetricsDictGlobal, setLoadingGlobal);
    }, []);

    const getMetricsInWindow = () => {
      getMetricsDict(
        createdAtFrom ? moment(createdAtFrom).format("YYYY-MM-DD"): '',
        createdAtTo ? moment(createdAtTo).format("YYYY-MM-DD"): '',
        setMetricsDictWindow,
        setLoadingWindow
      );
    }

    const handleDatePickerChange = (value) => {
        setCreatedAtFrom(value[0]);
        setCreatedAtTo(value[1]);
    }

    const handleDriversByOrderCount = () => {
        ExportReport.getDriverOrdersCount()
        .then((response) => {
            if (response.status === 200) {
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                var downloadElement = document.createElement('a');
                downloadElement.href = URL.createObjectURL(file);
                downloadElement.download = "driversByOrderCount.xlsx";
                downloadElement.click();
            } else {
                alert('Произошла ошибка, попробуйте позже');
            }
        }).catch((error) => {
            console.log(error);
            alert("Произошла ошибка, попробуйте позже");
        });
    };

    const handleOrdersByStatus = () => {
        ExportReport.getOrderWithStatus()
        .then((response) => {
            if (response.status === 200) {
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                var downloadElement = document.createElement('a');
                downloadElement.href = URL.createObjectURL(file);
                downloadElement.download = "oderdersWithStatuss";
                downloadElement.click();
            } else {
                alert('Произошла ошибка, попробуйте позже');
            }
        }).catch((error) => {
            console.log(error);
            alert("Произошла ошибка, попробуйте позже")
        });
    };

    const getMetricsDict = (startTime, endTime, setMetrics) => {
        Metric.getMetrics(startTime, endTime)
        .then((response) => {
            if (response.status === 200) {
                setMetrics(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
            alert('Произошла ошибка, попробуйте позже');
        })
        .finally(() => {
            setLoadingGlobal(false);
          });
    }

    const renderList = (items) => {
        if(items) {
            if (items.length > 0) {
                const list = items.map(item => {
                    return (
                        <ListGroup.Item style={{ padding: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{item.description}</div>
                                <div style={{ marginLeft: '12px', flex: '1' }}><Badge bg="secondary">{item.value}</Badge></div>
                            </div>
                        </ListGroup.Item>
                    );
                });

                return list;
            }else {
                return <div>Причин отказа заказа нет</div>
            }
        }else {
            return <Loader />
        }
    }

    const renderMetricsPerTimeWindow = (metricsData, loading) => {
        if (loading) {
            return <Loader />;
          }
        if (metricsData) {
            if (Object.keys(metricsData).length) {
                return (
                    <div>
                        <div style={{ marginTop: '20px', width: '500px', display: 'inline-block'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{textAlign: 'center', fontWeight: '700', fontSize: '24px', marginLeft: '10px'}}>Водители</div>
                            </div>
                            <ListGroup>
                                {renderList(metricsData.drivers)}
                            </ListGroup>
                        </div>
                        <div style={{ marginTop: '20px', marginLeft: '20px', width: '500px', display: 'inline-block'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{textAlign: 'center', fontWeight: '700', fontSize: '24px', marginLeft: '10px'}}>Клиенты</div>
                            </div>
                            <ListGroup>
                                {renderList(metricsData.clients)}
                            </ListGroup>
                        </div>
                        <div style={{marginTop: "20px"}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{textAlign: 'center', fontWeight: '700', fontSize: '24px', marginLeft: '10px'}}>Заказы</div>
                            </div>
                            <div style={{width: '500px', display: 'inline-block'}}>
                                <ListGroup>
                                    {renderList(metricsData.orders.slice(0, metricsData.orders.length / 2))}
                                </ListGroup>
                            </div>
                            <div style={{marginLeft: '20px', width: '500px', display: 'inline-block'}}>
                                <ListGroup>
                                    {renderList(metricsData.orders.slice(metricsData.orders.length / 2))}
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    return (
      <>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '1020px'}}>
              <div style={{textAlign: 'left', fontWeight: '700', fontSize: '30px'}}>Метрики</div>
              <div>
                  <Button variant="primary" onClick={handleDriversByOrderCount} style={{
                      backgroundColor: 'rgb(60, 179, 113)',
                      padding: '0px 15px 6px 15px',
                      border: 'none',
                      borderRadius: '8px',
                      color: '#ffffff',
                      marginRight: '20px',
                      height: '35px'
                  }}>Водители по количеству заказов</Button>
                  <Button variant="primary" onClick={handleOrdersByStatus} style={{
                      backgroundColor: 'rgb(60, 179, 113)',
                      padding: '0px 15px 6px 15px',
                      border: 'none',
                      borderRadius: '8px',
                      color: '#ffffff',
                      height: '35px'
                  }}>Заказы по статусам</Button>
              </div>
          </div>
          
          {renderMetricsPerTimeWindow(metricsDictGlobal, loadingGlobal)}
          <div style={{width: '270px', display: 'flex'}}>
              <div stye={{width: '100%', display:'flex'}}>
                  <Form.Group style={{ marginLeft: '7px', marginTop: '7px'}}>
                      <Form.Label style={{ color: 'rgb(163, 172, 182)', fontSize: '12px', marginBottom: '5px' }}>Дата создания</Form.Label>
                      <DatePicker
                          selectsRange={true}
                          startDate={createdAtFrom}
                          endDate={createdAtTo}
                          onChange={handleDatePickerChange}
                          locale={ru}
                          placeholderText="Укажите Дату"
                          dateFormat="yyyy-MM-dd"
                          />
                  </Form.Group>
              </div>
              <Button variant="primary" onClick={getMetricsInWindow} style={{marginTop: '33px', height: '35px', backgroundColor: '#A3195B', padding: '0px 15px 6px 15px', border: 'none', borderRadius: '8px', color: '#ffffff' }}>Применить</Button>
          </div>
          {renderMetricsPerTimeWindow(metricsDictWindow, loadingWindow)}
      </>
    );

}
export default MetricsPage;
