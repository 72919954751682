import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Loader from "../../../utils/loader";
import Pagination from "react-responsive-pagination";
import DeleteModal from "../../common/DeleteModal";
import React, {useEffect, useState} from "react";
import Insurance from "../../../services/Insurance";
import {toast} from "react-toastify";
import ListItemComponent from "./ListItemComponent";
import moment from "moment/moment";

const SearchComponent = () => {
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    const [deleteId, setDeleteId] = useState();

    const [isDelete, setIsDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    useEffect(() => {
        // getList(orderNumber);
    }, [currentPage]);

    const getList = () => {

        const from = moment(fromDate).format('DD.MM.yyyy');
        const to = moment(toDate).format('DD.MM.yyyy');
        setLoading(true);
        Insurance.contractFromTo(from, to).then(response => {
            console.log('search result', response);
            const obj = response.data;
            if (obj && obj.data && obj.data.data.length > 0) {
                setList(response.data.data.data);
            } else {
                setList([]);
            }
            // setTotalPages(Math.ceil(response.data.total / limit));
        }).catch(error => {
            console.log(error);
            if (error.errorText) {
                const errObj = JSON.parse(error.errorText);
                let errorMessage = errObj.message;
                if (errObj.errors && errObj.errors.to && errObj.errors.to.length > 0) {
                    errorMessage += ` ${errObj.errors.to[0]}`;
                }
                toast.error(`${errorMessage}`);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const onHandleClear = () => {
        setFromDate('');
        setToDate('');
        getList('')
    }

    const onHandleFilter = () => {
        getList();
    }

    const download = (number) => {
        setLoading(true)
        Insurance.downloadContracts(number).then(response2 => {

            const file = new Blob([response2.data], {
                type: response2.data.type,
            });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        }).catch(error => {
            toast('Договор не найден!');
        }).finally(() => {
            setLoading(false);
        });
    }

    const onHandleDeleteClose = () => {
        setDeleteId(undefined);
        setIsDelete(false);
    }

    const onHandleDeleteYes = (id) => {
        setLoading(true);
        Insurance.delete(id).then(response => {
            setLoading(false);
            setDeleteId(undefined);
            setIsDelete(false);
            getList();
        }).catch(error => {
            setLoading(false);
        })
    }

    const renderList = () => {
        if (list.length > 0) {
            return list.map(item => {
                return (<tr>
                    <td style={{width: '170px'}}>
                        {item.date_begin}
                    </td>
                    <td>{item.number}</td>
                    <td>
                        <Button onClick={() => download(item.number)}>
                            <img height="24px" title="Скачать бордеро" src="/images/download-01.svg" alt="download"/>
                            Скачать бордеро
                        </Button>
                    </td>
                </tr>);
            });
        } else {
            return (<tr>
                <td colSpan={2} style={{textAlign: 'center'}}>пустые данные списка</td>
            </tr>)
        }
    }

    return <>
        <div className="insurance__filter">
            <Form.Group style={{marginRight: '20px'}}>
                <Form.Control type="date" value={fromDate} onChange={(event) => setFromDate(event.target.value)}
                              placeholder='c'/>
            </Form.Group>
            <Form.Group style={{marginRight: '20px'}}>
                <Form.Control type="date" value={toDate} onChange={(event) => setToDate(event.target.value)}
                              placeholder='по'/>
            </Form.Group>
            <Button variant="primary" onClick={onHandleFilter} className="btn btn-filter">
                Применить фильтр
            </Button>
            <Button variant="primary" onClick={onHandleClear} className='btn btn-clear-filter'>
                Очистить фильтр
            </Button>
        </div>
        <Table bordered className='insurance__table'>
            <thead style={{textAlign: 'center'}}>
            <tr style={{backgroundColor: '#ededed'}}>
                <th>Дата</th>
                <th>Номер</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {renderList()}
            </tbody>
        </Table>
        {loading && <Loader/>}
        <div className='insurance__pagination'>
            {list && <Pagination current={currentPage} total={totalPages}
                                 onPageChange={page => handlePageChange(page)}/>}
        </div>
        {isDelete && <DeleteModal deleteId={deleteId} onYes={onHandleDeleteYes} onClose={onHandleDeleteClose}/>}
    </>;
}

export default SearchComponent;