import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import {Invoice, User, SystemRoles} from '../../services';
import moment from 'moment';
import Pagination from 'react-responsive-pagination';
import Button from "react-bootstrap/Button";
import Loader from "../../utils/loader";
import './feedbackRequestPage.scss';
import FeedbackRequest from "../../services/FeedbackRequest";
import FeedbackRequestItem from "./Components/FeedbackRequestItem";
import DeleteFeedbackRequestModal from "./Components/DeleteFeedbackRequest";
import CommentModal from "./Components/CommentModal";

const FeedbackRequestPage = ({title}) => {
    const {user} = useSelector(state => state);
    const [list, setList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    const [selectedId, setSelectedId] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [role, setRole] = useState();
    const [isDelete, setIsDelete] = useState(false);
    const [isComment, setIsComment] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    if (response.data) setRole(response.data.code)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    useEffect(() => {
        getList();
    }, [currentPage]);

    const getList = () => {
        setLoading(true);
        FeedbackRequest.list(currentPage, limit).then(response => {
            setList([]);
            setList(response.data.rows);
            setTotalPages(Math.ceil(response.data.total / limit));
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })

    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const renderList = () => {
        if (list && role) {
            return list.map(item => {
                return (<FeedbackRequestItem key={item.id} item={item} role={role}
                                             selectAll={selectAll}
                                             handleItemChange={handleItemChange}
                                             onDelete={handleDelete}
                                             onComment={handleComment}/>);
            });
        }
    }

    const handleDelete = (id) => {
        setSelectedId(id);
        setIsDelete(true);
    }

    const handleComment = (item) => {
        setSelectedItem(item);
        setIsComment(true);
    }

    const onDeleteClose = (isSuccess) => {
        setSelectedId(undefined);
        setIsDelete(false);
        if (isSuccess) {
            getList();
        }
    }

    const onCommentClose = (isSuccess) => {
        setSelectedItem(undefined);
        setIsComment(false);
        if (isSuccess) {
            getList();
        }
    }

    const handleChange = (event: any) => {
        setList(oldValue => oldValue.map((item) => {
            if (item.isSeen === false)
                item.checked = event.target.checked;
            return item;
        }))
        setSelectAll(event.target.checked);
    }

    const handleItemChange = (event: any, selectItem: any) => {

        setList(oldValue => oldValue.map((item) => {
            if (item.id === selectItem.id) {
                if (event.target.checked && item.isSeen === false) {
                    item.checked = event.target.checked;
                    setSelectAll(true);
                } else {
                    item.checked = event.target.checked;
                    setSelectAll(false);
                }
            }
            return item;
        }))


    }
    const handleSeen = () => {
        let ids = list.map(x => x.id);
        setLoading(true);
        FeedbackRequest.seen(ids).then(response => {
            setSelectAll(false);
            getList();
        }).catch(err => {
            console.log('err');
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <h3 className='feedback__title'>Заявки на консультацию</h3>
            <div className='feedback__filter'>
                <Button onClick={handleSeen} disabled={!selectAll}>Прочитать все</Button>
            </div>
            <Table bordered className='feedback__table'>
                <thead style={{textAlign: 'center'}}>
                <tr style={{backgroundColor: '#ededed'}}>
                    <th style={{textAlign: 'left', marginLeft: '15px'}}>
                        <input type="checkbox" checked={selectAll}
                               style={{width: '14px', height: '14px', marginLeft: '4px'}}
                               onChange={(event) => handleChange(event)}/>
                    </th>
                    <th>Дата</th>
                    <th>Телефон</th>
                    <th>Тип</th>
                    <th>Email</th>
                    <th>UserId</th>
                    <th style={{width: '200px'}}>Запрос</th>
                    <th style={{width: '200px'}}>Результат</th>
                    <th>Комментарии</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {renderList()}
                </tbody>
            </Table>
            <div className='feedback__pagination'>
                {
                    list && (!loading &&
                        <Pagination current={currentPage} total={totalPages}
                                    onPageChange={page => handlePageChange(page)}/>)
                }
            </div>
            {loading && <Loader/>}
            {isDelete && <DeleteFeedbackRequestModal id={selectedId} onClose={onDeleteClose}/>}
            {isComment && <CommentModal item={selectedItem} onClose={onCommentClose} user={user}/>}
        </>
    );
}

export default FeedbackRequestPage;
