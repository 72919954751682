import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {User} from "../../../services";
import Form from "react-bootstrap/Form";
import Loader from "../../../utils/loader";

const DeleteModal = ({user, deleteId, onClose}) => {
    const [showDeleteReason, setShowDeleteReason] = useState(false);
    const [errorTitle, setErrorTitle] = useState();
    const [deleteReason, setDeleteReason] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        setShowDeleteReason(false);
        onClose();
    }

    const handleYes = () => {
        if (deleteReason !== '') {
            setLoading(true);
            User.deleteUser(deleteId, 2, user.name, deleteReason).then((response) => {
                setLoading(false);
                setShowDeleteReason(undefined);
                onClose(true);

            }).catch((error) => {
                console.log(error);
                setLoading(false);
                alert("Произошла ошибка, попробуйте позже");
                setShowDeleteReason(undefined);
                onClose(false);
            });
        } else {
            setErrorTitle('Необходимо заполнить причину удаления');
        }
    }

    return (
        <div className="modal-overlay">
            <div style={{
                backgroundColor: "#ffffff",
                height: "auto",
                minHeight: '120px',
                width: "450px",
                margin: "200px auto",
                borderRadius: "6px",
            }}>
                <div style={{
                    padding: "14px 24px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #eeeeee",
                    borderTopLeftRadius: "6px",
                    borderTopRightRadius: "6px",
                }}>
                    <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                        <span style={{fontWeight: "600"}}>Вы действительно хотите удалить? </span>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={onClose}>X</div>
                </div>
                {!showDeleteReason && (
                    <div style={{
                        marginTop: '10px',
                        marginBottom: '20px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button onClick={onClose} variant="light" style={{marginRight: '20px'}}>Нет</Button>
                        <Button onClick={() => setShowDeleteReason(true)} style={{marginRight: '20px'}}
                                variant="primary">Да</Button>
                    </div>
                )}
                {showDeleteReason ? (
                    <div style={{margin: "10px 10px", paddingBottom: "4px"}}>
                        <Form.Group>
                            <Form.Label style={{color: "#A3ACB6", fontSize: "12px"}}>
                                Причина удаление
                            </Form.Label>
                            <Form.Control
                                onChange={(event) => setDeleteReason(event.target.value)}
                                as="textarea"
                                placeholder="Введите причину удаления"
                                rows={2}
                                style={{fontSize: "14px"}}
                            />
                        </Form.Group>
                        {errorTitle ? (
                            <div style={{
                                marginTop: "6px",
                                color: "#dc3545",
                                fontSize: "12px",
                            }}>
                                {errorTitle}
                            </div>
                        ) : null}
                        <div style={{
                            display: "flex",
                            gap: '5px',
                            justifyContent: 'right',
                            marginTop: "12px",
                            marginBottom: "12px",
                        }}>
                            <Button onClick={handleYes}
                                    variant="primary"
                                    size="sm"
                                    style={{marginLeft: "5px"}}>
                                Подтвердить
                            </Button>
                            <Button
                                className="btn btn-cancel"
                                onClick={handleCancel}
                                variant="secondary"
                                size="sm">
                                Отменить
                            </Button>

                        </div>
                    </div>
                ) : null}
                {loading && <Loader/>}
            </div>
        </div>
    );
};
export default DeleteModal;
