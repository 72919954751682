import { createStore, combineReducers } from "redux";
import { userReducer } from './reducers/userReducer';
import { roleReducer } from "./reducers/roleReducer";

const initialState = {};

const reducers = combineReducers({
    user: userReducer,
    role: roleReducer
});

export default createStore(
    (state, action) => reducers(state, action),
    initialState
    // +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
