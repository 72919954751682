import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Order, User} from "../../services";

const DeleteModal = ({activeTab, client, onClose, user}) => {

    const [deleteReason, setDeleteReason] = useState();
    const [showDeleteReason, setShowDeleteReason] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleDeleteYes = () => {

        if (deleteReason && deleteReason !== '') {
            Order.getClientOrdersQuantity(client.id)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.quantity === 0) {
                            User.deleteUser(client.id, activeTab == 'client' ? 0 : 1, user.name, deleteReason)
                                .then((response) => {
                                    onClose(true);
                                })
                                .catch((error) => {
                                    alert("Произошла ошибка, попробуйте позже")
                                    onClose();
                                });
                        } else {
                            alert(`Невозможно удалить пользователя ${client.name} у которого есть существующие заказы.`)
                            onClose();
                        }
                    } else {
                        alert("Произошла ошибка, попробуйте позже");
                        onClose();
                    }

                })
                .catch((error) => {
                    alert("Произошла ошибка, попробуйте позже");
                    onClose();
                });
        } else {
            setErrorMessage('Необходимо заполнить причину удаления');
        }
    }

    return (
        <div className="modal-overlay">
            <div
                style={{
                    backgroundColor: "#ffffff",
                    height: "auto",
                    minHeight: "120px",
                    width: "450px",
                    margin: "200px auto",
                    borderRadius: "6px",
                }}>
                <div
                    style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                    <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                        <span style={{fontWeight: "600"}}>Вы действительно хотите удалить? </span>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={onClose}>X</div>
                </div>
                {!showDeleteReason && (
                    <div style={{marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button onClick={onClose} variant="light" style={{marginRight: '20px'}}>Нет</Button>
                        <Button onClick={() => {
                            setShowDeleteReason(true);
                        }} style={{marginRight: '20px'}} variant="primary">Да</Button>
                    </div>)}
                {showDeleteReason ? (
                    <div style={{margin: "10px 10px", paddingBottom: "4px"}}>
                        <Form.Group>
                            <Form.Label style={{color: "#A3ACB6", fontSize: "12px"}}>
                                Причина удаление
                            </Form.Label>
                            <Form.Control
                                onChange={(event) => setDeleteReason(event.target.value)}
                                as="textarea"
                                placeholder="Введите причину удаления"
                                rows={2}
                                style={{fontSize: "14px"}}
                            />
                        </Form.Group>
                        {errorMessage ? (
                            <div style={{
                                marginTop: "6px",
                                color: "#dc3545",
                                fontSize: "12px",
                            }}>
                                {errorMessage}
                            </div>
                        ) : null}
                        <div style={{textAlign: "right", marginTop: "12px", marginBottom: "12px"}}>
                            <Button onClick={() => {
                                setShowDeleteReason(false);
                            }} variant="secondary" size="sm">
                                Отменить
                            </Button>
                            <Button onClick={handleDeleteYes}
                                    variant="primary"
                                    size="sm"
                                    style={{marginLeft: "12px"}}>
                                Подтвердить
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default DeleteModal;
