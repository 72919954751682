import React, {useEffect, useState} from "react";
import { Button } from "react-bootstrap";
import Tender from "../../../services/Tender";
import { Order, User } from "../../../services";
import moment from "moment"
import DriverInfoModal from "../../drivers/DriverInfoModal";
import Loader from "../../../utils/loader";

const TenderDetails = ({ order, user }) => {
    const [showOrderState, setShowOrderState] = useState(false);
    const [tenders, setTender] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [orderDate, setOrderDate] = useState();
    const [selectedDriver, setSelectedDriver] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchTenders();
        loadOrder();
    }, []);

    const fetchTenders = () => {
        Tender.getTenders(order.id, currentPage, limit)
            .then((response) => {
                if (response.status == 200) {
                    setTender(response.data.rows);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const assignBid = async (tenderId, userName) => {
        try {
            const response = await Tender.acceptedBid(tenderId, { moderator: userName });
            console.log('Водитель успешно назначен:', response);
            fetchTenders();
            loadOrder();
        } catch (error) {
            console.error('Ошибка при назначения водителя:', error);
        }
    };

    const loadOrder = () => {
        Order.getById(order.id)
            .then(response => {
                if (response.status == 200) {
                    const responseData = response.data;
                    setOrderDate(responseData);
                    console.log("orderDate", orderDate)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onSelectDriver = (item) => {
        setLoading(true);
        User.getDriverById(item.driverId)
            .then((response) => {
                setSelectedDriver(response.data.user);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    const handleClose = () => {
        setSelectedDriver();
    }

    return (
        <>
            <div className="order-info">
                <span>Водители участвующие на аукционе</span>
                <div className="row" style={{ width: '100%' }}>
                    <div className="col-10" style={{ paddingBottom: "12px" }}>
                        {!showOrderState ? (
                            <span style={{
                                cursor: "pointer",
                                color: "blue",
                                fontSize: "12px",
                                textDecorationLine: "underline",
                            }} onClick={() => setShowOrderState(true)}>
                                Раскрыть список
                            </span>
                        ) : (
                            <>
                                <span style={{
                                    cursor: "pointer",
                                    color: "blue",
                                    fontSize: "12px",
                                    textDecorationLine: "underline",
                                }} onClick={() => setShowOrderState(false)}>
                                    Скрыть список
                                </span>
                                <div style={{
                                    fontSize: "14px",
                                    marginTop: "5px",
                                    display: "flex",
                                    fontWeight: "600",
                                }}>
                                    <div style={{ width: "250px", lineHeight: "15px" }}>
                                        Водитель
                                    </div>
                                    <div style={{ width: "200px" }}>
                                        Цена
                                    </div>
                                    <div style={{ width: "250px" }}>
                                        Время
                                    </div>
                                    <div style={{ width: "200px", textAlign: "center" }}></div>
                                </div>
                            </>
                        )}

                        {showOrderState ? (
                            tenders && tenders.length ? (
                                tenders.map((item) => {
                                    return (
                                        <div style={{
                                            fontSize: "14px",
                                            marginTop: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            ...(item.isAccepted ? { border: "1px solid", borderRadius: "5px" } : {})
                                        }}>
                                            <div style={{ width: "250px", lineHeight: "15px" }}
                                                onClick={() => onSelectDriver(item)}
                                            >
                                                {item.driverFIO}:
                                            </div>
                                            <div style={{ width: "200px" }} onClick={() => onSelectDriver(item)}>
                                                {item.price} &#8376;
                                            </div>
                                            <div style={{ width: "250px" }} onClick={() => onSelectDriver(item)}>
                                                {moment.utc(item.createdAt).format("YYYY-MM-DD HH:MM:SS")}
                                            </div>
                                            <div style={{ width: "200px", textAlign: "center" }}>
                                                {item.isAccepted ? (
                                                    <span style={{ color: "#3CB371" }} onClick={() => onSelectDriver(item)}
                                                    >Победитель</span>
                                                ) : (
                                                    orderDate.isActiveAuction ? (
                                                        <Button
                                                            onClick={() => assignBid(item.id, user.name)}
                                                            variant="success"
                                                            size="sm"
                                                        >
                                                            Назначить
                                                        </Button>
                                                    ) : null
                                                )}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div style={{ fontSize: "12px", color: "red" }}>
                                    Нет данных
                                </div>
                            )
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            </div>

            {selectedDriver && (<>
                {loading ? (<Loader/>) : (
                    <DriverInfoModal selectedDriver={selectedDriver} onClose={handleClose} />
                )}
            </>)}
        </>
    );
};

export default TenderDetails;
