import React, {useEffect, useState} from "react";
import News from "../../../services/News";
import moment from "moment/moment";
import Button from "react-bootstrap/Button";

const FeedbackRequestItem = ({item, role, selectAll, handleItemChange, onDelete, onComment}) => {

    const [isLoading, setLoading] = useState(false);

    return (
        <tr>
            <td className={item.isSeen === false && 'unread'}>
                <input type="checkbox" checked={item.checked} onChange={(event) => handleItemChange(event, item)}/>
            </td>
            <td className={item.isSeen === false && 'unread'} style={{width: '170px'}}>
                {moment(item.createdAt).format("DD.MM.YYYY")}<br/>
            </td>
            <td className={item.isSeen === false && 'unread'}>
                {item.phoneNumber}
            </td>
            <td className={item.isSeen === false && 'unread'}>
                {item.actionType}
            </td>
            <td className={item.isSeen === false && 'unread'}>
                {item.email}
            </td>
            <td className={item.isSeen === false && 'unread'}>
                {item.binyUserId}
            </td>
            <td className={item.isSeen === false && 'unread'} style={{width: '200px !important'}}>
                <div>{item.cityFrom} -> {item.cityTo}</div>
                <div>Объем: {item.volume}</div>
                <div>Вес: {item.weight}</div>
            </td>
            <td className={item.isSeen === false && 'unread'} style={{width: '180px !important'}}>
                {item.result}
            </td>
            <td className={item.isSeen === false && 'unread'} style={{width: '180px !important'}}>
                {item.comment && (<div><p><i><b>Модератор:</b> {item.moderator}</i></p>
                    <p><i><b>Комментарии:</b></i> {item.comment}</p></div>)}
            </td>
            <td className={item.isSeen === false && 'unread'} style={{width: '100px'}}>

                <div className='action'>
                    <img height="22px" src="/icons/comment-icon.png" title="Оставить комментарий"
                         alt="Оставить комментарий" onClick={(e) => {
                        e.preventDefault();
                        onComment(item);
                    }}/>
                    {role === "superAdmin" && <img height="22px" src="/icons/delete.png" onClick={(e) => {
                        e.preventDefault();
                        onDelete(item.id);
                    }}/>}
                </div>
            </td>
        </tr>
    )
}
export default FeedbackRequestItem;
