import React, {useEffect, useState} from "react";
import {Directory} from "../../services";
import ContactsModal from "./ContactsModal";
import Loader from "../../utils/loader";

const ContactsPage = () => {
    const [contacts, setContacts] = useState();
    const [whatsApp, setWhatsApp] = useState();
    const [telegram, setTelegram] = useState();
    const [phone, setPhone] = useState();
    const [editItem, setEditItem] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getContacts();
    }, []);

    // const getContacts = () => {
    //     Directory.getContacts().then(response => {

    //         if (response.status == 200) {
    //             setContacts(response.data);
    //             const item0 = response.data.find(x => x.contactType == 0);
    //             if (item0) setWhatsApp(item0);
    //             const item1 = response.data.find(x => x.contactType == 1);
    //             if (item1) setTelegram(item1);
    //             const item2 = response.data.find(x => x.contactType == 2);
    //             if (item2) setPhone(item2);

    //         }
    //     }).catch(error => {
    //         console.log(error);
    //     })
    // }

    const getContacts = () => {
        setLoading(true); // Set loading to true when fetching contacts
        Directory.getContacts()
          .then((response) => {
            if (response.status === 200) {
              setContacts(response.data);
              const item0 = response.data.find((x) => x.contactType === 0);
              if (item0) setWhatsApp(item0);
              const item1 = response.data.find((x) => x.contactType === 1);
              if (item1) setTelegram(item1);
              const item2 = response.data.find((x) => x.contactType === 2);
              if (item2) setPhone(item2);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false); // Set loading to false after fetching contacts
          });
      };

    return <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>Контакты</div>
            </div>
            
            {loading && <Loader />}
            <>
                <div style={{marginTop: '20px', background: '#fff', padding: '16px'}}>
                    <div className='row' style={{marginTop: '16px'}}>
                        <div className='col-4' style={{paddingBottom: '12px'}}>
                            <div style={{color: '#050505', fontSize: '14px', fontWeight: "700"}}>Ватсап номер</div>
                            <div style={{fontSize: '14px', marginTop: '2px'}}>{whatsApp?.phoneNumber}</div>
                        </div>
                        <div className='col-1' style={{cursor: 'pointer'}}>
                            <img style={{cursor: 'pointer'}} src='/icons/edit.svg' onClick={() => {
                                setEditItem(whatsApp)
                            }}/>
                        </div>
                    </div>
                    <div className='row' style={{marginTop: '16px'}}>
                        <div className='col-4' style={{paddingBottom: '12px'}}>
                            <div style={{color: '#050505', fontSize: '14px', fontWeight: "700"}}>Телеграм</div>
                            <div style={{fontSize: '14px', marginTop: '2px'}}>{telegram?.phoneNumber}</div>
                        </div>
                        <div className='col-1' style={{cursor: 'pointer'}}>
                            <img style={{cursor: 'pointer'}} src='/icons/edit.svg' onClick={() => {
                                setEditItem(telegram)
                            }}/>
                        </div>
                    </div>
                    <div className='row' style={{marginTop: '16px'}}>
                        <div className='col-4' style={{paddingBottom: '12px'}}>
                            <div style={{color: '#050505', fontSize: '14px', fontWeight: "700"}}>Номер для звонка</div>
                            <div style={{fontSize: '14px', marginTop: '2px'}}>{phone?.phoneNumber}</div>
                        </div>
                        <div className='col-1' style={{cursor: 'pointer'}}>
                            <img style={{cursor: 'pointer'}} src='/icons/edit.svg' onClick={() => {
                                setEditItem(phone)
                            }}/>
                        </div>
                    </div>
                </div>
            </>

            {editItem && <ContactsModal item={editItem} onClose={() => {
                setEditItem(null);
                getContacts();
            }
            }/>}
        
    </>;
}
export default ContactsPage;
