import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {Document, Page, pdfjs} from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Table from "react-bootstrap/Table";
import {numberToFinanceFormat} from "../../../utils";
import BalanceModal from "./BalanceModal";
import Alert from "react-bootstrap/Alert";

const Balance = ({item, balanceList, onSuccess}) => {
    const [isShowBalanceHistory, setIsShowBalanceHistory] = useState(false);
    const [isBalanceModal, setIsBalanceModal] = useState(false);
    const [isBalanceSuccess, setIsBalanceSuccess] = useState(false);
    const renderBalanceList = () => {
        if (balanceList && balanceList.length > 0) {
            return balanceList.map(item => {
                return (
                    <tr>
                        <td>
                            <div style={{padding: '5px 0'}}>{item.createdAtStr}</div>
                        </td>
                        <td>
                            <div
                                style={{padding: '5px 0'}}>{numberToFinanceFormat((item.balanceAfter - item.balanceBefore))} тг.
                            </div>
                        </td>
                    </tr>
                );
            });
        } else {
            return (<tr>
                <td colSpan='2' style={{color: '#bdb8b8', textAlign: 'center'}}>Empty data</td>
            </tr>);
        }
    }

    const handleClose = (isSuccess) => {
        setIsBalanceModal(false);
        if (isSuccess) {
            setIsBalanceSuccess(true);
            onSuccess();
            setTimeout(() => {
                setIsBalanceSuccess(false);
            }, 2000);
        }
    }

    return (<>
            <div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                    paddingBottom: "10px",
                    marginTop: "14px",
                }}>
                    <div style={{
                        fontSize: "15px",
                        color: "#A3ACB6",
                        lineHeight: "1.3",
                        fontWeight: "600",
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '98%'
                    }} onClick={() => {
                        setIsShowBalanceHistory(cur => !cur);
                    }}>
                        <span> История пополнения</span>
                        {!isShowBalanceHistory && <img src="/icons/arrow-up.svg" alt=""/>}
                        {isShowBalanceHistory && <img src="/icons/arrow-down.svg" alt=""/>}
                    </div>
                </div>
                {isShowBalanceHistory && (<div className="row" style={{marginTop: "10px"}}>
                    <div className="row" style={{marginTop: "16px"}}>
                        <div className="col-4" style={{paddingBottom: "12px"}}>
                            <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                Доступно
                            </div>
                            <div style={{fontSize: "14px", marginTop: "2px"}}>
                                {item.balance ? numberToFinanceFormat(item.balance) : 0} тг.
                            </div>
                        </div>
                        <div className="col-4" style={{paddingBottom: "12px"}}>
                            <div style={{color: "#A3ACB6", fontSize: "12px"}}>На удержании</div>
                            <div style={{fontSize: "14px", marginTop: "2px"}}>
                                {item.moneyOnHold ? numberToFinanceFormat(item.moneyOnHold) : 0} тг.
                            </div>
                        </div>
                        <div className="col-4" style={{paddingBottom: "12px"}}>
                            <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                Оплачено всего
                            </div>
                            <div style={{fontSize: "14px", marginTop: "2px"}}>
                                {item.totalPaid ? numberToFinanceFormat(item.totalPaid) : 0} тг.
                            </div>
                        </div>
                    </div>
                    <Table bordered style={{
                        fontSize: '14px',
                        textAlign: 'left',
                        backgroundColor: '#ffffff'
                    }}>
                        <thead style={{textAlign: 'center'}}>
                        <tr style={{backgroundColor: '#ededed'}}>
                            <th>Дата</th>
                            <th>Пополнение</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderBalanceList()}
                        </tbody>
                    </Table>

                </div>)}
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'left', marginTop: '10px'}}>
                <Button variant="primary" onClick={() => setIsBalanceModal(true)}
                        style={{
                            backgroundColor: '#A3195B',
                            padding: '10px 40px',
                            border: 'none',
                            borderRadius: '8px',
                            color: '#ffffff'
                        }}>
                    Пополнить баланс
                </Button>
            </div>
            {isBalanceModal && <BalanceModal item={item} onClose={(event) => handleClose(event)}/>}
            {isBalanceSuccess && <Alert style={{marginTop: '20px'}}>Баланс успешно пополнен!</Alert>}
        </>
    )
        ;
};

export default Balance;
