import React, {useEffect, useState} from "react";
import {numberToFinanceFormat} from "../../../utils";
import moment from "moment/moment";
import { User } from "../../../services";
import Loader from "../../../utils/loader";
import DriverInfoModal from "../../drivers/DriverInfoModal";

const OrderViews = ({orderView, order}) => {
    const [loading, setLoading] = useState(true);
    const [selectedDriver, setSelectedDriver] = useState();

    const onSelectDriver = (item) => {
        setLoading(true);
        User.getDriverById(item.driverId)
            .then((response) => {
                setSelectedDriver(response.data.user);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    const handleClose = () => {
        setSelectedDriver();
    }

    return (
        <>
            <div style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                paddingBottom: "10px",
                marginTop: "24px",
                width: '100%'
            }}>
                <div style={{
                    fontSize: "15px",
                    lineHeight: "1.3",
                    fontWeight: "600",
                }}>
                    Количество просмотров
                </div>
            </div>
            {orderView && (
                orderView.map((item) => (
                    <div className="row" style={{marginTop: "0px", width: '100%'}} onClick={() => onSelectDriver(item)}>
                        <div className="col-4">
                            <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                {item.driverFIO}
                            </div>
                        </div>
                        <div className="col-4">
                            <div style={{fontSize: "14px"}}>
                                {moment(item.updatedAt).format("DD.MM.YYYY HH:mm")}
                            </div>
                        </div>
                        <div className="col-4">
                            <div style={{fontSize: "14px"}}>
                                {item.quantity}
                            </div>
                        </div>
                    </div>
                ))
            )}

            {selectedDriver && (<>
                {loading ? (<Loader/>) : (
                    <DriverInfoModal selectedDriver={selectedDriver} onClose={handleClose} />
                )}
            </>)}
        </>
    );
};

export default OrderViews;
