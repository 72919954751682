import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Directory } from '../../services';
import Button from 'react-bootstrap/Button';
import OrderCancelMessagesCreateModal from './OrderCancelMessagesCreateModal';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../utils/loader';

const OrderCancelMessagesPage = () => {

    const [orderCancelMessages, setOrderCancelMessages] = useState();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        getOrderCancelMessages()
    }, []);

    const getOrderCancelMessages = () => {
        Directory.getOrderCancelMessages().then(response => {
            console.log("ORDER CANCEL MESSAGES", response)
            if(response.status == 200) {
                if(response.data.length > 0) setOrderCancelMessages(response.data)
                else setOrderCancelMessages([]);
            }else {
                setOrderCancelMessages([])
            }
          })
          .catch(error => {
            console.log(error)
          })
    }

    const handleDeleteYes = () => {
        Directory.deleteOrderCancelMessage(deleteId).then((response) => {
            if (response.status == 200) {
                setDeleteId(undefined);
                getOrderCancelMessages();
            } else {
                setDeleteId(undefined);
                alert("Произошла ошибка, попробуйте позже")
            }
        }).catch((error) => {
            console.log(error);
            setDeleteId(undefined);
            alert("Произошла ошибка, попробуйте позже");
        });
    }

    const renderMadalDelete = () => {
        return (
            <div className="modal-overlay">
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        height: "120px",
                        width: "450px",
                        margin: "200px auto",
                        borderRadius: "6px",
                    }}
                >
                    <div
                        style={{
                            padding: "14px 24px",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#ffffff",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eeeeee",
                            borderTopLeftRadius: "6px",
                            borderTopRightRadius: "6px",
                        }}
                    >
                        <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                            <span style={{fontWeight: "600"}}>Вы действительно хотите удалить? </span>
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={() => {
                            setDeleteId(undefined)
                        }}>X
                        </div>
                    </div>
                    <div style={{marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button onClick={() => {
                            setDeleteId(undefined)
                        }} variant="light" style={{marginRight: '20px'}}>Нет</Button>
                        <Button onClick={() => {
                            handleDeleteYes()
                        }} style={{marginRight: '20px'}} variant="primary">Да</Button>
                    </div>
                </div>
            </div>
        );
    };

    const renderList = () => {
        if(orderCancelMessages) {
            if(orderCancelMessages.length > 0) {
                const list = orderCancelMessages.map(item => {
                    return (
                        <ListGroup.Item style={{ padding: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{item.message}</div>
                                <div style={{ marginLeft: '12px', flex: '1' }}><Badge bg="secondary">{item.priority}</Badge></div>
                                <div>
                                <Button size="sm" variant="outline-danger"
                                        style={{fontSize: '11px'}}
                                        onClick={() => {
                                            setDeleteId(item.id);
                                        }}>Удалить</Button>
                                <Button size="sm" variant="outline-secondary"
                                        style={{fontSize: '11px', marginLeft: '12px'}}
                                        onClick={() => {
                                            setEditItem(item);
                                        }}>Редактировать</Button>
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                });

                return list;
            }else {
                return <div>Причин отказа заказа нет</div>
            }
        }else {
            // return <div>Загрузка..</div>
            return <Loader />
        }
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'left', fontWeight: '700', fontSize: '24px' }}>Причины отказа заказа</div>
                <div>
                    <Button onClick={() => setShowCreateModal(true)} size="sm">Добавить причину отказа заказа</Button>
                </div>
            </div>
            <div style={{ marginTop: '20px' }}>
                <ListGroup>
                    {renderList()}
                </ListGroup>
            </div>

            {
                (showCreateModal || editItem) &&
                <OrderCancelMessagesCreateModal item={editItem} onClose={() => {
                  setShowCreateModal(false);
                  setEditItem(null);
                  getOrderCancelMessages();
                }} update={getOrderCancelMessages} />
            }
            {
              deleteId && renderMadalDelete()
            }
        </>
    );
}

export default OrderCancelMessagesPage;
