import React, {useState} from "react";
import moment from "moment";

const FreedomPayItem = ({item, role, onDelete}) => {

    const [isLoading, setLoading] = useState(false);
    return (
        <tr>
            <td>
                {item.number}
            </td>
            <td style={{width: '170px'}}>
                {moment(item.createdAt).format("DD.MM.YYYY HH:mm")}
            </td>
            <td>
                {item.pgCardHash}
            </td>
            <td>
                {item.pgPaymentAmount && (item.pgPaymentAmount+' т.')}
            </td>
            <td style={{width: '200px !important'}}>
                {item.response}
            </td>
            <td style={{width: '100px'}}>
                {item.pgStatus}
            </td>
        </tr>
    )
}
export default FreedomPayItem;