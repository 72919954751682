import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {acceptstatus, rejectstatus, ORDER_STATUSES, ORDER_STATUSES_KEY} from "../Statuses";
import {Directory, Order} from "../../../services";
import CheckCities from "./CheckCities";
import Loader from "../../../utils/loader";
import moment from "moment/moment";
import {toast} from "react-toastify";

const StatusInfo = ({user, order, getOrders, onCheckCities}) => {
    const [actionSuccess, setActionSuccess] = useState(false);
    const [bookingFailed, setBookingFailed] = useState(false);
    const [isCheckCities, setIsCheckCities] = useState(false);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);

    const updateStatus = () => {

        if (order.isActualCities) {

            if (order.status === ORDER_STATUSES_KEY.booked) {
                console.log('order', order);
                let currentDate = new Date();
                let dt = new Date(order.shippingDate);
                let shippingDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 0);
                if (currentDate.getTime() > shippingDate.getTime()) {
                    toast.error('Дата погрузки просрочена. Необходимо изменить дату погрузки!!!');
                    return;
                }
            }

            setLoading(true);
            const status = acceptstatus(order.status);
            Order.updateStatus(order.id, status, user.name)
                .then((response) => {
                    setLoading(false)
                    setActionSuccess(true);
                    getOrders();
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error);
                });
        } else {
            getCities();
        }
    }

    const rejectConfirm = () => {
        const status = rejectstatus(order.status);
        Order.rejectConfirm(order.id, status, user.name, "")
            .then((response) => {
                setBookingFailed(true);
                getOrders();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const openOrderAttachment = () => {
        Order.getOrderPhoto(order.id).then((response) => {

            const file = new Blob([response.data], {
                type: response.data.type,
            });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch((error) => {
            console.log(error);
        });
    };

    const onCloseCheckCities = (isSucceed) => {
        setIsCheckCities(false);
        if (isSucceed) {
            order.isActualCities = true;
            updateStatus();
        }
    }

    const renderStatus = () => {
        if (['moderation', 'loadingcargomoderation', 'unloadingcargomoderation'].includes(order.status)) {
            return (
                <div className="status"
                     style={{backgroundColor: `${actionSuccess ? "rgba(19, 182, 94, 0.1)" : "#f7f7f7"}`}}>
                    <div style={{flex: "1"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Статус заказа
                        </div>
                        <div style={{fontSize: "15px", color: "#f0ad4e", fontWeight: "700"}}>
                            {ORDER_STATUSES[order.status]}
                        </div>
                        {!order.isActualCities && <code>Добавлен новый город</code>}
                    </div>
                    {actionSuccess ? (
                        <div>
                            <img src="/icons/tick-success.svg" height="16px"/>
                        </div>
                    ) : (
                        <>
                            <div style={{marginLeft: "12px"}}>
                                <Button onClick={rejectConfirm} variant="danger" size="sm">
                                    Отклонить
                                </Button>
                            </div>
                            <div style={{marginLeft: "12px"}}>
                                <Button onClick={updateStatus} variant="primary" size="sm">
                                    Подтвердить
                                </Button>
                            </div>
                        </>
                    )}
                    {}
                </div>
            );
        } else if (order.status === "booked") {
            return (
                <div className="status"
                     style={{backgroundColor: `${actionSuccess ? "rgba(19, 182, 94, 0.1)" : "#f7f7f7"}`}}>
                    <div style={{flex: "1"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Статус заказа
                        </div>
                        <div
                            style={{fontSize: "15px", color: "#f0ad4e", fontWeight: "700"}}
                        >
                            {ORDER_STATUSES[order.status]}
                        </div>
                    </div>
                    {actionSuccess || bookingFailed ? (
                        actionSuccess ? (
                            <div>
                                <img src="/icons/tick-success.svg" height="16px"/>
                            </div>
                        ) : (
                            <div style={{color: "red", fontWeight: "800"}}>Отклонен</div>
                        )
                    ) : (
                        <>
                            <div style={{marginLeft: "12px"}}>
                                <Button onClick={rejectConfirm} variant="danger" size="sm">
                                    Отклонить
                                </Button>
                            </div>
                            <div style={{marginLeft: "12px"}}>
                                <Button onClick={updateStatus} variant="primary" size="sm">
                                    Подтвердить
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            );
        } else if (order.status === "waitingforcompletion" || order.status === 'completed') {
            return (
                <div className="status"
                     style={{backgroundColor: `${actionSuccess ? "rgba(19, 182, 94, 0.1)" : "#f7f7f7"}`}}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{flex: 1}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Статус заказа
                                </div>
                                <div
                                    style={{fontSize: "15px", color: "#f0ad4e", fontWeight: "700"}}
                                >
                                    {ORDER_STATUSES[order.status]}
                                </div>
                                <div onClick={openOrderAttachment} style={{marginTop: "12px"}}>
                                    <Button variant="outline-primary" size="sm">
                                        Открыть фото
                                    </Button>
                                </div>
                            </div>
                            {order.status === 'waitingforcompletion' && (<div style={{flex: 1}}>
                                {actionSuccess ? (
                                    <div style={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <img src="/icons/tick-success.svg" height="16px"/>
                                    </div>
                                ) : (
                                    <div style={{
                                        marginLeft: "12px",
                                        marginTop: '10px',
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button onClick={updateStatus} variant="success" size="sm">
                                            Завершить заказ
                                        </Button>
                                    </div>
                                )}
                            </div>)}
                        </div>

                    </div>
                </div>
            );
        } else {
            return (
                <div className="status"
                     style={{backgroundColor: `${actionSuccess ? "rgba(19, 182, 94, 0.1)" : "#f7f7f7"}`}}>
                    <div style={{flex: "1"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Статус заказа
                        </div>
                        <div style={{fontSize: "15px", color: "#f0ad4e", fontWeight: "700"}}>
                            {ORDER_STATUSES[order.status]}
                        </div>
                    </div>
                </div>
            );
        }
    };

    const getCities = () => {
        Directory.getCitiesByIds([order.cityIdFrom, order.cityIdTo]).then(response => {
            console.log('response', response);
            setCities(response.data);
            setIsCheckCities(true);
        }).catch((error) => {
            console.log('error', error);
        });
    }

    return (
        <>
            <div className="status-content">
                {renderStatus()}
            </div>
            {isCheckCities === true && cities.length > 0 &&
                <CheckCities newCitiesCount={2} cities={cities} order={order} onClose={onCloseCheckCities}/>}
            {loading === true && <Loader/>}
        </>
    );
};

export default StatusInfo;
