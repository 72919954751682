import React, {useContext, useState} from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {AuthContext} from '../../context/auth.context';
import {Link} from 'react-router-dom';

const LoginPage = () => {

    const auth = useContext(AuthContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [errorTitle, setErrorTitle] = useState();

    const [isLoading, setIsLoading] = useState(false);

    // const validateEmail = (email) => {
    //     return String(email)
    //       .toLowerCase()
    //       .match(
    //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //       );
    //   };

    const handleLogin = () => {
        if (username && password) {
            setErrorTitle();
            setIsLoading(true);
            console.log(username, password);
            axios.get('https://admin-service.biny.co/api/v1/auth/login', {
                auth: {username, password},
                withCredentials: true
            })
                .then((response) => {
                    console.log('login responce', response);
                    if (response.status == 200) {
                        response.data.isAuthenticated = true;
                        auth.login(response.data.accessToken);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    // setLoading(false);
                    // setErrorTitle('Введен неверный логин или пароль');
                    console.log('Введен неверный логин или пароль');
                    setErrorTitle('Введен неверный логин или пароль');
                    setIsLoading(false);
                });

        } else {
            setErrorTitle('Заполните все поля')
        }

    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleLogin()
        }
    };

    return (
        <div style={{
            backgroundColor: '#343a40',
            height: '100vh',
            width: '100vw',
            fontFamily: 'Gerbera-Regular',
            position: 'relative'
        }}>
            <div style={{position: 'absolute', top: '24px', left: '16px'}}>
                <img src="/icons/logo.svg" height="40px"/>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh'
            }}>
                <div style={{
                    backgroundColor: '#ffffff',
                    boxShadow: '0 2px 6px -2px rgb(228, 228, 228)',
                    width: '360px',
                    borderRadius: '24px',
                    padding: '38px 44px',
                    textAlign: 'center'
                }}>
                    <div style={{fontSize: '24px', fontFamily: 'Gerbera-Bold'}}>Вход в систему</div>
                    <div style={{fontSize: '14px', marginTop: '12px'}}>Введите логин и пароль для входа в систему</div>
                    <div style={{marginTop: '24px'}}>
                        <Form.Control value={username} onChange={(e) => setUsername(e.target.value)} size="lg"
                                      type="text" placeholder="Email" style={{fontSize: '16px', padding: '12px 16px'}}/>
                        <Form.Control onKeyDown={(e) => handleKeyPress(e)} value={password}
                                      onChange={(e) => setPassword(e.target.value)} size="lg" type="password"
                                      placeholder="Пароль"
                                      style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                    </div>
                    {/* <div style={{ textAlign: 'left', fontSize: '13px', marginTop: '10px', color: '#A3ACB6' }}>Забыли пароль?</div> */}

                    {errorTitle && <div style={{marginTop: '12px', fontSize: '13px', color: 'red'}}>{errorTitle}</div>}

                    {
                        isLoading
                            ?
                            <div style={{
                                width: '100%',
                                backgroundColor: '#A3195B',
                                borderRadius: '8px',
                                padding: '19px 14px 18px',
                                border: 'none',
                                color: '#ffffff',
                                fontWeight: '500',
                                marginTop: '20px'
                            }}>
                                <div className="loader white" style={{margin: '0 auto'}}></div>
                            </div>
                            :
                            <div>
                                <button onClick={handleLogin} style={{
                                    width: '100%',
                                    backgroundColor: '#A3195B',
                                    borderRadius: '8px',
                                    padding: '14px',
                                    border: 'none',
                                    color: '#ffffff',
                                    fontWeight: '500',
                                    marginTop: '20px'
                                }}>Войти
                                </button>
                            </div>
                    }
                    {/* <Link to="/registration" style={{ textDecoration: 'none', color: '#000000' }}>
                        <div style={{ fontSize: '13px', marginTop: '8px', padding: '8px' }}>Зарегистрироваться</div>
                    </Link> */}
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
