import {useCallback, useState, useEffect} from 'react';
import axios from 'axios';
import {configData} from "../services/api-config";
import {useNavigate} from "react-router-dom";

const storageName = 'authData';

export const useAuth = () => {

    const [token, setToken] = useState(null)
    const [checkDone, setCheckDone] = useState(false);

    const login = useCallback((token) => {
        localStorage.setItem(
            storageName,
            JSON.stringify({token: token,date:new Date()})
        );
        setToken(token)
        setCheckDone(true);

    }, []);

    const logout = useCallback(() => {
        axios.post(configData.BASE_URL + 'api/v1/users/logout');
        localStorage.removeItem(storageName);
        sessionStorage.clear();
        setToken(null);
        window.location.href = '/login';
    }, []);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName))
        if (data && data.token) {
            login(data.token)
        }
    }, [login])

    return {login, logout, token, checkDone};
}
