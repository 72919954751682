import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Order, User} from "../../../services";
import News from "../../../services/News";
import FeedbackRequest from "../../../services/FeedbackRequest";

const CommentModal = ({item, onClose, user}) => {

    const [comment, setComment] = useState(item?.comment ? item.comment : undefined);

    const handleDeleteYes = () => {
        if (item) {
            const body = {moderator: user.name, comment};
            console.log('comment', body);
            FeedbackRequest.saveComment(item.id, body)
                .then((response) => {
                    onClose(true);
                })
                .catch((error) => {
                    alert("Произошла ошибка, попробуйте позже");
                });
        } else {
            alert('Необходимо заполнить причину удаления');
        }
    }

    return (
        <div className="modal-overlay">
            <div
                style={{
                    backgroundColor: "#ffffff",
                    height: "auto",
                    minHeight: "150px",
                    width: "450px",
                    margin: "200px auto",
                    borderRadius: "6px",
                }}>
                <div
                    style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                    <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                        <span style={{fontWeight: "600"}}>Комментарии</span>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={() => onClose(false)}>X</div>
                </div>
                <div style={{margin: '10px'}}>
                    <textarea rows="5" value={comment} style={{width: '100%', outline: 'none'}}
                              onChange={event => setComment(event.target.value)}/></div>
                <div style={{padding: '10px', width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={handleDeleteYes} style={{marginRight: '20px'}} variant="primary">
                        Сохранить
                    </Button>
                    <Button onClick={() => onClose(false)} variant="light" style={{marginRight: '20px'}}>
                        Закрыть
                    </Button>
                </div>

            </div>
        </div>
    );
};

export default CommentModal;
