import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment/moment";
import {Order} from "../../../services";
import {useSelector} from "react-redux";

const CanceledOrderModal = ({item, onClose}) => {
    const {user} = useSelector(state => state);

    const [comment, setComment] = useState();

    const onSave = () => {
        const body = {orderId: item.id, comment: comment, moderator:user.name}
        Order.canceledOrder(body)
            .then((response) => {
                onClose(true);
            })
            .catch((error) => {
                console.log(error);
                alert("Произошла ошибка, попробуйте позже")
            });
    }

    return (
        <>
            <div className="modal-overlay">
                <div style={{
                    backgroundColor: "#ffffff",
                    height: "300px",
                    width: "400px",
                    margin: "20px auto",
                    borderRadius: "6px",
                }}>
                    <div style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                        Отменить заказ
                    </div>
                    <div style={{padding: '10px'}}>
                        <textarea rows="5" value={comment} style={{width: '100%', outline: 'none'}}
                                  onChange={event => setComment(event.target.value)}/>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '5px',
                        padding: '10px'
                    }}>
                        <Button style={{width: '200px'}} variant="outline-primary" onClick={onSave}>
                            Сохранить
                        </Button>
                        <Button style={{width: '200px'}} variant="outline-primary" onClick={onClose}>
                            Закрыть
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CanceledOrderModal;
