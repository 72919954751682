import {get, post, put, destroy} from './adminConfig';

const SystemUsers = {
    getAll: (page) =>
        get(`v1/users/many?page=${page}`),
    list: (page, limit) =>
        get(`v1/users/list?page=${page}&limit=${limit}`),
    addUser: (data) =>
        post('v1/users', data),
    updateUser: (data) =>
        post('v1/users/update', data),
    getUser: () =>
        get('v1/users'),
    updatePassword: (userId, newPassword) =>
        get(`v1/users/update-password/${userId}/${newPassword}`)
}

export default SystemUsers;
