import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {File, User} from "../../services";
import Form from "react-bootstrap/Form";
import moment from "moment/moment";
import {useSelector} from "react-redux";
import DriverUpdateModal from "./Components/DriverUpdateModal";
import DriverConfirmModal from "./Components/DriverConfirmModal";
import PhotoViewer from "./Components/PhotoViewer";
import Vehicle from "../../services/Vehicle";
import Directory from "../../services/Directory";
import Loader from "../../utils/loader";
import CommunicationModal from "./Components/CommunicationModal";
import UserCommunications from "../../services/UserCommunications";

const DriverInfoModal = ({onClose, selectedDriver}) => {
    const {user} = useSelector((state) => state);
    const [company, setCompany] = useState(undefined);
    const [moderationSuccess, setModerationSuccess] = useState(false);
    const [changeSuccess, setChangeSuccess] = useState(false);
    const [moderationFailed, setModerationFailed] = useState(false);
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    const [docType, setDocType] = useState({});
    const [listDoc, setListDoc] = useState([]);
    const [image, setImage] = useState("");
    const [isShowPhotoViewer, setShowPhotoViewer] = useState(false);

    const [showRejectReason, setShowRejectReason] = useState(false);
    const [rejetcReasson, setRejectReason] = useState();
    const [errorTitle, setErrorTitle] = useState();
    const [driver, setDriver] = useState(selectedDriver);
    const [driverInfo, setDriverInfo] = useState({});
    const [isViewDriverInfo, setIsViewDriverInfo] = useState(false);
    const [isViewDocument, setIsViewDocument] = useState(true);


    const [smsCode, setSmsCode] = useState();
    const [isSmsCodeVisible, setIsSmsCodeVisible] = useState(false);
    const [saveSmsCodeText, setSaveSmsCodeText] = useState(false);
    const [vehicle, setVehicle] = useState();
    const [vehicleTypes, setVehicleTypes] = useState();
    const [communicationList, setCommunicationList] = useState([]);

    const [loading, setLoading] = useState(true);

    const [isShowDriverUpdateModal, setIsShowDriverUpdateModal] = useState(false);
    const [isShowCommunicationModal, setShowCommunicationModal] = useState(false);
    const [isShowCommunication, setIsShowCommunication] = useState(true);

    useEffect(() => {
        getDriverById();
        getVehicle();
        getDocList();
        vTypes();
        getCommunicationList();
    }, []);

    const getDriverById = () => {
        User.getDriverById(selectedDriver.id)
            .then((response) => {
                setDriver(response.data.user);
                if (response.data.company) {
                    setCompany(response.data.company);
                }
            })
            .catch((error) => {
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });
    };

    const getDocList = () => {
        File.getDocList(selectedDriver.id)
            .then((response) => {
                setListDoc(response.data);
            })
            .catch((error) => {
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    const getVehicle = () => {
        Vehicle.getByDriverId(selectedDriver.id).then(response => {
            console.log('response', response);
            setVehicle(response.data);
        }).catch(err => {
            console.log('err', err);
        })
    }

    const vTypes = () => {
        Directory.getTypes().then(response => {
            setVehicleTypes(response.data);
        }).catch(err => {
            console.log('err', err);
        })
    }

    const getCommunicationList = () => {
        UserCommunications.getByUserId(selectedDriver.id).then(response => {
            setCommunicationList(response.data);
        }).catch(err => {
            console.log('err', err);
        })
    }
    const handleDownload = () => {
        const a = document.createElement("a");
        a.href = image;
        a.setAttribute("download", "image.jpg");
        a.click();
    };

    const sendRejectReason = () => {
        if (rejetcReasson && rejetcReasson != "") {
            User.reject(driver.id, {
                comment: rejetcReasson,
                user: user.name,
                userType: 2,
            }).then((response) => {
                console.log("REJECT MODERATION RESPONCE", response);
                if (response.status == 200) {
                    setModerationFailed(true);
                    getDriverById();
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setErrorTitle("Необходимо заполнить причину отказа");
        }
    };

    const handleAddSmsCode = () => {
        User.addSmsCode(selectedDriver.id, smsCode)
            .then((response) => {
                if (response.status === 200) {
                    setSaveSmsCodeText("Успешно сохранено");
                    // alert('Успешно сохранено ' + smsCode);
                    setTimeout(() => {
                        setSaveSmsCodeText("");
                        setIsSmsCodeVisible(false);
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });
    };

    const handleCloseConfirm = (event) => {
        setIsShowConfirmModal(false);
        if (event) {
            setModerationSuccess(true);
            getDriverById();
            getVehicle();
        }
    }

    const handlePhotoView = (item) => {
        setDocType(item);
        setImage(null);
        setShowPhotoViewer(true);
        setLoading(true);

        File.getNewDoc(item.fileId).then((response) => {
            if (response.data) {
                const file = new Blob([response.data], {
                    type: response.data.type,
                });
                const fileURL = URL.createObjectURL(file);
                setImage(fileURL);
                setShowPhotoViewer(true);
                setLoading(false);
            } else {
                setLoading(false);
                console.log("Произошла ошибка, попробуйте позже");
                alert("Произошла ошибка, попробуйте позже");
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            alert("Произошла ошибка, попробуйте позже");
        });
    }

    const handleChange = (fieldName, value) => {
        setDriverInfo({...driverInfo, [fieldName]: value});
    }

    const handleClosePhotoView = () => {
        setShowPhotoViewer(false);
    }

    const onCloseUpdateModal = (isSuccess) => {
        setIsShowDriverUpdateModal(false);
        if (isSuccess) {
            getDriverById();
            getVehicle();
            getDocList();
        }
    }

    const onCloseCommunicationModal = () => {
        setShowCommunicationModal(false);
        getCommunicationList();
    }
    const renderModerationPannel = () => {
        if (selectedDriver.state === "Moderation") {
            if (moderationSuccess) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "rgba(19, 182, 94, 0.1)",
                            padding: "12px 16px",
                            borderRadius: "6px",
                        }}
                    >
                        <div style={{flex: "1"}}>
                            <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                Статус клиента
                            </div>
                            <div
                                style={{
                                    fontSize: "15px",
                                    color: "#13B65E",
                                    fontWeight: "700",
                                }}
                            >
                                Модерация пройдена!
                            </div>
                        </div>
                    </div>
                );
            } else if (moderationFailed) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "rgba(220, 53, 69, 0.1)",
                            padding: "12px 16px",
                            borderRadius: "6px",
                        }}
                    >
                        <div style={{flex: "1"}}>
                            <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                Статус клиента
                            </div>
                            <div
                                style={{
                                    fontSize: "15px",
                                    color: "#dc3545",
                                    fontWeight: "700",
                                }}
                            >
                                Заяка отклонена!
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div
                        style={{
                            backgroundColor: "#fafafa",
                            padding: "12px 16px",
                            borderRadius: "6px",
                            marginBottom: "10px",
                        }}
                    >
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div style={{flex: "1"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Статус клиента
                                </div>
                                <div
                                    style={{
                                        fontSize: "15px",
                                        color: "#f0ad4e",
                                        fontWeight: "700",
                                    }}
                                >
                                    На модерации
                                </div>
                            </div>
                            <div style={{marginLeft: "12px"}}>
                                <Button
                                    onClick={() =>
                                        showRejectReason ? null : setShowRejectReason(true)
                                    }
                                    variant="danger"
                                    size="sm"
                                    style={{opacity: `${showRejectReason ? "0.3" : "1"}`}}
                                >
                                    Отклонить
                                </Button>
                            </div>
                            <div style={{marginLeft: "12px"}}>
                                <Button onClick={
                                    showRejectReason
                                        ? null
                                        : () => {
                                            setIsShowConfirmModal(true);
                                        }
                                }
                                        variant="success"
                                        size="sm"
                                        style={{opacity: `${showRejectReason ? "0.3" : "1"}`}}>
                                    Подтвердить
                                </Button>
                            </div>
                        </div>
                        {isShowConfirmModal &&
                            <DriverConfirmModal driver={driver} driverInfo={driverInfo} user={user}
                                                onClose={handleCloseConfirm}/>}
                        {showRejectReason ? (
                            <div style={{marginTop: "12px", paddingBottom: "4px"}}>
                                <Form.Group>
                                    <Form.Label style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Причина отказа
                                    </Form.Label>
                                    <Form.Control
                                        value={rejetcReasson}
                                        onChange={(event) => setRejectReason(event.target.value)}
                                        as="textarea"
                                        placeholder="Введите причину отказа"
                                        rows={2}
                                        style={{fontSize: "14px"}}
                                    />
                                </Form.Group>
                                {errorTitle ? (
                                    <div
                                        style={{
                                            marginTop: "6px",
                                            color: "#dc3545",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {errorTitle}
                                    </div>
                                ) : null}
                                <div style={{textAlign: "right", marginTop: "12px"}}>
                                    <Button
                                        onClick={() => {
                                            setShowRejectReason(false);
                                            setRejectReason();
                                        }}
                                        variant="secondary"
                                        size="sm"
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        onClick={sendRejectReason}
                                        variant="primary"
                                        size="sm"
                                        style={{marginLeft: "12px"}}
                                    >
                                        Отправить отказ
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                );
            }
        } else return null;
    };

    const renderUpdatePanel = () => {
        if (changeSuccess) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgba(19, 182, 94, 0.1)",
                        padding: "12px 16px",
                        borderRadius: "6px",
                    }}
                >
                    <div style={{flex: "1"}}>
                        <div
                            style={{
                                fontSize: "15px",
                                color: "#13B65E",
                                fontWeight: "700",
                            }}
                        >
                            Изменения сохранены!
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        backgroundColor: "#fafafa",
                        padding: "12px 16px",
                        borderRadius: "6px",
                    }}
                >
                    <div style={{display: "flex", alignItems: "center"}}>

                        <div style={{display: "flex", width: '100%', gap: '10px', justifyContent: 'right'}}>
                            <Button onClick={() => setIsShowDriverUpdateModal(true)}
                                    variant="success" size="sm">
                                Изменить данные
                            </Button>
                            <Button onClick={() => setShowCommunicationModal(true)}
                                    variant="success" size="sm">
                                Коммуникация
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className="modal-overlay">
            <div style={{
                backgroundColor: "#ffffff",
                maxHeight: "calc(100vh - 50px)",
                width: "640px",
                margin: "24px auto",
                borderRadius: "6px",
            }}>
                <div style={{
                    padding: "14px 24px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #eeeeee",
                    borderTopLeftRadius: "6px",
                    borderTopRightRadius: "6px",
                }}>
                    <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                        Информация о водителе
                    </div>
                    <div style={{cursor: "pointer"}} onClick={onClose}>
                        X
                    </div>
                </div>
                <div style={{
                    overflowY: "scroll",
                    maxHeight: "calc(100vh - 103px)",
                    textAlign: "left",
                }}>
                    <div style={{padding: "16px"}}>
                        {renderModerationPannel()}
                        {renderUpdatePanel()}
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                            paddingBottom: "10px",
                            marginTop: "24px",
                        }}>
                            <div
                                style={{
                                    fontSize: "15px",
                                    color: "#A3ACB6",
                                    lineHeight: "1.3",
                                    fontWeight: "600",
                                }}
                            >
                                Основные данные
                            </div>
                        </div>

                        <div className="row" style={{marginTop: "16px"}}>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Фамилия
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {driver.surname}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>Имя</div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {driver.name}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Отчество
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {driver.patronymic || "-"}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Номер телефона
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    +{driver.phoneNumber}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>Email</div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {driver.email}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Компания
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {company && company.name}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Тип транспорта
                                </div>
                                <div style={{
                                    fontSize: "14px",
                                    marginTop: "2px",
                                }}>
                                    {vehicle && vehicle.vehicleTypeName}
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                            paddingBottom: "10px",
                            marginTop: "24px",
                        }}>
                            <div
                                style={{
                                    fontSize: "15px",
                                    color: "#A3ACB6",
                                    lineHeight: "1.3",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIsViewDriverInfo((curr) => !curr);
                                }}>
                                Доп информация
                            </div>
                        </div>

                        {isViewDriverInfo && (
                            <div className="row" style={{marginTop: "16px"}}>
                                <div className="col-4" style={{paddingBottom: "12px"}}>
                                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>ИИН</div>
                                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                                        {driver?.iin || "-"}
                                    </div>
                                </div>
                                <div className="col-4" style={{paddingBottom: "12px"}}>
                                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Номер удостоверения
                                    </div>
                                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                                        {driver?.idNumber || "-"}
                                    </div>
                                </div>
                                <div className="col-4" style={{paddingBottom: "12px"}}>
                                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Кем выдан
                                    </div>
                                    <div style={{
                                        fontSize: "14px",
                                        marginTop: "2px",
                                    }}>
                                        {driver?.issuedBy || "-"} -{" "}
                                        {driver?.issuedDate
                                            ? moment(driver?.issuedDate).format("DD.MM.YYYY")
                                            : "" || ""}
                                    </div>
                                </div>
                                <div className="col-4" style={{paddingBottom: "12px"}}>
                                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Номер авто
                                    </div>
                                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                                        {vehicle?.carNumber || ""}
                                    </div>
                                </div>
                                <div className="col-4" style={{paddingBottom: "12px"}}>
                                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Модель авто
                                    </div>
                                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                                        {vehicle?.carModel || "-"}
                                    </div>
                                </div>
                                <div className="col-4" style={{paddingBottom: "12px"}}>
                                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Гос номер прицепа
                                    </div>
                                    <div style={{
                                        fontSize: "14px",
                                        marginTop: "2px",
                                    }}>
                                        {vehicle?.trailerNumber || "-"}
                                    </div>
                                </div>
                                <div className="col-4" style={{paddingBottom: "12px"}}>
                                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Марка
                                    </div>
                                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                                        {vehicle?.trailerModel || "-"}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                            paddingBottom: "10px",
                            marginTop: "24px",
                        }}>
                            <div
                                style={{
                                    fontSize: "15px",
                                    color: "#A3ACB6",
                                    lineHeight: "1.3",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIsShowCommunication((curr) => !curr);
                                }}>
                                Коммуникация
                            </div>
                        </div>
                        {isShowCommunication && (
                            <div className="row" style={{marginTop: "16px", padding: "10px"}}>
                                {communicationList.map((c) => {
                                    return (
                                        <div className="col-12"
                                             style={{borderBottom: '1px solid #000', paddingBottom: '10px'}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <span><i>Модератор:</i>{c.moderator}</span><span> {moment(c.createdAt).format("DD.MM.YYYY")}</span>
                                            </div>
                                            <div><span><i>Комментарий:</i></span><span> {c.comment}</span></div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}


                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                                paddingBottom: "10px",
                                marginTop: "24px",
                            }}>
                            <div style={{
                                fontSize: "15px",
                                color: "#A3ACB6",
                                lineHeight: "1.3",
                                fontWeight: "600",
                                cursor: "pointer",
                            }}
                                 onClick={() => {
                                     setIsViewDocument((curr) => !curr);
                                 }}>
                                Документы
                            </div>
                        </div>

                        {isViewDocument && (
                            <div className="row" style={{marginTop: "16px", padding: "10px"}}>
                                {listDoc.map((item) => {
                                    return (
                                        <div className="card-drivers" key={item.id}>
                                            <div style={{width: "200px"}}>{item.typeName}</div>
                                            <div> {moment(item.createdAt).format("DD.MM.YYYY HH:mm")}</div>
                                            <div>{item.sideName}</div>
                                            <div onClick={() => handlePhotoView(item)}
                                                 style={{color: "green", cursor: "pointer"}}>
                                                Просмотр
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <div style={{
                            borderTop: "1px solid grey",
                            width: "100%",
                            paddingTop: "10px",
                            display: "none"
                        }}>
                            <button
                                style={{
                                    borderRadius: "4px",
                                    border: "1px solid #000",
                                    padding: "5px",
                                    cursor: "pointer",
                                    marginBottom: "5px",
                                    backgroundColor: "transparent"
                                }}
                                onClick={() => {
                                    setSmsCode("");
                                    setIsSmsCodeVisible((cur) => !cur);
                                }}>
                                Cоздать смс код
                            </button>

                            {isSmsCodeVisible && (
                                <div style={{marginTop: "10px"}}>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{color: "#2c2c2d", fontSize: "15px"}}>
                                            Введите SMS-код
                                        </Form.Label>
                                        <Form.Control
                                            size="4"
                                            type="number"
                                            value={smsCode}
                                            onChange={(e) => setSmsCode(e.target.value)}
                                            placeholder="0000"
                                        />
                                    </Form.Group>
                                    {saveSmsCodeText && <h3>{saveSmsCodeText}</h3>}
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={handleAddSmsCode}
                                    >
                                        Сохранить
                                    </Button>
                                </div>
                            )}
                        </div>
                        {isShowPhotoViewer && (<>
                            {loading ? (<Loader/>) : (
                                <PhotoViewer driver={driver} image={image} docType={docType} driverInfo={driverInfo}
                                             handleChange={handleChange} handleDownload={handleDownload}
                                             onClose={handleClosePhotoView}
                                />
                            )}
                        </>)
                        }
                        {isShowDriverUpdateModal &&
                            <DriverUpdateModal driver={driver} vehicle={vehicle} vtypes={vehicleTypes}
                                               onClose={onCloseUpdateModal}/>}

                        {isShowCommunicationModal && <CommunicationModal user={user} item={driver}
                                                                         onClose={onCloseCommunicationModal}/>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DriverInfoModal;
