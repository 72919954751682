import {destroy, get, post, put} from "./config";

const LogService = {
    list: (page, limit) =>
        get(`v1/log/list?page=${page}&limit=${limit}`),
    getDetials: (logId) =>
        get(`v1/log/details/${logId}`),
}

export default LogService;
