import moment from "moment/moment";
import React, {useState} from "react";
import {numberToFinanceFormat} from "../../../utils";

const DriverInfo = ({driver, vehicle}) => {

    const [showFullInfo, setShowFullInfo] = useState(false);

    return (
        <>
            <div className="row" style={{marginTop: "16px"}}>
                <div className="col-4" style={{paddingBottom: "12px"}}>
                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>ФИО</div>
                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                        {driver.surname} {driver.name} {driver.patronymic}
                    </div>
                </div>
                <div className="col-4" style={{paddingBottom: "12px"}}>
                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>Телефон</div>
                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                        {driver.phoneNumber}
                    </div>
                </div>
                <div className="col-4" style={{paddingBottom: "12px"}}>
                    <div style={{color: "#A3ACB6", fontSize: "12px"}}>Тип транспорта</div>
                    <div style={{fontSize: "14px", marginTop: "2px"}}>
                        {vehicle?.vehicleTypeName || '-'}
                    </div>
                </div>
            </div>
            <div className='row' style={{marginTop: '16px'}}>
                <div className='col-4' style={{paddingBottom: '12px'}}>
                    <div style={{color: '#A3ACB6', fontSize: '12px'}}>ИИН</div>
                    <div style={{fontSize: '14px', marginTop: '2px'}}>{driver?.iin || '-'}</div>
                </div>
                <div className='col-4' style={{paddingBottom: '12px'}}>
                    <div style={{color: '#A3ACB6', fontSize: '12px'}}>Номер удостоверения</div>
                    <div style={{fontSize: '14px', marginTop: '2px'}}>{driver?.idNumber || '-'}</div>
                </div>
                <div className='col-4' style={{paddingBottom: '12px'}}>
                    <div style={{color: '#A3ACB6', fontSize: '12px'}}>Кем выдан</div>
                    <div style={{
                        fontSize: '14px',
                        marginTop: '2px'
                    }}>{driver?.issuedBy || '-'} - {driver?.issuedDate ? moment(driver?.issuedDate).format("DD.MM.YYYY") : '' || ''}</div>
                </div>
                <div className='col-4' style={{paddingBottom: '12px'}}>
                    <div style={{color: '#A3ACB6', fontSize: '12px'}}>Номер авто</div>
                    <div style={{fontSize: '14px', marginTop: '2px'}}>{vehicle?.carNumber || ''}</div>
                </div>
                <div className='col-4' style={{paddingBottom: '12px'}}>
                    <div style={{color: '#A3ACB6', fontSize: '12px'}}>Модель авто</div>
                    <div style={{fontSize: '14px', marginTop: '2px'}}>{vehicle?.carModel || '-'}</div>
                </div>
                <div className='col-4' style={{paddingBottom: '12px'}}>
                    <div style={{color: '#A3ACB6', fontSize: '12px'}}>Гос номер прицепа</div>
                    <div style={{
                        fontSize: '14px',
                        marginTop: '2px'
                    }}>{vehicle?.trailerNumber || '-'}</div>
                </div>
                <div className='col-4' style={{paddingBottom: '12px'}}>
                    <div style={{color: '#A3ACB6', fontSize: '12px'}}>Марка</div>
                    <div
                        style={{fontSize: '14px', marginTop: '2px'}}>{vehicle?.trailerModel || '-'}</div>
                </div>
            </div>
        </>
    );
};

export default DriverInfo;

