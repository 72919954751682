import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";

const PhotoViewer = ({imgUrl, onClose}) => {

    const handleDownload = () => {
        const a = document.createElement("a");
        a.href = imgUrl;
        a.setAttribute("download", "image.jpg");
        a.click();
    };

    return (
        <>
            <div className="modal-overlay">
                <div style={{
                    backgroundColor: "#ffffff",
                    height: "600px",
                    width: "750px",
                    margin: "20px auto",
                    borderRadius: "6px",
                }}>
                    <div style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                        <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                            <img style={{objectFit: 'cover'}} width="700px" height="500px" src={imgUrl} alt=""/>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                        <Button style={{width: '200px'}} variant="outline-primary"
                                onClick={handleDownload}>Скачать</Button>
                        <Button style={{width: '200px'}} variant="outline-primary" onClick={() => {
                            onClose()
                        }}>Закрыть
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PhotoViewer;
