import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import PhotoViewer from "./PhotoViewer";
import {File} from "../../../services";
import Loader from "../../../utils/loader";

const LoadingCargoPhotos = ({files}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [imgUrl, setImgUrl] = useState();
    const [loading, setLoading] = useState(false);

    const handleOpenPhoto = (docType) => {
        const doc = files.find(x => x.documentType === docType);
        if (!doc) {
            alert('Фото не найден')
            return;
        }

        setLoading(true);
        File.getOrderDocDownload(doc.fileId).then((response) => {

            const file = new Blob([response.data], {
                type: response.data.type,
            });

            const fileURL = URL.createObjectURL(file);
            setImgUrl(fileURL);
            setIsOpen(true);
            setLoading(false);

        }).catch((error) => {
            setLoading(false);
            console.log(error);
        });
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <>
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', width: '100%', gap: '8px'}}>
                <h3 style={{
                    fontSize: "15px",
                    color: '#000',
                    fontWeight: "600",
                    lineHeight: '1.3',
                    marginTop: '10px'
                }}>Фото при погрузке</h3>
                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '0px'
                }}>
                    <span style={{fontSize: '14px'}}>Фото груза</span>
                    <span style={{fontSize: '14px', color: '#1b63f8', cursor: 'pointer'}}
                          onClick={() => handleOpenPhoto(1)}>просмотр</span>
                </div>
                <hr style={{margin: '0px'}}/>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px'}}>
                    <span style={{fontSize: '14px'}}>Фото пломбы</span>
                    <span style={{fontSize: '14px', color: '#1b63f8', cursor: 'pointer'}}
                          onClick={() => handleOpenPhoto(2)}>просмотр</span>
                </div>
            </div>
            {isOpen && <PhotoViewer imgUrl={imgUrl} onClose={handleClose}/>}
            {loading && <Loader/>}
        </>
    );
};

export default LoadingCargoPhotos;
