import React, {useEffect, useState} from "react";
import {numberToFinanceFormat} from "../../../utils";
import Button from "react-bootstrap/Button";

const PhotoViewerCl = ({imgUrl, onClose, onDownload, role}) => {
    return (
        <>
            <div className="modal-overlay">
                <div style={{
                    backgroundColor: "#ffffff",
                    height: "600px",
                    width: "750px",
                    margin: "20px auto",
                    borderRadius: "6px",
                }}>
                    <div style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                        <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                            <img style={{objectFit: 'cover'}} width="700px" height="500px" src={imgUrl} alt=""/>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px'}}>
                        {role === 'superAdmin' &&
                            <Button style={{width: '200px'}} variant="outline-primary" onClick={onDownload}>
                                Скачать
                            </Button>}
                        <Button style={{width: '200px'}} variant="outline-primary" onClick={() => {
                            onClose()
                        }}>Закрыть
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PhotoViewerCl;
