import { Backdrop } from "@mui/material";
import { BounceLoader } from "react-spinners";

function Loader() {
    return (
        <div className="progress" style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30%', width: '100%',
            background: 'transparent', fontSize: '35px'
        }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                            <BounceLoader color="#fff" size={40} />
                    </Backdrop>
        </div>
    );
}

export default Loader;
