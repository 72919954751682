import {get, post, put, destroy} from './config';

const Export = {
    exportOrders: (status, page, limit, orderNo, sender, recipient, startCreateAt, endCreateAt) =>
        get(`v1/export/export-orders?status=${status}&page=${page}&limit=${limit}&orderNo=${orderNo}&sender=${sender}&recipient=${recipient}&startCreatedAt=${startCreateAt}&endCreatedAt=${endCreateAt}`, {responseType: 'blob'}),
    exportPriceComparisonOrders: (status, startCreatedAt, endCreatedAt) =>
        get(`v1/export/export-price-comparison-orders?status=${status}&startCreatedAt=${startCreatedAt}&endCreatedAt=${endCreatedAt}`, {responseType: 'blob'}),
    exportProfitFromOrders: (status, startShippingDate, endShippingDate) =>
        get(`v1/export/export-profit-from-orders?status=${status}&startShippingDate=${startShippingDate}&endShippingDate=${endShippingDate}`, {responseType: 'blob'}),

    exportClients: (state, type, name, phoneNumber, bin, email) =>
        get(`v1/export/export-clients?state=${state}&type=${type}&name=${name}&phoneNumber=${phoneNumber}&bin=${bin}&email=${email}`, {responseType: 'blob'}),
    exportDrivers: (state, type, name, phoneNumber, bin, email) =>
        get(`v1/export/export-drivers?state=${state}&type=${type}&name=${name}&phoneNumber=${phoneNumber}&bin=${bin}&email=${email}`, {responseType: 'blob'}),
    exportConfirmedDriverOrders: () => get(`v1/export/export-drivers-for-statics`, {responseType: 'blob'}),
    exportInsurance: (startDate,endDate) => get(`v1/export/export-insurance?startDate=${startDate}&endDate=${endDate}`, {responseType: 'blob'})
}

export default Export;
