import React, {useEffect, useState} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import {Directory, User} from '../../services';
import Button from 'react-bootstrap/Button';
import CategoryCreateModal from './CategoryCreateModal';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../utils/loader';

const CategoriesPage = () => {

    const [categories, setCategories] = useState();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [loading, setLoading] = useState(true);

    const insuranceCategories = [{id: 1, name: 'Категория 1'}, {id: 2, name: 'Категория 2'}, {
        id: 3,
        name: 'Категория 3'
    }, {id: 4, name: 'Особый груз'}, {id: 5, name: 'Опасный груз'}, {id: 6, name: 'Автомобиль'}];

    useEffect(() => {
        getCategories()
    }, []);

    const getCategories = () => {
        setLoading(true); // Set loading to true when fetching categories
        Directory.getCategories()
            .then((response) => {
                console.log('CATEGORIES', response);
                if (response.status === 200) {
                    if (response.data.length > 0) setCategories(response.data);
                    else setCategories([]);
                } else {
                    setCategories([]);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false after fetching categories
            });
    };

    const handleDeleteYes = () => {
        Directory.deleteCategory(deleteId).then((response) => {
            if (response.status == 200) {
                setDeleteId(undefined);
                getCategories();
            } else {
                setDeleteId(undefined);
                alert("Произошла ошибка, попробуйте позже")
            }
        }).catch((error) => {
            console.log(error);
            setDeleteId(undefined);
            alert("Произошла ошибка, попробуйте позже");
        });
    }

    const renderMadalDelete = () => {
        return (
            <div className="modal-overlay">
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        height: "120px",
                        width: "450px",
                        margin: "200px auto",
                        borderRadius: "6px",
                    }}
                >
                    <div
                        style={{
                            padding: "14px 24px",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#ffffff",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eeeeee",
                            borderTopLeftRadius: "6px",
                            borderTopRightRadius: "6px",
                        }}
                    >
                        <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                            <span style={{fontWeight: "600"}}>Вы действительно хотите удалить? </span>
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={() => {
                            setDeleteId(undefined)
                        }}>X
                        </div>
                    </div>
                    <div style={{marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button onClick={() => {
                            setDeleteId(undefined)
                        }} variant="light" style={{marginRight: '20px'}}>Нет</Button>
                        <Button onClick={() => {
                            handleDeleteYes()
                        }} style={{marginRight: '20px'}} variant="primary">Да</Button>
                    </div>
                </div>
            </div>
        );
    };

    const insuranceCategoryName = (category) => {
        if (category) {
            const item = insuranceCategories.find(x => x.id === category);
            if (item) {
                return item.name;
            }
        }

        return;
    }

    const renderList = () => {
        if (categories) {
            if (categories.length > 0) {
                const list = categories.map(item => {
                    return (
                        <ListGroup.Item style={{padding: '16px'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>{item.name}</div>
                                <div style={{marginLeft: '12px'}}><Badge bg="secondary">{item.value}</Badge>
                                </div>
                                <div style={{marginLeft: '12px', flex: '1'}}>
                                    {insuranceCategoryName(item.insuranceCategory)}
                                </div>
                                <div>
                                    <Button size="sm" variant="outline-danger"
                                            style={{fontSize: '11px'}}
                                            onClick={() => {
                                                setDeleteId(item.id);
                                            }}>Удалить</Button>
                                    <Button size="sm" variant="outline-secondary"
                                            style={{fontSize: '11px', marginLeft: '12px'}}
                                            onClick={() => {
                                                setEditItem(item);
                                            }}>Редактировать</Button>
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                });

                return list;
            } else {
                return <div>Категорий нет</div>
            }
        }
    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>Категории груза</div>
                <div>
                    <Button onClick={() => setShowCreateModal(true)} size="sm">Добавить категорию</Button>
                </div>
            </div>
            <div style={{marginTop: '20px'}}>
                <ListGroup>
                    {loading ? <Loader/> : (
                        renderList()
                    )}
                </ListGroup>
            </div>
            {
                (showCreateModal || editItem) &&
                <CategoryCreateModal item={editItem} onClose={() => {
                    setShowCreateModal(false);
                    setEditItem(null);
                    getCategories();
                }} update={getCategories}/>
            }
            {
                deleteId && renderMadalDelete()
            }
        </>
    );
}

export default CategoriesPage;
