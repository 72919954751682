import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import {User, Order, SystemRoles} from "../../services";
import Button from 'react-bootstrap/Button';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Form from "react-bootstrap/Form";
import {ORDER_STATUSES} from "./Statuses";

const OrderListItem = ({item, selectOrder, getOrders, editDate, onCanceledOrder}) => {
    const [isDelete, setIsDelete] = useState(false);
    const [role, setRole] = useState();
    const {user} = useSelector(state => state);

    const [showDeleteReason, setShowDeleteReason] = useState();
    const [deleteReason, setDeleteReason] = useState();
    const [errorTitle, setErrorTitle] = useState();

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    console.log('ROLE: ', response.data.name)
                    if (response.data) setRole(response.data.code)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    const selectItem = () => {
        selectOrder({
            ...item
        })
    }

    const handlerDelete = (item) => {
        if (deleteReason !== '') {
            Order.deleteOrder(item.id, user.name, deleteReason)
                .then((response) => {
                    if (response.status == 200) {
                        setIsDelete(false)
                        getOrders()
                    } else {
                        setIsDelete(false)
                        alert("Произошла ошибка, попробуйте позже")
                    }
                })
                .catch((error) => {
                    console.log(error);
                    alert("Произошла ошибка, попробуйте позже")
                    setIsDelete(false)
                });
        } else {
            setErrorTitle('Необходимо заполнить причину удаления');
        }
    }

    let getClassNames = () => {

        let classNames = '';
        if (!item.isActualCities) {
            classNames = 'not-actual-city';
        }

        if (item.isActiveAuction) {
            classNames = `${classNames} active-auction`;
        }

        let currentDate = new Date();
        let dt = new Date(item.shippingDate);
        let shippingDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 0);

        if (['published', 'moderation', 'awaitingpayment'].includes(item.status) && currentDate.getTime() > shippingDate.getTime()) {
            classNames = `${classNames} overdue-orders`;
        }

        return classNames;
    }

    const isOverdue = () => {
        let currentDate = new Date();
        let dt = new Date(item.shippingDate);
        let shippingDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 0);

        if (['published', 'moderation', 'awaitingpayment','booked','confirmed','processloadingcargo'].includes(item.status) && currentDate.getTime() > shippingDate.getTime()) {
            return true;
        }
        return false;
    }

    const isAllowCanceled = () => {
        if (['published', 'moderation', 'awaitingpayment'].includes(item.status)) return true;
        else return false;
    }

    const renderContent = () => {
        return (
            <tr style={item.isActiveAuction ? {color: "#fff"} : {}}>
                <td onClick={selectItem}
                    className={`${getClassNames()}`}>
                    <div style={{padding: "5px 0"}}>{item.number}</div>
                    {item.isActiveAuction ? (
                        <span>Идут встречные предложения</span>
                    ) : null}
                </td>
                <td onClick={selectItem}
                    className={`${getClassNames()}`}>
                    <div style={{padding: "5px 0"}}>
                        {moment(item.createdAt).format("DD.MM.YYYY")}
                    </div>
                </td>
                <td onClick={selectItem}
                    className={`${getClassNames()}`}>
                    <div style={{
                        padding: "5px 0",
                        wordWrap: "break-word",
                        maxWidth: "160px"
                    }}>{item.senderName ?? ''}</div>
                </td>
                <td onClick={selectItem}
                    className={`${getClassNames()}`}>
                    <div style={{padding: "5px 0", wordWrap: "break-word", maxWidth: "160px"}}>
                        {item.recipientName ?? ''}
                    </div>
                </td>
                <td onClick={selectItem}
                    className={`${getClassNames()}`}>
                    <div style={{padding: "5px 0"}}>
                        {moment(item.shippingDate).format("DD.MM.YYYY")}
                    </div>
                </td>
                <td onClick={selectItem}
                    className={`${getClassNames()}`}>
                    <div style={{padding: "5px 0", wordWrap: "break-word", maxWidth: "160px"}}>
                        {`${item.countryFromName}, г. ${item.cityFromName}, ${item.addressFrom}`}
                    </div>
                </td>
                <td onClick={selectItem}
                    className={`${getClassNames()}`}>
                    <div style={{padding: "5px 0", wordWrap: "break-word", maxWidth: "160px"}}>
                        {`${item.countryToName}, г. ${item.cityToName}, ${item.addressTo}`}
                    </div>
                </td>
                <td onClick={selectItem}
                    className={`${getClassNames()}`}>
                    <div style={{padding: "5px 0"}}>
                        {moment(item.unloadingDate).format("DD.MM.YYYY")}
                    </div>
                </td>
                <td onClick={selectItem}
                    className={`${getClassNames()}`}>
                    <div style={{padding: "5px 0", maxWidth: "max-content"}}>
                        <Badge bg="primary">{ORDER_STATUSES[item.status]}</Badge>
                    </div>
                </td>
                <td className={`${getClassNames()}`}>
                    <div style={{display: 'flex', width: '100%'}}>
                        {
                            role === "superAdmin" ?
                                <div
                                    style={{
                                        width: "30px",
                                        textAlign: "center",
                                        padding: "5px 0",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setIsDelete(true)
                                    }}>
                                    <img height="17px" src="/icons/delete.png"/>
                                </div>
                                : ''
                        }
                        {
                            isDelete ?
                                renderMadalDelete(item)
                                : <span></span>
                        }
                        {isOverdue() && (<div title="Изменить дату"
                                              style={{
                                                  width: "30px",
                                                  textAlign: "center",
                                                  padding: "5px 0",
                                                  cursor: "pointer",
                                              }}
                                              onClick={() => editDate(item)}>
                            <img height="17px" src="/icons/edit.svg"/>
                        </div>)}
                        {isAllowCanceled() && (<div title="Отменить заказ"
                                                    style={{
                                                        width: "30px",
                                                        textAlign: "center",
                                                        padding: "5px 0",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => onCanceledOrder(item)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                                <path
                                    d="M10.0016 19.5C8.68772 19.5 7.45268 19.2506 6.29655 18.752C5.1404 18.2533 4.13472 17.5765 3.2795 16.7217C2.42427 15.8669 1.74721 14.8616 1.24833 13.706C0.749442 12.5504 0.5 11.3156 0.5 10.0017C0.5 8.68772 0.749334 7.45268 1.248 6.29655C1.74667 5.1404 2.42342 4.13472 3.27825 3.2795C4.1331 2.42427 5.13834 1.74721 6.29398 1.24833C7.44959 0.749443 8.68437 0.5 9.9983 0.5C11.3122 0.5 12.5473 0.749334 13.7034 1.248C14.8596 1.74667 15.8652 2.42342 16.7205 3.27825C17.5757 4.1331 18.2527 5.13834 18.7516 6.29398C19.2505 7.44959 19.5 8.68437 19.5 9.9983C19.5 11.3122 19.2506 12.5473 18.752 13.7034C18.2533 14.8596 17.5765 15.8652 16.7217 16.7205C15.8669 17.5757 14.8616 18.2527 13.706 18.7516C12.5504 19.2505 11.3156 19.5 10.0016 19.5ZM9.99998 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 9.99998C18 9.06176 17.8397 8.15829 17.5192 7.28958C17.1987 6.42086 16.741 5.62688 16.1461 4.90765L4.90765 16.1461C5.62688 16.7474 6.42086 17.2067 7.28958 17.524C8.15829 17.8413 9.06176 18 9.99998 18ZM3.85383 15.0923L15.0923 3.85383C14.3795 3.24614 13.5877 2.78524 12.7169 2.47113C11.8461 2.15703 10.9404 1.99998 9.99998 1.99998C7.76664 1.99998 5.87498 2.77498 4.32498 4.32498C2.77498 5.87498 1.99998 7.76664 1.99998 9.99998C1.99998 10.9382 2.15863 11.8417 2.47595 12.7104C2.79325 13.5791 3.25254 14.3731 3.85383 15.0923Z"
                                    fill="#FA1861"/>
                            </svg>
                        </div>)}
                    </div>
                </td>
            </tr>
        );
    };

    const renderMadalDelete = (item) => {
        return (
            <div className="modal-overlay">
                <div style={{
                    backgroundColor: "#ffffff",
                    height: 'auto',
                    minHeight: "120px",
                    width: "450px",
                    margin: "200px auto",
                    borderRadius: "6px",
                }}>
                    <div
                        style={{
                            padding: "14px 24px",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#ffffff",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eeeeee",
                            borderTopLeftRadius: "6px",
                            borderTopRightRadius: "6px",
                        }}
                    >
                        <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                            <span style={{fontWeight: "600"}}>Вы действительно хотите удалить? </span>
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={() => {
                            setIsDelete(false)
                        }}>X
                        </div>
                    </div>
                    {!showDeleteReason && (
                        <div style={{marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => {
                                setIsDelete(false);
                                setShowDeleteReason(undefined);
                            }} variant="light" style={{marginRight: '20px'}}>Нет</Button>
                            <Button onClick={() => setShowDeleteReason(true)} style={{marginRight: '20px'}}
                                    variant="primary">Да</Button>
                        </div>)}
                    {showDeleteReason ? (
                        <div style={{margin: "10px 10px", paddingBottom: "4px"}}>
                            <Form.Group>
                                <Form.Label style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Причина удаление
                                </Form.Label>
                                <Form.Control
                                    onChange={(event) => setDeleteReason(event.target.value)}
                                    as="textarea"
                                    placeholder="Введите причину удаления"
                                    rows={2}
                                    style={{fontSize: "14px"}}
                                />
                            </Form.Group>
                            {errorTitle ? (
                                <div
                                    style={{
                                        marginTop: "6px",
                                        color: "#dc3545",
                                        fontSize: "12px",
                                    }}
                                >
                                    {errorTitle}
                                </div>
                            ) : null}
                            <div style={{textAlign: "right", marginTop: "12px", marginBottom: "12px"}}>
                                <Button
                                    onClick={() => {
                                        setShowDeleteReason(false);
                                        setIsDelete(false);
                                    }}
                                    variant="secondary"
                                    size="sm">
                                    Отменить
                                </Button>
                                <Button onClick={() => {
                                    handlerDelete(item)
                                }} variant="primary"
                                        size="sm"
                                        style={{marginLeft: "12px"}}>
                                    Подтвердить
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    return (
        <>
            {renderContent()}
        </>
    );
};

export default OrderListItem;
