import {get, post, put, destroy} from './config';

const NotifyService = {
    sendEmail: (body) =>
        post(`v1/notifications/send-email`, body),
    getQuantityNotifications : (params) =>
        get(`v1/moderatornotification/quantity`, {params}),
    getNotificationsList: (params) =>
        get('v1/moderatornotification/list', {params}),
    postNotificationSeen: (id, moderatorName) =>
        post(`v1/moderatornotification/read/${id}?moderator=${moderatorName}`)
}

export default NotifyService;
