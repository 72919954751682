import {get, post, put, destroy} from './config';

const Order = {
    getAll: (status, page) =>
        get(`v1/orders/all?status=${status}&page=${page}`),
    getById: (orderId) =>
        get(`/v1/orders/by-${orderId}`),
    updateStatus: (orderId, status, user) =>
        post(`v1/orders/update-status/${orderId}?status=${status}&username=${user}`),
    rejectConfirm: (orderId, status, user, reason) =>
        post(`v1/orders/reject-confirm/${status}/${orderId}?username=${user}&reason=${reason}`),
    getRecipient: (recipientId) =>
        get(`v1/orders/recipient/by-recipient-${recipientId}`),
    getCountry: (countryId) =>
        get(`v1/directories/countries/by-country-${countryId}`),
    getCity: (cityId) =>
        get(`v1/directories/city/by-${cityId}`),
    getOrderPhoto: (orderId) =>
        get(`v1/files/order/evidence/by-order-${orderId}`, {responseType: 'blob'}),
    getLastPosition: (orderId, driverId) =>
        get(`v1/orders/positions/last?orderId=${orderId}&driverId=${driverId}`),
    getCargo: (cargoId) =>
        get(`v1/orders/cargo/by-cargo-${cargoId}`),
    getCategories: () =>
        get('v1/directories/cargo/categories'),
    getTypes: () =>
        get('v1/directories/vehicle/types'),
    getCities: (countryId) =>
        get(`v1/directories/cities/by-${countryId}`),
    getCountries: () =>
        get('v1/directories/countries'),
    deleteOrder: (orderId, user, reason) =>
        destroy(`v1/orders/${orderId}?username=${user}&reason=${reason}`),
    getOrders: (status, page, limit, orderNo, sender, recipient,startCreateAt,endCreateAt) =>
        get(`v1/orders/list?status=${status}&page=${page}&limit=${limit}&orderNo=${orderNo}&startCreateAt=${startCreateAt}&endCreateAt=${endCreateAt}&sender=${sender}&recipient=${recipient}`),
    getOrdersBySenderName: (sender) =>
        get(`v1/orders/list?sender=${sender}`),
    orderStates: (id) =>
        get(`v1/orders/states/${id}`),
    getCoordinates: (number) =>
        get(`v1/orders/positions/list?orderNo=${number}`),
    quantityByStatus: () =>
        get(`v1/orders/quantity/by-status`),
    getClientOrdersQuantity: (clientId) =>
        get(`v1/orders/quantity/by-user-${clientId}`),
    getTotalNetPrice: (clientId) =>
        get(`v1/orders/total-netprice`),
    updateShippingUnloadingDate: (orderId, body) =>
        put(`v1/orders/update-shipping-unloading-date/${orderId}`,body),
    canceledOrder: ( body) =>
        post(`v1/orders/canceled`,body),
}
export default Order;
