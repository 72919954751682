import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import DriverInfoModal from './DriverInfoModal';
import DriverCreateModal from './DriverCreateModal';
import {User, SystemRoles} from '../../services';
import moment from 'moment';
import Pagination from 'react-responsive-pagination';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "../../utils/loader";
import './driver.scss';
import Export from "../../services/Export";
import DriversItem from "./DriversItem";
import DeleteModal from "./Components/DeleteModal";

export const DRIVER_STATUSES_DATA = {
    "Pending": {title: 'Завершение регистрации', color: '#f0be39'},
    "Moderation": {title: 'Ожидает модерации', color: '#0275d8'},
    'Confirmed': {title: 'Верифицирован', color: '#5cb85c'},
    "Rejected": {title: 'Модерация отклонена', color: '#FF0000'}
}

export const DRIVER_STATUSES = [{id: '', name: 'Все'},
    {id: 'pending', name: 'Зарешение регистрации'},
    {id: 'moderation', name: 'Ожидает модерации'},
    {id: 'confirmed', name: 'Верифицирован'},
    {id: 'rejected', name: 'Модерация отклонена'}];

const DriversPage = ({status, title}) => {

    const {user} = useSelector(state => state);

    const [total, setTotal] = useState(0);
    const [drivers, setDrivers] = useState();
    const [selectedDriver, setSelectedDriver] = useState();
    const [activeTab, setActiveTab] = useState('UNCONFIRMED_DRIVER');
    const [stateList, setStateList] = useState(DRIVER_STATUSES.filter(x => x.id !== 'confirmed'));

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();
    const [deleteId, setDeleteId] = useState();
    const [role, setRole] = useState();
    const [isCreateDriver, setIsCreateDriver] = useState(false);
    const [state, setState] = useState('');
    const [fio, setFio] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    if (response.data) setRole(response.data.code)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    useEffect(() => {
        getDriver(state, fio, phoneNumber);
    }, [status, currentPage]);

    const getDriver = (status, fio, phoneNumber) => {
        setLoading(true);
        User.getUsers(status, "driver", currentPage, limit, fio, phoneNumber, "", "")
            .then(response => {
                setLoading(false);
                setTotal(response.data.total);
                setDrivers(response.data.rows);
                setTotalPages(Math.ceil(response.data.total / limit));

            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const selectedDriverItem = (item) => {
        User.getDriverById(item.id)
            .then((response) => {
                setSelectedDriver(response.data.user);
            })
            .catch((error) => {
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    const clearFilter = () => {
        setState('');
        setFio('');
        setPhoneNumber('');
        getDriver('', '', '');
    }

    const getFilter = () => {
        getDriver(state, fio, phoneNumber);
    }

    const handleSelect = (tab) => {
        if (tab === 'CONFIRMED_DRIVER') {
            const filter = DRIVER_STATUSES.filter(x => x.id === 'confirmed');
            setStateList(filter);
            setState('confirmed');
            setCurrentPage(1);
            getDriver('confirmed', fio, phoneNumber);
        } else {
            const filter = DRIVER_STATUSES.filter(x => x.id !== 'confirmed');
            setStateList(filter);
            setState('');
            setCurrentPage(1);
            getDriver('', fio, phoneNumber);
        }
        setActiveTab(tab);
    }

    const handleClose = () => {
        setSelectedDriver();
        getDriver(state, fio, phoneNumber);
    }

    const downloadOrderExport = () => {
        setLoading(true);
        Export.exportDrivers(state, "driver", fio, phoneNumber, '', '')
            .then((response) => {
                console.log(response);
                setLoading(false);
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });
    }

    const onCloseDeleteModal = () => {
        setDeleteId(null);
        getDriver(state, fio, phoneNumber);
    }

    return (
        <>
            <div style={{textAlign: 'left', display: 'flex', justifyContent: 'space-between'}}>
                <span style={{fontWeight: '700', fontSize: '24px'}}>{title}</span>
                <span style={{fontWeight: '500'}}>Итого: {total}</span>
            </div>
            <div style={{marginTop: '20px'}}>
                <div className="tabs">
                    <Button onClick={() => handleSelect('UNCONFIRMED_DRIVER')} size="sm"
                            className={activeTab === 'UNCONFIRMED_DRIVER' ? 'active' : ''}>
                        Потенциальные Водители
                    </Button>
                    <Button onClick={() => handleSelect('CONFIRMED_DRIVER')} size="sm"
                            className={activeTab === 'CONFIRMED_DRIVER' ? 'active' : ''}>
                        Верифицированные
                    </Button>
                </div>
                <div style={{marginBottom: '20px'}}>
                    <div style={{textAlign: 'left', fontWeight: '600', fontSize: '18px', marginTop: '10px'}}>
                        Фильтр водители
                    </div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: '100%', display: 'flex'}}>
                            <Form.Group style={{marginRight: '20px'}}>
                                <Form.Label style={{
                                    color: 'rgb(163, 172, 182)',
                                    fontSize: '12px',
                                    marginBottom: '5px'
                                }}>Статус</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={state}
                                    onChange={e => setState(e.target.value)}
                                    style={{
                                        fontSize: '14px',
                                        border: 'none',
                                        boxShadow: '0 2px 4px -2px rgb(228, 228, 228)',
                                        width: '200px'
                                    }}>
                                    {stateList.map(item => {
                                        return (
                                            <option value={item.id}>{item.name}</option>
                                        );
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group style={{marginRight: '20px'}}>
                                <Form.Label
                                    style={{
                                        color: 'rgb(163, 172, 182)',
                                        fontSize: '12px',
                                        marginBottom: '5px'
                                    }}>
                                    ФИО водителя
                                </Form.Label>
                                <Form.Control value={fio} onChange={(event) => setFio(event.target.value)}
                                              style={{fontSize: '14px', width: '200px'}}
                                              placeholder='ФИО водителя'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label
                                    style={{
                                        color: 'rgb(163, 172, 182)',
                                        fontSize: '12px',
                                        marginBottom: '5px'
                                    }}>
                                    Номер телефона
                                </Form.Label>
                                <Form.Control value={phoneNumber}
                                              onChange={(event) => setPhoneNumber(event.target.value)}
                                              style={{fontSize: '14px', width: '200px'}}
                                              placeholder='Номер телефона'/>
                            </Form.Group>
                        </div>
                        <div className="btn-groups">
                            <Button variant="primary" onClick={getFilter} className="btn btn-filter">
                                Применить фильтр
                            </Button>
                            <Button variant="primary" onClick={clearFilter} className='btn btn-clear-filter'>
                                Очистить фильтр
                            </Button>
                            <Button variant="primary" style={{marginLeft: '20px'}}
                                    onClick={() => setIsCreateDriver(true)} className='btn btn-add'>
                                Добавить водителя
                            </Button>
                            <Button variant="primary" onClick={downloadOrderExport} className="btn btn-filter">
                                Выгрузить в Excel
                            </Button>
                        </div>
                    </div>
                </div>
                <Table bordered style={{fontSize: '14px', textAlign: 'left', backgroundColor: '#ffffff'}}>
                    <thead style={{textAlign: 'center'}}>
                    <tr style={{backgroundColor: '#ededed'}}>
                        <th>Дата регистрации</th>
                        <th>ФИО водителя</th>
                        <th>Номер телефона</th>
                        <th>Тип транспорта</th>
                        <th>Экспедиторская водитель</th>
                        <th>Статус</th>
                        {activeTab === 'CONFIRMED_DRIVER' && <th>Количество завершенных заказов</th>}
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {(drivers && drivers.length > 0) && (
                        drivers.map(item => {
                            return <DriversItem item={item} role={role}
                                                activeTab={activeTab}
                                                onSelected={selectedDriverItem}
                                                onDelete={(event) => setDeleteId(event)}/>;
                        }))
                    }
                    </tbody>
                </Table>
                {loading && <Loader/>}
            </div>
            {drivers && <Pagination
                current={currentPage}
                total={totalPages}
                onPageChange={page => handlePageChange(page)}
            />}
            {selectedDriver &&
                <DriverInfoModal selectedDriver={selectedDriver} onClose={handleClose}
                                 getDriver={() => getDriver()}/>}
            {isCreateDriver && <DriverCreateModal onClose={() => setIsCreateDriver(false)}/>}
            {deleteId && <DeleteModal user={user}
                                      deleteId={deleteId}
                                      onClose={onCloseDeleteModal}/>
            }
        </>

    );
}

export default DriversPage;
