import { createContext, useContext, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import {useNavigate} from "react-router-dom";

const SignalRContext = createContext();

export function SignalRProvider({ children }) {
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        const connect = new signalR.HubConnectionBuilder()
            .withUrl("https://service.biny.co/chat-hub")//
            .withAutomaticReconnect()
            .build();

        setConnection(connect);
    }, []);

    return (
        <SignalRContext.Provider value={connection}>
            {children}
        </SignalRContext.Provider>
    );
}

export function useSignalR() {
    return useContext(SignalRContext);
}
