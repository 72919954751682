import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import {File, User, Invoice} from '../../../services';
import {useSelector} from "react-redux";
import Loader from "../../../utils/loader";
import SysDocument from "../../../services/SysDocument";

const ClientPaymentInfoModal = ({item, onClose, getInvoices}) => {

    const {user} = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const {invoice} = item;
    const [invoiceSuccess, setInvoiceSuccess] = useState(false);
    const [clientUser, setClientUser] = useState();

    useEffect(() => {

        User.getById(invoice.companyId)
            .then(response => {
                 setClientUser(response.data);
            })
            .catch(error => {
                console.log(error)
            });

    }, [item]);

    const confirmInvoice = () => {
        setLoading(true);
        Invoice.updateStatus(invoice.id, user.name)
            .then(response => {
                setLoading(false);
                sendPush();
                getInvoices();
                setInvoiceSuccess(true);
            })
            .catch(error => {
                setLoading(false);
                sendPush();
                console.log(error);
            });
    }

    const sendPush = () => {
        Invoice.sendPush(invoice.orderId)
            .then(response => {
            })
            .catch(error => {
                console.log(error);
            });
    }

    const downloadPaymentInvoice = () => {
        setLoading(true);
        SysDocument.paymentInvoice(item.invoice.orderId).then((response) => {

            setLoading(false);
            const file = new Blob([response.data], {
                type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        }).catch(error => {
            alert("Произошла ошибка, попробуйте позже");
        });
    }


    return (
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '640px',
                margin: '24px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff'}}>Информация о платеже</div>
                    <div onClick={onClose}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    <div style={{padding: '16px'}}>
                        {
                            invoice.status === "awaitingpayment"
                                ?
                                (
                                    !invoiceSuccess
                                        ? (<>
                                            {loading && <Loader/>}
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundColor: '#fafafa',
                                                padding: '12px 16px',
                                                borderRadius: '6px'
                                            }}>
                                                <div style={{flex: '1'}}>
                                                    <div style={{color: '#A3ACB6', fontSize: '12px'}}>Статус платежа</div>
                                                    <div style={{fontSize: '15px', color: '#f0ad4e', fontWeight: '700'}}>Не
                                                        оплачен
                                                    </div>
                                                </div>
                                                <div style={{marginLeft: '12px', display: 'flex', gap: '5px'}}>
                                                    <Button onClick={confirmInvoice} variant="primary" size="sm"
                                                            disabled={loading}>
                                                        Подтвердить оплату
                                                    </Button>
                                                    <Button variant="primary" onClick={downloadPaymentInvoice}>
                                                        Печатная форма
                                                    </Button>
                                                </div>
                                            </div>
                                        </>)
                                        :
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(19, 182, 94, 0.1)',
                                            padding: '12px 16px',
                                            borderRadius: '6px'
                                        }}>
                                            <div style={{flex: '1'}}>
                                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Статус заказа</div>
                                                <div style={{
                                                    fontSize: '15px',
                                                    color: '#13B65E',
                                                    fontWeight: '700'
                                                }}>Оплачен
                                                </div>
                                                <Button variant="primary" onClick={downloadPaymentInvoice}>
                                                    Печатная форма
                                                </Button>
                                            </div>
                                        </div>
                                )
                                :
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(19, 182, 94, 0.1)',
                                    padding: '12px 16px',
                                    borderRadius: '6px'
                                }}>
                                    <div style={{flex: '1', display: "flex", justifyContent: 'space-between'}}>
                                        <div>
                                            <div style={{color: '#A3ACB6', fontSize: '12px'}}>Статус заказа</div>
                                            <div style={{fontSize: '15px', color: '#13B65E', fontWeight: '700'}}>Оплачен
                                            </div>
                                        </div>
                                        <Button variant="primary" onClick={downloadPaymentInvoice}>
                                            Печатная форма
                                        </Button>
                                    </div>
                                </div>
                        }

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: '1px solid rgba(190, 190, 182, 0.3)',
                            paddingBottom: '10px',
                            marginTop: '24px'
                        }}>
                            <div style={{
                                fontSize: '15px',
                                color: '#A3ACB6',
                                lineHeight: '1.3',
                                fontWeight: '600'
                            }}>Основные данные
                            </div>
                        </div>

                        <div className='row' style={{marginTop: '16px'}}>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Номер оплаты</div>
                                <div style={{fontSize: '14px', marginTop: '2px'}}>{invoice.number}</div>
                            </div>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Дата создания</div>
                                <div style={{
                                    fontSize: '14px',
                                    marginTop: '2px'
                                }}>{moment(invoice.createdAt).format('DD.MM.YYYY')}</div>
                            </div>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Номер договора</div>
                                <div
                                    style={{
                                        fontSize: '14px',
                                        marginTop: '2px'
                                    }}>{'Договор № ' + invoice.orderNumber}</div>
                            </div>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Сумма к опалте</div>
                                <div style={{fontSize: '14px', marginTop: '2px'}}>{invoice.price + ' тг'}</div>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: '1px solid rgba(190, 190, 182, 0.3)',
                            paddingBottom: '10px',
                            marginTop: '24px'
                        }}>
                            <div style={{
                                fontSize: '15px',
                                color: '#A3ACB6',
                                lineHeight: '1.3',
                                fontWeight: '600'
                            }}>Покупатель
                            </div>
                        </div>

                        {clientUser ? (<div className='row' style={{marginTop: '16px'}}>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Название компании</div>
                                <div style={{fontSize: '14px', marginTop: '2px'}}>{clientUser.companyName}</div>
                            </div>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>БИН</div>
                                <div style={{
                                    fontSize: '14px',
                                    marginTop: '2px'
                                }}>{clientUser.bin}</div>
                            </div>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Контактный телефон</div>
                                <div style={{fontSize: '14px', marginTop: '2px'}}>{clientUser.phoneNumber}</div>
                            </div>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Email</div>
                                <div style={{fontSize: '14px', marginTop: '2px'}}>{clientUser.email}</div>
                            </div>
                        </div>) : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientPaymentInfoModal;
