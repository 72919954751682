import { get, post, put, destroy } from './config';

const Driver = {

    registerDriverFastTrack: (data) =>
        post('v1/users/driver/register/fast-track', data),
    addDriverVehicle: (data) =>
        post('v1/users/driver/vehicle', data)
}

export default Driver;
