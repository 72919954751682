import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import moment from "moment";
import {File, User} from "../../services";
import Table from 'react-bootstrap/Table'
import Form from "react-bootstrap/Form";
import Alert from 'react-bootstrap/Alert';
import {numberToFinanceFormat, phoneNumberFormat} from '../../utils/index';
import user from "../../services/User";
import {useSelector} from "react-redux";
import LoadingClientPhotos from "./Components/LoadingClientPhotos";
import ExpeditorDrivers from "./Components/ExpeditorDrivers";
import Balance from "./Components/Balance";
import ClientEmployees from "./Components/ClientEmployees";
import Loader from "../../utils/loader";
import {toast} from "react-toastify";

const ClientInfoModal = ({activeTab, item, onClose, getClient, role}) => {

    const {user} = useSelector(state => state);

    const [moderationSuccess, setModerationSuccess] = useState(false);
    const [moderationFailed, setModerationFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showRejectReason, setShowRejectReason] = useState(false);
    const [rejetcReasson, setRejectReason] = useState();
    const [errorTitle, setErrorTitle] = useState();
    const [balanceList, setBalanceList] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [employees, setEmployees] = useState([]);

    const sendRejectReason = () => {
        if (rejetcReasson && rejetcReasson !== "") {
            User.reject(item.id, {comment: rejetcReasson, user: user.name, userType: activeTab === 'client' ? 0 : 1})
                .then((response) => {
                    setModerationFailed(true);
                    getClient();
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setErrorTitle("Необходимо заполнить причину отказа");
        }
    };

    const confirmUser = () => {
        let errorList = [];
        if (!item.name || item.name === '') {
            errorList.push("Название организации");
        }

        if (!item.bin || item.bin === '') {
            errorList.push("БИН");
        }

        if (errorList.length > 0) {
            toast.error(`Необходимо заполнить ${errorList.join('; ')}`);
            return;
        }

        const body = {user: user.name, userType: activeTab === 'client' ? 0 : 1};
        User.confirm(item.id, body)
            .then((response) => {
                console.log(response);
                setModerationSuccess(true);
                getClient();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getBalance();
        getDrivers();
        getEmployees();
    }, [item.id]);

    const getUser = () => {
        User.getById(item.id).then((response) => {
            if (response.status === 200) {
                item.balance = response.data.balance;
                item.totalPaid = response.data.totalPaid;
                item.moneyOnHold = response.data.moneyOnHold;
            }
        }).catch(error => {
            console.log('error', error);
        });
    }

    const getBalance = () => {
        User.getBalanceList(item.id).then((response) => {
            setBalanceList(response.data.rows);
        }).catch(error => {
            console.log('balance list', error);
        })
    }

    const getDrivers = () => {
        User.getDriversByExpeditorId(item.id).then(response => {
            if (response.data) {
                setDrivers(response.data);
            }
        }).catch(err => {
            console.log('err', err);
        })
    }

    const getEmployees = () => {
        User.getEmployees(item.id, 1, 100).then(response => {
            if (response.data) {
                setEmployees(response.data.rows);
            }
        }).catch(err => {
            console.log('err', err);
        })
    }
    const renderModerationPannel = () => {
        if (item.state === "Moderation") {
            if (moderationSuccess) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "rgba(19, 182, 94, 0.1)",
                            padding: "12px 16px",
                            borderRadius: "6px",
                        }}
                    >
                        <div style={{flex: "1"}}>
                            <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                Статус клиента
                            </div>
                            <div
                                style={{
                                    fontSize: "15px",
                                    color: "#13B65E",
                                    fontWeight: "700",
                                }}
                            >
                                Модерация пройдена!
                            </div>
                        </div>
                    </div>
                );
            } else if (moderationFailed) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "rgba(220, 53, 69, 0.1)",
                            padding: "12px 16px",
                            borderRadius: "6px",
                        }}>
                        <div style={{flex: "1"}}>
                            <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                Статус клиента
                            </div>
                            <div
                                style={{
                                    fontSize: "15px",
                                    color: "#dc3545",
                                    fontWeight: "700",
                                }}>
                                Заяка отклонена!
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div
                        style={{
                            backgroundColor: "#fafafa",
                            padding: "12px 16px",
                            borderRadius: "6px",
                        }}
                    >
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div style={{flex: "1"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Статус клиента
                                </div>
                                <div
                                    style={{
                                        fontSize: "15px",
                                        color: "#f0ad4e",
                                        fontWeight: "700",
                                    }}
                                >
                                    На модерации
                                </div>
                            </div>
                            <div style={{marginLeft: "12px"}}>
                                <Button
                                    onClick={() =>
                                        showRejectReason ? null : setShowRejectReason(true)
                                    }
                                    variant="danger"
                                    size="sm"
                                    style={{opacity: `${showRejectReason ? "0.3" : "1"}`}}
                                >
                                    Отклонить
                                </Button>
                            </div>
                            <div style={{marginLeft: "12px"}}>
                                <Button
                                    onClick={showRejectReason ? null : confirmUser}
                                    variant="success"
                                    size="sm"
                                    style={{opacity: `${showRejectReason ? "0.3" : "1"}`}}
                                >
                                    Подтвердить
                                </Button>
                            </div>
                        </div>

                        {showRejectReason ? (
                            <div style={{marginTop: "12px", paddingBottom: "4px"}}>
                                <Form.Group>
                                    <Form.Label style={{color: "#A3ACB6", fontSize: "12px"}}>
                                        Причина отказа
                                    </Form.Label>
                                    <Form.Control
                                        value={rejetcReasson}
                                        onChange={(event) => setRejectReason(event.target.value)}
                                        as="textarea"
                                        placeholder="Введите причину отказа"
                                        rows={2}
                                        style={{fontSize: "14px"}}
                                    />
                                </Form.Group>
                                {errorTitle ? (
                                    <div
                                        style={{
                                            marginTop: "6px",
                                            color: "#dc3545",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {errorTitle}
                                    </div>
                                ) : null}
                                <div style={{textAlign: "right", marginTop: "12px"}}>
                                    <Button
                                        onClick={() => {
                                            setShowRejectReason(false);
                                            setRejectReason();
                                        }}
                                        variant="secondary"
                                        size="sm"
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        onClick={sendRejectReason}
                                        variant="primary"
                                        size="sm"
                                        style={{marginLeft: "12px"}}
                                    >
                                        Отправить отказ
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                );
            }
        } else return null;
    };

    const handleFillBalance = () => {
        getBalance();
        getUser();
    }

    return (
        <div className="modal-overlay">
            <div
                style={{
                    backgroundColor: "#ffffff",
                    maxHeight: "calc(100vh - 50px)",
                    width: "700px",
                    margin: "24px auto",
                    borderRadius: "6px",
                }}>
                <div style={{
                    padding: "14px 24px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #eeeeee",
                    borderTopLeftRadius: "6px",
                    borderTopRightRadius: "6px",
                }}>
                    {
                        activeTab === 'client' ?
                            <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                                Информация о клиенте
                            </div>
                            :
                            <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                                Транспортная компания
                            </div>
                    }
                    <div style={{cursor: 'pointer'}} onClick={onClose}>X</div>
                </div>
                <div
                    style={{
                        overflowY: "scroll",
                        maxHeight: "calc(100vh - 103px)",
                        textAlign: "left",
                    }}
                >
                    <div style={{padding: "16px"}}>
                        {renderModerationPannel()}
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                            paddingBottom: "10px",
                            marginTop: "24px",
                        }}>
                            <div
                                style={{
                                    fontSize: "15px",
                                    color: "#A3ACB6",
                                    lineHeight: "1.3",
                                    fontWeight: "600",
                                }}>
                                Основные данные
                            </div>
                        </div>
                        <div className="row" style={{marginTop: "16px"}}>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Название компании
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {item.companyName}
                                </div>
                            </div>
                            {item.companyId !== null && (<div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    ФИО(сотрудника)
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {item.name} {item.surname}
                                </div>
                            </div>)}
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>БИН</div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {item.bin}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Контактный телефон
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {phoneNumberFormat(item.phoneNumber)}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>Email</div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {item.email}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Дата регистрации
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {moment(item.createdAt).format("DD.MM.YYYY")}
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{fontSize: "14px", marginTop: "2px", display: 'flex'}}>
                                    <input type="checkbox" checked={item.isNDS} readOnly
                                           style={{height: '23px'}}/><span>Плательщик НДС</span>
                                </div>
                            </div>
                            <div className="col-4" style={{paddingBottom: "12px"}}>
                                <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                                    Адрес
                                </div>
                                <div style={{fontSize: "14px", marginTop: "2px"}}>
                                    {item.address}
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                            paddingBottom: "10px",
                            marginTop: "24px",
                        }}>
                            <div style={{
                                fontSize: "15px",
                                color: "#A3ACB6",
                                lineHeight: "1.3",
                                fontWeight: "600",
                            }}>
                                Документы
                            </div>
                        </div>

                        <div className="row" style={{marginTop: "24px"}}>
                            <LoadingClientPhotos item={item} role={role}/>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                            paddingBottom: "10px",
                            marginTop: "24px",
                        }}>
                            <div style={{
                                fontSize: "15px",
                                color: "#A3ACB6",
                                lineHeight: "1.3",
                                fontWeight: "600",
                            }}>
                                Личный счет
                            </div>
                        </div>
                        {activeTab !== 'client' && <ExpeditorDrivers drivers={drivers}/>}
                        {item.companyId === null && <ClientEmployees employees={employees}/>}
                        {activeTab === 'client' &&
                            <Balance item={item} balanceList={balanceList} onSuccess={handleFillBalance}/>}
                        {loading === true && <Loader/>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientInfoModal;
