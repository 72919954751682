import React, {useEffect, useState} from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";

const ListItemComponent = ({item, role, onDelete, onDownload}) => {

    return (
        <tr>
            <td style={{width: '170px'}}>
                {moment(item.createdAt).format("DD.MM.YYYY")}
            </td>
            <td style={{width: '180px'}}>
                {item.orderNumber}
            </td>
            <td>
                {item.requestData}
            </td>
            <td>
                {item.responseData}
            </td>
            <td style={{width: '70px', textAlign: 'center'}}>
                {item.isSuccess && <input type="checkbox" checked readOnly title="success"/>}
                {!item.isSuccess && <input type="checkbox" disabled readOnly title="failed"/>}
            </td>
            <td style={{width: '100px'}}>
                <div className='action'>
                    <img height="24px" style={{color:'#000'}} title="Скачать бордеро" src="/images/download-black.svg" alt="download"
                         onClick={() => onDownload(item)}/>
                    {role == "superAdmin" &&
                        <img height="22px" src="/icons/delete.png" onClick={(e) => {
                            e.preventDefault();
                            onDelete(item.id);
                        }}/>
                    }

                </div>
            </td>
        </tr>
    )
}
export default ListItemComponent;
