import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {numberToFinanceFormat} from '../../../utils';
import {Driver, User, Payment, SystemRoles} from '../../../services';
import Button from 'react-bootstrap/Button';
import {useSelector} from "react-redux";

const DriverPaymentInfoModal = ({item, onClose}) => {
    const {user} = useSelector(state => state);
    const [driver, setDriver] = useState();
    const [bankAccount, setBankAccount] = useState();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [isPayFromFreedom, setIsPayFromFreedom] = useState(false);

    const [role, setRole] = useState();

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    if (response.data) {
                        setRole(response.data);
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    useEffect(() => {
        User.getById(item.driverId)
            .then(response => {
                setDriver(response.data);
            })
            .catch(error => {
                console.log(error);
            });

    }, [item]);

    const confirmPayment = () => {
        Payment.updateStatus(item.id, !isPayFromFreedom)
            .then(response => {
                console.log('DRIVER PAYMENT UPDATE status', response);
                setPaymentSuccess(true);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleCheckboxChange = (event) => {
        setIsPayFromFreedom(event.target.checked);
    }

    // {role && role.permissions.includes('')
    return (
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '640px',
                margin: '24px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff'}}>Информация о платеже</div>
                    <div style={{cursor: 'pointer'}} onClick={() => onClose(paymentSuccess)}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    <div style={{padding: '16px'}}>

                        {
                            item.status === "awaitingpayment"
                                ?
                                (
                                    !paymentSuccess
                                        ?
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#fafafa',
                                            padding: '12px 16px',
                                            borderRadius: '6px'
                                        }}>
                                            <div style={{flex: '1'}}>
                                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Статус платежа</div>
                                                <div style={{fontSize: '15px', color: '#f0ad4e', fontWeight: '700'}}>
                                                    Не оплачен
                                                </div>
                                            </div>
                                            {(role && role.permissions.includes('drivers_payments_confirm')) &&
                                                <div style={{marginLeft: '12px'}}>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                        marginBottom: '5px'
                                                    }}>
                                                        <input type="checkbox" checked={isPayFromFreedom}
                                                               style={{
                                                                   width: '16px',
                                                                   height: '16px',
                                                                   marginLeft: '4px'
                                                               }}
                                                               onChange={(event) => handleCheckboxChange(event)}/>
                                                        <span style={{cursor: "pointer"}}
                                                              onClick={() => setIsPayFromFreedom(!isPayFromFreedom)}>Вывод денег через Freedom Pay</span>
                                                    </div>
                                                    <Button onClick={confirmPayment} className="payment-active"
                                                            size="sm">
                                                        Подтвердить оплату
                                                    </Button>
                                                </div>}
                                        </div>
                                        :
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(19, 182, 94, 0.1)',
                                            padding: '12px 16px',
                                            borderRadius: '6px'
                                        }}>
                                            <div style={{flex: '1'}}>
                                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Статус заказа</div>
                                                <div style={{
                                                    fontSize: '15px',
                                                    color: '#13B65E',
                                                    fontWeight: '700'
                                                }}>Оплачен
                                                </div>
                                            </div>
                                        </div>
                                ) :
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(19, 182, 94, 0.1)',
                                    padding: '12px 16px',
                                    borderRadius: '6px'
                                }}>
                                    <div style={{flex: '1'}}>
                                        <div style={{color: '#A3ACB6', fontSize: '12px'}}>Статус заказа</div>
                                        <div style={{fontSize: '15px', color: '#13B65E', fontWeight: '700'}}>Оплачен
                                        </div>
                                    </div>
                                </div>
                        }

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: '1px solid rgba(190, 190, 182, 0.3)',
                            paddingBottom: '10px',
                            marginTop: '24px'
                        }}>
                            <div style={{
                                fontSize: '15px',
                                color: '#A3ACB6',
                                lineHeight: '1.3',
                                fontWeight: '600'
                            }}>Основные данные
                            </div>
                        </div>

                        <div className='row' style={{marginTop: '16px'}}>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>ID оплаты</div>
                                <div style={{fontSize: '14px', marginTop: '2px'}}>{item.number}</div>
                            </div>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Дата создания</div>
                                <div style={{
                                    fontSize: '14px',
                                    marginTop: '2px'
                                }}>{moment(item.createdAt).format('DD.MM.YYYY')}</div>
                            </div>
                            <div className='col-4' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>Сумма к опалте</div>
                                <div style={{
                                    fontSize: '14px',
                                    marginTop: '2px'
                                }}>{numberToFinanceFormat(item.price) + ' тг'}</div>
                            </div>
                            <div className='col-6' style={{paddingBottom: '12px'}}>
                                <div style={{color: '#A3ACB6', fontSize: '12px'}}>IBAN</div>
                                <div style={{
                                    fontSize: '14px',
                                    marginTop: '2px'
                                }}>{item.iban??'-'}</div>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: '1px solid rgba(190, 190, 182, 0.3)',
                            paddingBottom: '10px',
                            marginTop: '24px'
                        }}>
                            <div style={{
                                fontSize: '15px',
                                color: '#A3ACB6',
                                lineHeight: '1.3',
                                fontWeight: '600'
                            }}>Водитель
                            </div>
                        </div>
                        {
                            driver ?
                                <div className='row' style={{marginTop: '16px'}}>
                                    <div className='col-4' style={{paddingBottom: '12px'}}>
                                        <div style={{color: '#A3ACB6', fontSize: '12px'}}>Фамилия</div>
                                        <div style={{fontSize: '14px', marginTop: '2px'}}>{driver.name}</div>
                                    </div>
                                    <div className='col-4' style={{paddingBottom: '12px'}}>
                                        <div style={{color: '#A3ACB6', fontSize: '12px'}}>Имя</div>
                                        <div style={{fontSize: '14px', marginTop: '2px'}}>{driver.surname}</div>
                                    </div>
                                    <div className='col-4' style={{paddingBottom: '12px'}}>
                                        <div style={{color: '#A3ACB6', fontSize: '12px'}}>Отчество</div>
                                        <div style={{fontSize: '14px', marginTop: '2px'}}>{driver.patronymic}</div>
                                    </div>
                                    <div className='col-4' style={{paddingBottom: '12px'}}>
                                        <div style={{color: '#A3ACB6', fontSize: '12px'}}>Email</div>
                                        <div style={{fontSize: '14px', marginTop: '2px'}}>{driver.email}</div>
                                    </div>
                                    <div className='col-4' style={{paddingBottom: '12px'}}>
                                        <div style={{color: '#A3ACB6', fontSize: '12px'}}>Номер телефона</div>
                                        <div style={{fontSize: '14px', marginTop: '2px'}}>{driver.phoneNumber}</div>
                                    </div>
                                </div> : null
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}
export default DriverPaymentInfoModal;
