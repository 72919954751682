import React, {useEffect, useState} from 'react';
import ClientPaymentList from './client/ClientPaymentList';
import DriverPaymentList from './driver/DriverPaymentList';
import Button from 'react-bootstrap/Button';
import {useSelector} from "react-redux";
import FreedomPayPage from "./freedom-pay/FreedomPayPage";
import './paymentsPage.scss';

const PaymentsPage = () => {
    const {user} = useSelector(state => state);
    const [type, setType] = useState('clients');

    return (
        <>
            <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>Счета на оплату</div>
            <div style={{display: 'flex', marginTop: '16px'}}>
                <div style={{marginRight: '16px'}}>
                    <Button onClick={() => setType('clients')} size="sm"
                            className={type === 'clients' ? 'payment-active' : ''}>
                        Счета клиентов
                    </Button>
                </div>
                <div style={{marginRight: '16px'}}>
                    <Button onClick={() => setType('drivers')} size="sm"
                            className={type === 'drivers' ? 'payment-active' : ''}>
                        Счета водителей
                    </Button>
                </div>
                <div style={{marginRight: '16px'}}>
                    <Button onClick={() => setType('freedom-pay')} size="sm"
                            className={type === 'freedom-pay' ? 'payment-active' : ''}>
                        История транзакций
                    </Button>
                </div>
            </div>
            {
                type === 'clients' ? <ClientPaymentList/> :
                    type === 'drivers' ? <DriverPaymentList/> :
                        <FreedomPayPage/>
            }
        </>
    )
}

export default PaymentsPage
