import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Directory } from '../../services';

const CountryCreateModal = ({onClose, update}) => {

    const [name, setName] = useState();
    const [code, setCode] = useState();
    const [errorTitle, setErrorTitle] = useState();
    const [successView, setSuccessView] = useState(false);

    const onSubmit = () => {
        if(name && code) {
            setErrorTitle();
            Directory.addCountry({name, code}).then(response => {
                console.log("COUNTRY ADD", response)
                if(response.status == 200) {
                    setSuccessView(true);
                    update();
                }
                else alert('Произошла ошибка, попробуйте позже');
            })
              .catch(error => {
            console.log(error)
            })
        }else {
            setErrorTitle('Необходимо заполнить все поля')
        }
        
    }

    const renderContent = () => {
        if(successView) {
            return (
                <div style={{ textAlign: 'center', padding: '32px' }}>
                    <div>
                        <div style={{ display: 'inline-block', backgroundColor: 'rgba(19, 182, 94, 0.1)', width: '80px', height: '80px', borderRadius: '50%' }}>                                      
                            <div style={{ height: '20px', marginTop: '28px' }}>
                                <img src="/icons/tick-success.svg" height="22px" />
                            </div>
                        </div>
                    </div>

                    <div style={{ fontSize: '20px', fontWeight: '700', marginTop: '16px' }}>Категория успешно добавлена!</div>
                    <div style={{ textAlign: 'left', fontSize: '15px', marginTop: '4px', textAlign: 'center', color: '#A3ACB6', padding: '0 32px' }}>Теперь данная категория доступна при создании заказа</div>
                    <div style={{ marginTop: '20px' }}><Button onClick={onClose} size="sm" variant="primary">Перейти к списку</Button></div>
                </div>
            );
        }else {
            return (
                <div style={{ padding: '24px' }}>
                    <Form.Group className="mb-3">
                        <Form.Label style={{ color: '#A3ACB6', fontSize: '15px' }}>Название страны</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Введите название" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{ color: '#A3ACB6', fontSize: '15px' }}>Код страны</Form.Label>
                        <Form.Control type="text" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Введите код" />
                    </Form.Group>
                    { errorTitle ? <div>{errorTitle}</div> : null }
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                        <div><Button onClick={onClose} variant="secondary">Отменить</Button></div>
                        <div><Button onClick={onSubmit} variant="success">Добавить страну</Button></div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className='modal-overlay'>
            <div style={{ backgroundColor: '#ffffff', maxHeight: 'calc(100vh - 50px)', width: '500px', margin: '24px auto', borderRadius: '6px' }}>
                <div style={{ padding: '14px 24px', display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', justifyContent: 'space-between', borderBottom: '1px solid #eeeeee', borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}>
                    <div style={{ fontSize: '16px', backgroundColor: '#ffffff', fontWeight: '600' }}>Создание страны</div>
                     <div onClick={onClose}>X</div>
                </div>
                <div style={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left' }}>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default CountryCreateModal;