import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SelectModal from '../custom/select/SelectModal';
import {Order, Driver, User, File} from '../../services';
import {userReducer} from "../../store/reducers/userReducer";
import {useSelector} from "react-redux";


const ContactsModal = ({onClose}) => {
    const [showTypeSelect, setShowTypeSelect] = useState(false);
    const [types, setTypes] = useState();
    const [vehicleType, setVehicleType] = useState();
    const [addDriverSuccess, setAddDriverSuccess] = useState();

    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [patronymic, setPatronymic] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [idCardFront, setIdCardFront] = useState('');
    const [idCardBack, setIdCardBack] = useState('');
    const [carPhoto, setCarPhoto] = useState('');
    const [carCardFront, setCarCardFront] = useState('');
    const [carCardBack, setCarCardBack] = useState('');
    const [trailerCardFront, setTrailerCardFront] = useState('');
    const [trailerCardBack, setTrailerCardBack] = useState('');

    const [iin, setIin] = useState('');
    const [isIin, setIsIin] = useState(false);
    const [idNumber, setIdNumber] = useState('');
    const [isIdNumber, setIsIdNumber] = useState(false);
    const [issuedBy, setIssuedBy] = useState('');
    const [isIssuedBy, setIsIssuedBy] = useState(false);
    const [issuedDate, setIssuedDate] = useState('');
    const [isIssuedDate, setIsIssuedDate] = useState(false);
    const [carNumber, setCarNumber] = useState('');
    const [isCarNumber, setIsCarNumber] = useState(false);
    const [carModel, setCarModel] = useState('');
    const [isCarModel, setIsCarModel] = useState(false);
    const [trailerNumber, setTrailerNumber] = useState('');
    const [isTrailerNumber, setIsTrailerNumber] = useState(false);
    const [trailerModel, setTrailerModel] = useState('');
    const [isTrailerModel, setIsTrailerModel] = useState(false);

    useEffect(() => {
        Order.getTypes()
            .then(response => {
                console.log('types', response.data)
                setTypes(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const handleSend = () => {
        const data = {
            name: firstName,
            surname: surname,
            patronymic: patronymic,
            phoneNumber: phoneNumber
        }
        Driver.registerDriverFastTrack(data)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    const driverId = response.data.id;
                    console.log(driverId);
                    const formDataIdFront = new FormData();
                    formDataIdFront.append("file", idCardFront);
                    formDataIdFront.append("type", 3);
                    formDataIdFront.append("side", 0);
                    formDataIdFront.append("userId", driverId);
                    const formDataIdBack = new FormData();
                    formDataIdBack.append("file", idCardBack);
                    formDataIdBack.append("type", 3);
                    formDataIdBack.append("side", 1);
                    formDataIdBack.append("userId", driverId);
                    const formDataCarFront = new FormData();
                    formDataCarFront.append("file", carCardFront);
                    formDataCarFront.append("type", 4);
                    formDataCarFront.append("side", 0);
                    formDataCarFront.append("userId", driverId);
                    const formDataCarBack = new FormData();
                    formDataCarBack.append("file", carCardBack);
                    formDataCarBack.append("type", 4);
                    formDataCarBack.append("side", 1);
                    formDataCarBack.append("userId", driverId);
                    const formDataTrailerFront = new FormData();
                    formDataTrailerFront.append("file", trailerCardFront);
                    formDataTrailerFront.append("type", 7);
                    formDataTrailerFront.append("side", 0);
                    formDataTrailerFront.append("userId", driverId);
                    const formDataTrailerBack = new FormData();
                    formDataTrailerBack.append("file", trailerCardBack);
                    formDataTrailerBack.append("type", 7);
                    formDataTrailerBack.append("side", 1);
                    formDataTrailerBack.append("userId", driverId);
                    const formDataCarPhoto = new FormData();
                    formDataCarPhoto.append("file", carPhoto);
                    formDataCarPhoto.append("type", 5);
                    formDataCarPhoto.append("side", 0);
                    formDataCarPhoto.append("userId", driverId);
                    const formDataVehicleType = {
                        "driverId": driverId,
                        "vehicleTypeId": vehicleType.id,
                    };
                    console.log("chtotoo");
                    console.log(formDataVehicleType);
                    console.log(formDataCarFront);
                    Promise.all([
                        File.addDocDriver(formDataIdFront),
                        File.addDocDriver(formDataIdBack),
                        File.addDocDriver(formDataCarFront),
                        File.addDocDriver(formDataCarBack),
                        File.addDocDriver(formDataTrailerFront),
                        File.addDocDriver(formDataTrailerBack),
                        File.addDocDriver(formDataCarPhoto),
                        Driver.addDriverVehicle(formDataVehicleType)
                    ]).then(responses => {
                        let summary200 = true;
                        if (responses && responses.length) {
                            for (let i = 0; i < responses.lenght; i++) {
                                if (responses[i].status != 200) {
                                    summary200 = false;
                                }
                            }
                        } else {
                            summary200 = false;
                        }
                        if (summary200 === true) {
                            const dataConfirmDriver = {
                                iin: iin,
                                idNumber: idNumber,
                                issuedBy: issuedBy,
                                issuedDate: issuedDate,
                                carNumber: carNumber,
                                carModel: carModel,
                                trailerNumber: trailerNumber,
                                trailerModel: trailerModel
                            }
                            User.confirmDriver(driverId, dataConfirmDriver)
                                .then(response => {
                                    console.log(response);
                                    if (response.status === 200) {
                                        onClose();
                                        alert("Все прошло успешно");
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        } else {
                            alert('При загрузке файлов произошла ошибка');
                        }
                    }).catch(error => {
                        console.log(error);
                        alert('Произошла ошибка, попробуйте позже');
                    })
                }
            }).catch(error => {
            console.log(error);
            alert('Произошла ошибка, попробуйте позже');
        })
    }

    const renderUploadForm = (placeholder_text, getFile, setFile, id_prefix) => {
        return (
            <div style={{
                marginTop: '16px',
                border: '1px solid #ced4da',
                borderRadius: '8px',
                textAlign: 'left',
                padding: '12px 16px'
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{flex: '1'}}>
                        <div style={{fontSize: '15px'}}>{placeholder_text}</div>
                        <div style={{fontSize: '13px', color: '#A3ACB6'}}>Загрузите фото, скан документа</div>
                    </div>
                    {
                        !getFile
                            ?
                            <div>
                                <label htmlFor={`upload-photo-${id_prefix}`}>
                                    <div style={{
                                        backgroundColor: 'rgba(187, 187, 187, 0.1)',
                                        padding: '6px 12px 12px',
                                        borderRadius: '12px'
                                    }}>
                                        <div style={{height: '20px'}}>
                                            <img src="/icons/upload.svg" height="10px"/>
                                        </div>
                                    </div>
                                </label>
                                <input type="file" name="photo" id={`upload-photo-${id_prefix}`}
                                       style={{opacity: "0", position: "absolute", zIndex: "-1"}}
                                       onChange={(event) => setFile(event.target.files[0])}/>
                            </div>
                            :
                            <div>
                                <label htmlFor="upload-photo">
                                    <div style={{
                                        backgroundColor: 'rgba(19, 182, 94, 0.1)',
                                        padding: '6px 12px 12px',
                                        borderRadius: '12px'
                                    }}>
                                        <div style={{height: '20px'}}>
                                            <img src="/icons/tick-success.svg" height="10px"/>
                                        </div>
                                    </div>
                                </label>
                                <input type="file" name="photo" id={`upload-photo-${id_prefix}`}
                                       style={{opacity: "0", position: "absolute", zIndex: "-1"}}/>
                            </div>
                    }
                </div>
            </div>

        )
    }

    return <>
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '400px',
                margin: '30px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff'}}>Информация о водителе</div>
                    <div style={{cursor: 'pointer'}} onClick={onClose}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    <div style={{padding: '16px'}}>
                        <div>
                            <Form.Control value={firstName} onChange={(e) => setFirstName(e.target.value)} size="lg"
                                          type="text" placeholder="Имя"
                                          style={{fontSize: '16px', padding: '12px 16px'}}/>
                            <Form.Control value={surname} onChange={(e) => setSurname(e.target.value)} size="lg"
                                          type="text" placeholder="Фамилия"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={patronymic} onChange={(e) => setPatronymic(e.target.value)} size="lg"
                                          type="text" placeholder="Отчество"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} size="lg"
                                          type="number" placeholder="Номер телефона"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <div className='col-12'>
                                <div className="mb-2">
                                    <div onClick={() => setShowTypeSelect(true)} style={{
                                        color: `${vehicleType ? '#212529' : '#A3ACB6'}`,
                                        fontSize: '16px',
                                        padding: '12px 16px',
                                        marginTop: '16px',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.5rem'
                                    }}>
                                        {vehicleType ? vehicleType.name : 'Выберите тип транспорта'}
                                    </div>
                                </div>
                            </div>
                            {renderUploadForm("Удостоверение личности - лицевая", idCardFront, setIdCardFront, "01")}
                            {renderUploadForm("Удостоверение личности - обратная", idCardBack, setIdCardBack, "02")}
                            {renderUploadForm("Автомобиль", carPhoto, setCarPhoto, "03")}
                            {renderUploadForm("Тех. паспорт - лицевая", carCardFront, setCarCardFront, "04")}
                            {renderUploadForm("Тех. паспорт - обратная", carCardBack, setCarCardBack, "05")}
                            {renderUploadForm("Тех. паспорт прицепа - лицевая", trailerCardFront, setTrailerCardFront, "06")}
                            {renderUploadForm("Тех. паспорт прицепа - обратная", trailerCardBack, setTrailerCardBack, "07")}
                            <Form.Control value={iin} onChange={(e) => setIin(e.target.value)} size="lg"
                                          type="text" placeholder="ИИН"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={idNumber} onChange={(e) => setIdNumber(e.target.value)} size="lg"
                                          type="text" placeholder="Номер удостоверения"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={issuedBy} onChange={(e) => setIssuedBy(e.target.value)} size="lg"
                                          type="text" placeholder="Кем выдан"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={issuedDate} onChange={(e) => setIssuedDate(e.target.value)}
                                          size="lg" type="date" placeholder="Когда выдан"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={carNumber} onChange={(e) => setCarNumber(e.target.value)} size="lg"
                                          type="text" placeholder="Номер авто"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={carModel} onChange={(e) => setCarModel(e.target.value)} size="lg"
                                          type="text" placeholder="Модель авто"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={trailerNumber} onChange={(e) => setTrailerNumber(e.target.value)}
                                          size="lg" type="text" placeholder="Гос номер прицепа"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={trailerModel} onChange={(e) => setTrailerModel(e.target.value)}
                                          size="lg" type="text" placeholder="Марка, модель прицепа"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                        </div>
                        <div
                            style={{marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                            {/* <Button onClick={()=>{setIsForm(false)}} variant="danger" size="sm" style={{marginRight: '20px'}}>Отменить</Button> */}
                            {/* <Button onClick={()=>{setIsForm(false)}} variant="success" size="sm">Отправить</Button> */}
                            <Button onClick={onClose} variant="light" style={{marginRight: '20px'}}>Отменить</Button>
                            {
                                iin && idNumber && issuedBy && issuedDate && carNumber && carModel && trailerModel && trailerNumber
                                && idCardFront && idCardBack && carPhoto && carCardFront && carCardBack && trailerCardFront && trailerCardBack
                                && firstName && surname && phoneNumber && vehicleType ?
                                    <Button onClick={() => handleSend()} variant="primary">Отправить</Button>
                                    :
                                    <Button variant="primary" style={{
                                        backgroundColor: 'rgb(107 104 104)',
                                        borderColor: 'rgb(107 104 104)',
                                        cursor: 'default'
                                    }}>Отправить</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            showTypeSelect &&
            <SelectModal
                onClose={() => setShowTypeSelect(false)}
                header="Укажите тип транспорта"
                options={types}
                onSubmit={(item) => {
                    setVehicleType(item);
                    setShowTypeSelect(false)
                }}
            />
        }
    </>;
}
export default ContactsModal
