import Table from "react-bootstrap/Table";
import Pagination from "react-responsive-pagination";
import Loader from "../../../utils/loader";
import React, {useEffect, useState} from "react";
import FeedbackRequestItem from "../../feedback/Components/FeedbackRequestItem";
import StatusTableItem from "./StatusTableItem";
import {Order} from "../../../services";
import moment from "moment/moment";
import {ORDER_STATUSES, ORDER_STATUSES_KEYS} from "../../orders/Statuses";

const StatusTable = ({status,fnTotal}) => {

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();
    const [total,setTotal]=useState(0);

    const [orderNo, setOrderNo] = useState('');
    const [sender, setSender] = useState('');
    const [recipient, setRecipient] = useState('');

    const [startCreateAt, setStartCreateAt] = useState('');
    const [endCreateAt, setEndCreateAt] = useState('');

    useEffect(() => {
        loadOrder();
    }, [status, currentPage]);

    const loadOrder = () => {
        Order.getOrders(status === 'all' ? '' : status, currentPage, limit, orderNo, sender, recipient, startCreateAt ? moment(startCreateAt).format("YYYY-MM-DD") : '', endCreateAt ? moment(endCreateAt).format("YYYY-MM-DD") : '')
            .then(response => {
                if (response.status === 200) {
                    setList(response.data.rows);
                    setTotalPages(Math.ceil(response.data.total / limit));
                    setTotal(response.data.total);
                    fnTotal(response.data.total);
                }

            })
            .catch(error => {
                console.log(error);
            });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const renderList = () => {
        if (list) {
            return list.map(item => {
                return (<StatusTableItem key={item.id} item={item}/>);
            });
        }
    }

    return <>
        <Table bordered className='feedback__table'>
            <thead style={{textAlign: 'center'}}>
            <tr style={{backgroundColor: '#ededed'}}>
                <th>№ заказа</th>
                <th>Дата создания</th>
                <th>Отправитель</th>
                <th>Получатель</th>
                <th>Дата погрузки</th>
                <th>Откуда</th>
                <th>Куда</th>
                <th>Дата выгрузки</th>
                <th>Статус</th>
                <th>NetPrice</th>
            </tr>
            </thead>
            <tbody>
            {renderList()}
            </tbody>
        </Table>
        <div className='feedback__pagination'>
            {
                list && (!loading &&
                    <Pagination current={currentPage} total={totalPages}
                                onPageChange={page => handlePageChange(page)}/>)
            }
        </div>
        {loading && <Loader/>}</>
}

export default StatusTable;
