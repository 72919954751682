import {get, post, put, destroy} from './config';

const Invoice = {
    getAll: (status, page) =>
        get(`v1/invoices/all?invoices=${status}&page=${page}`),
    updateStatus: (invoiceId,user) =>
        post(`v1/invoices/update-status/${invoiceId}?username=${user}`),
    sendPush: (orderId) =>
        post(`v1/invoices/send-push/${orderId}`),
    getInvoices: (page, limit, statusFilter, contractNo, paymentPrice) =>
        get(`v1/invoices/list?invoices=&page=${page}&limit=${limit}&status=${statusFilter}&contractNo=${contractNo}&paymentPrice=${paymentPrice}`),
    deleteInvoice: (invoiceId) =>
        destroy(`v1/invoices/${invoiceId}`),
}

export default Invoice;
