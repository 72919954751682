import style from "./index.module.scss"
import React, { useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Order } from "../../../services";
import OrderInfoModal from "../../orders/OrderInfoModal";
import NotifyService from "../../../services/Notification";

export const ORDER_STATUSES = {
  "NEW_ORDER": { title: 'Новый заказ', color: '#0275d8' },
  "BOOKED_ORDER": { title: 'Забронирован водителем', color: '#f0be39' },
  "SUGGESTED_PRICE": { title: 'Водитель предложил новую цену', color: '#39f05e' },
}

const NotificationItemCard = ({ orderId, userName, notification, UpdateList }) => {
  const [selectedOrder, setSelectedOrder] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isRead, setIsRead] = useState(notification.isRead);

  const handleNotificationRead = (id, userName) => {
    if (!isRead && notification.id) {
      NotifyService.postNotificationSeen(id, userName).then(() => {
        console.log("userName", userName)
      }).catch(error => {
        console.error("Ошибка при отметке уведомления как прочитанного:", error);
      });
    }

    loadOrder();
  };

  const loadOrder = () => {
    Order.getById(orderId)
      .then(response => {
        if (response.status == 200) {
          const responseData = response.data;
          setSelectedOrder(responseData)
          setIsOpenModal(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const formattedTime = notification
    ? new Date(notification.createdAt).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })
    : '';

  const icon = notification.isRead ? <RadioButtonUncheckedIcon sx={{ color: '#C3C5CA' }} /> : <RadioButtonCheckedIcon sx={{ color: '#C5115E' }} />;

  return (
    <>
      <div className={style.notificationCard} onClick={() => handleNotificationRead(notification.id, userName)}>
        <div className={style.content_img}>
          {icon}
        </div>
        <div className={style.content_title} style={{ fontWeight: notification.isRead ? "400" : "600" }} >
          {notification.title || 'Загрузка...'}
        </div>
        <div className={style.notificationCard_destination}>
          <div className={style.destination_text} style={{ fontWeight: notification.isRead ? "400" : "600" }}>
            {notification.text}
          </div>
          <div className={style.destination_status} style={{ backgroundColor: `${ORDER_STATUSES[notification.type].color}` }}>
            {ORDER_STATUSES[notification.type].title}
          </div>
        </div>
        <div className={style.notificationCard_time}>
          <div className={style.time_img}><AccessTimeIcon sx={{ color: 'rgb(195, 197, 202)' }} /></div>
          <div className={style.time_text}>{formattedTime}</div>
        </div>
      </div >
      {isOpenModal &&
        <OrderInfoModal key='info-modal' item={selectedOrder}
          onClose={() => {
            setIsOpenModal(false)
            if (!isRead) {
              UpdateList(true);
            }
          }}
        />}
    </>
  );
};

export default NotificationItemCard;
