import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {Directory} from "../../services";

const ContactsModal = ({item, onClose}) => {

    const [phoneNumber, setPhoneNumber] = useState(item.phoneNumber);

    const handleUpdate = () => {
        item.phoneNumber = phoneNumber;
        Directory.updateContact(item).then(response => {
            if (response.status === 200) {
                onClose();
            }
        }).catch(error => {
            console.log('error', error);
        })
    }
    return <>
        <div className="modal-overlay">
            <div style={{
                backgroundColor: "#ffffff",
                maxHeight: "calc(100vh - 50px)",
                width: "640px",
                margin: "24px auto",
                borderRadius: "6px",
                padding: '24px'
            }}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div>Редактировать</div>
                    <div onClick={onClose}>X</div>
                </div>
                <div>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}></Form.Label>
                        <Form.Control type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                                      placeholder="Введите ..."/>
                    </Form.Group>
                </div>
                <div style={{display: 'flex', gap: '12px', justifyContent: 'flex-end'}}>
                    <button className="btn btn-primary" onClick={handleUpdate}>
                        Сохранить
                    </button>
                    <button className="btn btn-primary" onClick={onClose}>Закрыть</button>
                </div>
            </div>
        </div>
    </>;
}

export default ContactsModal;
