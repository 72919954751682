import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import "bootstrap/dist/css/bootstrap.css";
import {SystemRoles} from '../../services';
import './InsurancePage.scss';
import ListComponent from "./Components/ListComponent";
import SearchComponent from "./Components/SearchComponent";

const InsurancePage = ({title}) => {
    const {user} = useSelector(state => state);
    const [type, setType] = useState('list');
    const [role, setRole] = useState();

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    if (response.data) setRole(response.data.code)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    return (
        <>
            <h3 className='insurance__title'>Страховка</h3>
            <div className="insurance__tabs">
                <button className={type === 'list' ? 'btn btn-active' : 'btn'} onClick={() => setType('list')}>
                    Список
                </button>
                <button className={type === 'search' ? 'btn btn-active btn-right' : 'btn btn-right'}
                        onClick={() => setType('search')}>
                    Поиск
                </button>
            </div>
            {type === 'list' && <ListComponent role={role}/>}
            {type === 'search' && <SearchComponent/>}
        </>
    );
}

export default InsurancePage;
