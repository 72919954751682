import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useSelector} from "react-redux";
import Loader from "../../../utils/loader";
import moment from "moment";
import ClientContract from "../../../services/ClientContract";
import PriceModal from "./PriceModal";
import {Directory} from "../../../services";
import PriceList from "./PriceList";
import DeleteModal from "./DeleteModal";
import './../clientContractPage.scss';
import Select from "@mui/material/Select";
import {Autocomplete, TextField} from "@mui/material";
import {toast} from "react-toastify";

const CreateModal = ({editMode, item, role, clients, onClose}) => {
    const {user} = useSelector(state => state);
    const [name, setName] = useState(item ? item.name : '');
    const [number, setNumber] = useState(item ? item.number : '');
    const [clientId, setClientId] = useState(item ? item.clientId : '');
    const [dateSigned, setDateSigned] = useState(item ? moment(item.dateSigned).format('yyyy-MM-DD') : '');
    const [startDate, setStartDate] = useState(item ? moment(item.startDate).format('yyyy-MM-DD') : '');
    const [endDate, setEndDate] = useState(item ? moment(item.endDate).format('yyyy-MM-DD') : '');
    const [paymentTerms, setPaymentTerms] = useState(item ? item?.paymentTerms : '');
    const [sum, setSum] = useState(item ? item?.sum : '');
    const [loading, setLoading] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [isCreatePrice, setIsCreatePrice] = useState();
    const [countryList, setCountryList] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState();
    const [deleteId, setDeleteId] = useState();
    const [priceList, setPriceList] = useState([]);

    const onSubmit = () => {
        const signedDt = new Date(dateSigned);
        const startDt = new Date(startDate);
        const endDt = new Date(endDate);
        if (name, number, clientId) {
            setErrorTitle();
            if (!item) {
                setLoading(true);
                ClientContract.create({
                    user: user.username,
                    name: name,
                    number: number,
                    clientId: clientId,
                    dateSigned: signedDt,
                    startDate: startDt,
                    endDate: endDt,
                    paymentTerms: paymentTerms,
                    sum: sum
                }).then(response => {
                    setLoading(false);
                    onClose(true);
                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                });
            } else {
                setLoading(true);
                ClientContract.update(item.id, {
                    user: user.username,
                    name: name,
                    number: number,
                    clientId: clientId,
                    dateSigned: signedDt,
                    startDate: startDt,
                    endDate: endDt,
                    paymentTerms: paymentTerms,
                    sum: sum
                }).then(response => {
                    setLoading(false);
                    onClose(true);
                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                });
            }
        } else {
            setErrorTitle('Необходимо заполнить все поля')
        }
    }

    useEffect(() => {
        getCountry();
        getVehicleTypes();
        if (item)
            getPrices();
    }, [item]);

    const getCountry = () => {
        Directory.getCountries().then(response => {
            setCountryList(response.data);
        }).catch((error) => {
            console.log('error', error);
        });
    }

    const getVehicleTypes = () => {
        Directory.getTypes().then(response => {
            setVehicleTypes(response.data);
        }).catch((error) => {
            console.log('error', error);
        });
    }

    const getPrices = () => {
        ClientContract.priceList(item.id).then(response => {
            setPriceList(response.data);
        }).catch(error => {
            console.log('price error', error);
        })
    }

    const onClosePriceModal = (isSuccess) => {
        setIsCreatePrice(false);
        if (isSuccess)
            getPrices();
    }

    const onChangeClient = (value) => {
        if (value && value.id) {
            setClientId(value.id);
        } else {
            setClientId(undefined);
        }
    }

    const onDeleteYes = (id) => {
        if (id) {
            setLoading(true);
            ClientContract.deletePrice(id)
                .then((response) => {
                    setLoading(false);
                    onDeleteClose(true);
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error('Произошла ошибка, попробуйте позже');
                });
        }
    }

    const onDeleteClose= (isSuccess) => {
        setDeleteId(null)
        if (isSuccess) {
            getPrices();
        }
    }

    return (
        <div className='modal-overlay'>
            <div className='c-modal'>
                <div className='c-modal__header'>
                    <h3>
                        {item ? 'Редактировать' : 'Cоздание'}
                    </h3>
                    <span onClick={() => onClose(false)}>X</span>
                </div>
                <div className='c-modal__content'>
                    <div className='content-row'>
                        <div className="content-column">
                            <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Номер договора</Form.Label>
                                    <Form.Control type="text" value={number}
                                                  onChange={(e) => setNumber(e.target.value)}
                                                  placeholder="Введите номер договора"/>
                                </Form.Group>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Название
                                        договора</Form.Label>
                                    <Form.Control type="text" value={name}
                                                  onChange={(e) => setName(e.target.value)}
                                                  placeholder="Введите название договора"/>
                                </Form.Group>
                            </div>
                            <Form.Group className="mb-3 w-100">
                                <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Контрагент</Form.Label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={clients}
                                    value={clients.find((option) => option.id === clientId)}
                                    sx={{width: '100%'}}
                                    onChange={(event: any, newValue: any | null) => onChangeClient(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Выберите контрагент"/>}
                                />
                            </Form.Group>
                            <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Дата
                                        заключения</Form.Label>
                                    <Form.Control type="date" value={dateSigned} placeholder="Введите дата"
                                                  onChange={(e) => setDateSigned(e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Сумма договора</Form.Label>
                                    <Form.Control type="text" value={sum}
                                                  onChange={(e) => setSum(e.target.value)}
                                                  placeholder="Введите cумма договора"/>
                                </Form.Group>
                            </div>
                            <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>
                                        Дата начала действия договора
                                    </Form.Label>
                                    <Form.Control type="date" value={startDate} placeholder="Введите дата"
                                                  onChange={(e) => setStartDate(e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Дата окончания действия
                                        договора</Form.Label>
                                    <Form.Control type="date" value={endDate} placeholder="Введите дата"
                                                  onChange={(e) => setEndDate(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <Form.Group className="mb-3 w-100">
                                <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Условия оплаты</Form.Label>
                                <Form.Control type="text" value={paymentTerms}
                                              onChange={(e) => setPaymentTerms(e.target.value)}
                                              placeholder="Введите условия оплаты"/>
                            </Form.Group>

                        </div>
                    </div>

                    {errorTitle ? <div>{errorTitle}</div> : null}
                    {item && (<div style={{display: 'flex', gap: '5px', flexDirection: 'column'}}>
                        <Button style={{width: '160px'}} onClick={(event) => setIsCreatePrice(true)} variant="success">
                            Добавить цена
                        </Button>
                        {<PriceList list={priceList} onDelete={(e) => setDeleteId(e)} role={role.code}/>}
                    </div>)}
                    <div style={{display: 'flex', gap: '5px', justifyContent: 'flex-end'}}>
                        <Button onClick={onSubmit} variant="success">Сохранить</Button>
                        <Button onClick={() => onClose(false)} variant="secondary">Отменить</Button>
                    </div>
                </div>
                {loading && <Loader/>}
                {isCreatePrice &&
                    <PriceModal contractId={item.id} item={selectedPrice} onClose={onClosePriceModal}
                                countryList={countryList}
                                vehicleTypes={vehicleTypes}/>}
                {deleteId && <DeleteModal deleteId={deleteId} onYes={onDeleteYes} onClose={onDeleteClose}/>}
            </div>
        </div>
    );
}

export default CreateModal;
