import React, {useEffect, useState} from 'react';
import Table from "react-bootstrap/Table";
import {SystemUsers, SystemRoles} from '../../services';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import UserCreateModal from './UserCreateModal';
import UserResetPasswordModal from "./UserResetPasswordModal";
import Loader from '../../utils/loader';
import Pagination from "react-responsive-pagination";

const UsersPage = () => {

    const [users, setUsers] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showResetPwdModal, setShowResetPwdModal] = useState(false);
    const [rolesObj, setRolesObj] = useState();
    const [currentItem, setCurrentItem] = useState(null);
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    useEffect(() => {
        SystemRoles.getAll()
            .then(response => {
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        let obj = []

                        for (let i = 0; i < response.data.length; i++) {
                            obj[response.data[i].id] = response.data[i].name
                        }
                        setRolesObj(obj);
                    } else {
                        setRolesObj({});
                    }
                } else {
                    setRolesObj({});
                }
                setLoading(false); 
            })
            .catch(error => {
                console.log(error)
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        getUsers();
    }, [currentPage]);

    const handleEdit = (item) => {
        setCurrentItem(item);
        setShowModal(true);
    }

    const handleResetPassword = (item) => {
        setCurrentItem(item);
        setShowResetPwdModal(true);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const getUsers = () => {
        SystemUsers.list(currentPage,limit)
            .then(response => {
                console.log('System USERS ====>', response)
                setUsers(response.data.rows);
                setTotalPages(Math.ceil(response.data.total / limit));

            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false); // Set loading to false after users are fetched
              });
    }

    const renderList = () => {
        if (users) {
            const list = users.map(item => {
                return (
                    <tr>
                        <td>
                            <div style={{padding: '5px 0'}}>{item.username}</div>
                        </td>
                        <td>
                            <div style={{padding: '5px 0'}}>{item.name}</div>
                        </td>
                        <td>
                            {
                                rolesObj ? <div style={{padding: '5px 0'}}>{rolesObj[item.roleId]}</div> :
                                    <div style={{padding: '5px 0'}}><Loader /></div>
                            }
                        </td>
                        <td>
                            {item.state==='UnActive'?'Неактивно':'Активно'}
                        </td>
                        <td>
                            <div style={{padding: '5px 0'}}>{moment(item.createdAt).format('DD.MM.YYYY')}</div>
                        </td>
                        <td>
                            <div style={{
                                padding: '5px 0px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent:'center',
                                gap:'15px'
                            }}>
                                <img src="/icons/edit.svg" title="Редактировать" onClick={() => {
                                    handleEdit(item)
                                }}/>

                                <img style={{width: '24px', height: '24px'}} src="/icons/reset-pwd.png"
                                     title="Cбросить пароль" onClick={() => {
                                    handleResetPassword(item)
                                }}/>
                            </div>
                        </td>
                    </tr>
                );
            });

            return list;
        } 
        else {
            return <Loader />
        }

    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>Пользователи</div>
                <div>
                    <Button onClick={() => {
                        setCurrentItem(null);
                        setShowModal(true);
                    }} size="sm" variant="success">
                        Добавить пользователя
                    </Button>
                </div>
            </div>

            <div style={{marginTop: '20px'}}>
                {loading ? <Loader /> : (
                <>
                    <Table bordered style={{fontSize: '14px', textAlign: 'left', backgroundColor: '#ffffff'}}>
                        <thead style={{textAlign: 'center'}}>
                        <tr style={{backgroundColor: '#ededed'}}>
                            <th>Пользователь</th>
                            <th>Фамилия, имя</th>
                            <th>Роль</th>
                            <th>Статус</th>
                            <th>Создан</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {renderList()}
                        </tbody>
                    </Table>
                    <Pagination current={currentPage} total={totalPages}
                                onPageChange={page => handlePageChange(page)}/>
                </>
                )}
            </div>

            {
                showModal &&
                <UserCreateModal currentItem={currentItem} onClose={() => setShowModal(false)} onSuccess={() => {
                    setShowModal(false);
                    getUsers()
                }}/>
            }
            {
                showResetPwdModal &&
                <UserResetPasswordModal currentItem={currentItem} onClose={() => setShowResetPwdModal(false)} onSuccess={() => {
                    setShowResetPwdModal(false);
                    getUsers();
                }}/>
            }
        </>
    );
}

export default UsersPage
