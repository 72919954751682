import React, {useEffect, useState} from 'react';
import Table from "react-bootstrap/Table";
import {Payment} from '../../../services';
import DriverPaymentListItem from './DriverPaymentListItem';
import DriverPaymentInfoModal from './DriverPaymentInfoModal';
import Pagination from 'react-responsive-pagination';
import Loader from '../../../utils/loader';
import OrderInfoModal from "../../orders/OrderInfoModal";

const DriverPaymentList = () => {

    const [status, setStatus] = useState('all');
    const [payments, setPayments] = useState();
    const [selectedPayment, setSelectedPayment] = useState();
    const [openOrderInfoModal, setOpenOrderInfoModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    useEffect(() => {
        getList();
    }, [status, currentPage]);

    const getList = () => {
        Payment.getPayments(currentPage, limit)
            .then(response => {
                setPayments(response.data.rows);
                setTotalPages(Math.ceil(response.data.total / limit));
            })
            .catch(error => {
                console.log(error)
            })
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const handleOpenOrderModal = (order) => {
        setSelectedOrder(order);
        setOpenOrderInfoModal(true);
    }

    const renderList = () => {
        if (payments) {
            if (payments.length > 0) {
                const list = payments.map(item => {
                    return <DriverPaymentListItem key={item.id} item={item}
                                                  selectPayment={(item) => setSelectedPayment(item)}
                                                  onOpenOrderModal={handleOpenOrderModal}/>
                });

                return list;
            } else {
                return (
                    <tr>
                        <td colSpan={7}>
                            <div style={{padding: '32px', textAlign: 'center'}}>По данному статусу счетов на оплату
                                нет
                            </div>
                        </td>
                    </tr>
                )
            }

        } else {
            return (
                <Loader/>
            );
        }
    }

    const handleClose = (isSuccess) => {
        setSelectedPayment(false);

        if (isSuccess) {
            getList();
        }
    }

    return (
        <>
            <div style={{marginTop: '20px'}}>
                <Table bordered style={{fontSize: '14px', textAlign: 'left', backgroundColor: '#ffffff'}}>
                    <thead style={{textAlign: 'center'}}>
                    <tr style={{backgroundColor: '#ededed'}}>
                        <th>Номер</th>
                        <th>Дата создания</th>
                        <th>Заказ</th>
                        <th>Статус заказа</th>
                        <th>Откуда</th>
                        <th>Куда</th>
                        <th>Отправитель</th>
                        <th>Водитель</th>
                        <th>IBAN</th>
                        <th>Расстояние</th>
                        <th>Сумма к оплате</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderList()}
                    </tbody>
                </Table>
            </div>
            {
                payments &&
                <Pagination
                    current={currentPage}
                    total={totalPages}
                    onPageChange={page => handlePageChange(page)}
                />
            }
            {selectedPayment && <DriverPaymentInfoModal item={selectedPayment} onClose={handleClose}/>}
            {openOrderInfoModal &&
                <OrderInfoModal key='info-modal' item={selectedOrder}
                                onClose={()=>setOpenOrderInfoModal(false)}
                />}
        </>
    )
}

export default DriverPaymentList;
