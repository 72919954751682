import { get, post, put, destroy } from './config';

const File = {
    getByUserId: (type, userId) =>
        get(`v1/files/document/${type}/by-user-${userId}`, { responseType: 'blob' }),
    getNewDoc: (fileId) =>
        get(`v1/files/document/by-${fileId}`, { responseType: 'blob' }),
    getDocList: (userId) =>
        get(`v1/files/document/list/by-user-${userId}`),
    getDocApp: (orderId) =>
        get(`v1/documents/document-nod/${orderId} `, { responseType: 'blob' }),
    exportExcell: () =>
        get(`v1/export/driver-orders `, { responseType: 'blob' }),
    addDocDriver: (formData) =>
        post('/v1/files/admin/document', formData),
    addOrderDoc: (formData) =>
        post('/v1/files/order/document', formData),
    getOrderDocs: (orderId) =>
        get(`/v1/files/order/document/list/by-order-${orderId}`),
    getOrderDocDownload: (fileId) =>
        get(`/v1/files/order/document/download/${fileId}`,{responseType: 'blob'}),
    getOrderDocsDriverPhotos: (orderId) =>
        get(`/v1/files/order/documents/driver-photos?orderId=${orderId}`),
    deleteOrderDocs: (id) =>
        destroy(`/v1/files/order/document/${id}`)
}

export default File;
