import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import FeedbackRequest from "../../../services/FeedbackRequest";

const DeleteFeedbackRequestModal = ({activeTab, id, onClose, user}) => {

    const [errorMessage, setErrorMessage] = useState('');

    const handleDeleteYes = () => {
        if (id) {
            FeedbackRequest.delete(id)
                .then((response) => {
                    onClose(true);
                })
                .catch((error) => {
                    alert("Произошла ошибка, попробуйте позже");
                });
        } else {
            setErrorMessage('Необходимо заполнить причину удаления');
        }
    }

    return (
        <div className="modal-overlay">
            <div
                style={{
                    backgroundColor: "#ffffff",
                    height: "auto",
                    minHeight: "120px",
                    width: "450px",
                    margin: "200px auto",
                    borderRadius: "6px",
                }}>
                <div
                    style={{
                        padding: "14px 24px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                    <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                        <span style={{fontWeight: "600"}}>Вы действительно хотите удалить? </span>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={() => onClose(false)}>X</div>
                </div>

                <div style={{marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={() => onClose(false)} variant="light" style={{marginRight: '20px'}}>Нет</Button>
                    <Button onClick={handleDeleteYes} style={{marginRight: '20px'}} variant="primary">Да</Button>
                </div>

            </div>
        </div>
    );
};

export default DeleteFeedbackRequestModal;
