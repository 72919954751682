import React, {useEffect, useState} from 'react';
import Table from "react-bootstrap/Table";
import {Payment, SystemRoles} from '../../../services';
import Pagination from 'react-responsive-pagination';
import Loader from '../../../utils/loader';
import {useSelector} from "react-redux";
import Paybox from "../../../services/Paybox";
import DeleteModal from "../../common/DeleteModal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import './FreedomPayPage.scss';
import FreedomPayItem from "./FreedomPayItem";

const FreedomPayPage = ({title}) => {
    const {user} = useSelector(state => state);
    const [list, setList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    const [deleteId, setDeleteId] = useState();
    const [role, setRole] = useState();
    const [isDelete, setIsDelete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [orderNumber, setOrderNumber] = useState('');

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    if (response.data) setRole(response.data.code)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    useEffect(() => {
        getList(orderNumber);
    }, [currentPage]);

    const getList = (orderNo) => {
        setLoading(true);
        Paybox.list(currentPage, limit, orderNo).then(response => {
            setList(response.data.rows);
            setTotalPages(Math.ceil(response.data.total / limit));
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })

    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const renderList = () => {
        if (list && role) {
            return list.map(item => {
                return (<FreedomPayItem key={item.id} item={item} role={role} onDelete={handleDelete}/>);
            });
        }
    }

    const handleDelete = (id) => {
        setDeleteId(id);
        setIsDelete(true);
    }

    const onHandleFilter = () => {
        getList(orderNumber);
    }

    const onHandleClear = () => {
        setOrderNumber('');
        getList('');
    }

    const onHandleDeleteYes = (id) => {

    }
    const onHandleDeleteClose = (isSuccess) => {
        setDeleteId(undefined);
        setIsDelete(false);
        if (isSuccess) {
            getList(orderNumber);
        }
    }

    return (
        <>
            <h3 className='freedom-pay__title'></h3>
            <div className='freedom-pay__filter'>
                <Form.Group style={{marginRight: '20px', width: '200px'}}>
                    <Form.Control value={orderNumber} onChange={(event) => setOrderNumber(event.target.value)}
                                  placeholder='Номер заказа'/>
                </Form.Group>
                <Button variant="primary" onClick={onHandleFilter} className="btn btn-filter">
                    Применить фильтр
                </Button>
                <Button variant="primary" onClick={onHandleClear} className='btn btn-clear-filter'>
                    Очистить фильтр
                </Button>
            </div>
            <Table bordered className='freedom-pay__table'>
                <thead style={{textAlign: 'center'}}>
                <tr style={{backgroundColor: '#ededed'}}>
                    <th>Номер заказа</th>
                    <th>Создан</th>
                    <th>Карты</th>
                    <th>Сумма</th>
                    <th style={{width: '200px'}}>Описание</th>
                    <th style={{width: '200px'}}>Статус</th>
                </tr>
                </thead>
                <tbody>
                {renderList()}
                </tbody>
            </Table>

            <div className='freedom-pay__pagination'>
                {
                    list && (!loading &&
                        <Pagination current={currentPage} total={totalPages}
                                    onPageChange={page => handlePageChange(page)}/>)
                }
            </div>
            {isDelete && <DeleteModal deleteId={deleteId} onYes={onHandleDeleteYes} onClose={onHandleDeleteClose}/>}
            {loading && <Loader/>}
        </>
    );
}

export default FreedomPayPage;
