import React, {useEffect, useState} from "react";
import moment from "moment";

const PushListItem = ({item, role,onSelect}) => {

    const handleSelect=(item)=>{
        onSelect(item);
    }

    return (
        <tr>
            <td>
                {moment(item.updatedAt).format('DD.MM.yyyy HH:mm')}
            </td>
            <td>
                {item.number}
            </td>
            <td>
                {item.total}
            </td>
            <td>
                {item.success}
            </td>
            <td>
                {item.error}
            </td>
            <td>
                <span style={{color: 'red', textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={() => handleSelect(item)}>Просмотреть ошибки</span>
            </td>
        </tr>
    )
}
export default PushListItem;
