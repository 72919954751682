import {destroy, get, post, put} from "./config";

const ClientContract = {
    list: (page, limit) =>
        get(`v1/clientcontract/list?page=${page}&limit=${limit}`),
    getById: (id) =>
        get(`v1/clientcontract/by-${id}`),
    create: (body) =>
        post(`v1/clientcontract`, body),
    update: (id, body) =>
        put(`v1/clientcontract/${id}`, body),
    delete: (id) =>
        destroy(`v1/clientcontract/${id}`),

    createPrice: (body) =>
        post(`v1/clientcontract/price`, body),
    priceList: (contractId) =>
        get(`v1/clientcontract/price/list/${contractId}`),
    deletePrice: (id) =>
        destroy(`v1/clientcontract/price/${id}`),
}

export default ClientContract;
