import React, {useState} from "react";
import {numberToFinanceFormat} from "../../../utils";
import moment from "moment";
const OrderDetails = ({order, orderStateData, cargoDetails}) => {
    const [showOrderState, setShowOrderState] = useState(false);
    return (
        <>
            <div className="order-info">
                <span>Забор и доставка</span>
                <div className="row" style={{width: '100%'}}>
                    <div className="col-6" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Адрес забора
                        </div>
                        <div style={{
                            fontSize: "16px",
                            marginTop: "2px",
                            fontWeight: "700",
                        }}>{`${order.countryFromName}, г. ${order.cityFromName}`}</div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {order.addressFrom}
                        </div>
                    </div>
                    <div className="col-6" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Адрес доставки
                        </div>
                        <div style={{
                            fontSize: "16px",
                            marginTop: "2px",
                            fontWeight: "700",
                        }}>{`${order.countryToName}, г. ${order.cityToName}`}</div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {order.addressTo}
                        </div>
                    </div>
                </div>
            </div>
            <div className="order-info">
                <span>Детали заказа</span>
                <div className="row" style={{width: '100%'}}>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Стоимость для отправителя
                        </div>
                        <div style={{
                            fontSize: "17px",
                            marginTop: "2px",
                            fontWeight: "700",
                        }}>
                            {numberToFinanceFormat(order.price)} &#8376;
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Стоимость для водителя
                        </div>
                        <div style={{
                            fontSize: "17px",
                            marginTop: "2px",
                            fontWeight: "700",
                        }}>
                            {numberToFinanceFormat(order.netPrice)} &#8376;
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Расстояние
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {numberToFinanceFormat(order.distance)} км
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Стоимость алго
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                             {order.algoPrice? numberToFinanceFormat(order.algoPrice):'-'} &#8376;
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Дата создания
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {moment.utc(order.createdAt).format("DD.MM.YYYY HH:mm")}
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Дата и время загрузки
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {moment(order.shippingDate).format("DD.MM.YYYY")},{" "}
                            {order.shippingHourFrom}:00-{order.shippingHourTo}:00
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Дата и время выгрузки
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {moment(order.unloadingDate).format("DD.MM.YYYY")}
                            {
                                order.unloadingHourFrom && order.unloadingHourTo &&
                                <span>, {order.unloadingHourFrom}:00 - {order.unloadingHourTo}:00</span>
                            }
                        </div>
                    </div>
                    <div className="col-8" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Список дата отслежвание
                        </div>
                        {!showOrderState ? (
                            <span style={{
                                cursor: "pointer",
                                color: "blue",
                                fontSize: "12px",
                                textDecorationLine: "underline",
                            }} onClick={() => setShowOrderState(true)}>
                                        Раскрыть список
                                    </span>
                        ) : (
                            <span style={{
                                cursor: "pointer",
                                color: "blue",
                                fontSize: "12px",
                                textDecorationLine: "underline",
                            }} onClick={() => setShowOrderState(false)}>
                                        Скрыть список
                                    </span>
                        )}
                        {showOrderState ? (
                            orderStateData && orderStateData.length ? (
                                orderStateData.map((item) => {
                                    return (
                                        <div style={{
                                            fontSize: "14px",
                                            marginTop: "5px",
                                            display: "flex",
                                        }}>
                                            <div style={{width: "180px", lineHeight: "15px"}}>
                                                {item.statusName}:
                                            </div>
                                            <div style={{width: "200px"}}>
                                                {moment(item.createdAt).format("YYYY-MM-DD HH:MM:SS")}
                                            </div>
                                            <div style={{width: "200px"}}>
                                                {item.moderator}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div style={{fontSize: "12px", color: "red"}}>
                                    Нет данных
                                </div>
                            )
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Тип транспорта
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {cargoDetails?.vehicleTypeName || "-"}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderDetails;
