import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Loader from "../../utils/loader";
import {SystemRoles, User} from "../../services";
import {EditorState, ContentState, convertToRaw, convertFromRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import NotifyService from "../../services/Notification";
import {toast} from "react-toastify";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {InputLabel} from "@mui/material";


export const DRIVER_STATUSES_DATA = {
    "Moderation": {title: 'На модерации', color: '#0275d8'},
    'Finished': {title: 'Отработано (закрыто)', color: '#5cb85c'}
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const options = [
    {value: '1', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'}
]

const PDriversPage = ({status, title}) => {
    const {user} = useSelector(state => state);
    const [tema, setTema] = useState('');
    const [clients, setClients] = useState([]);
    const [expeditors, setExpeditors] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedExpeditor, setSelectedExpeditor] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState(() => {
        return EditorState.createEmpty();
    });

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    useEffect(() => {
        getClient();
        getExpeditors();
    }, []);

    const handleChangeClient = (event) => {
        const {target: {value}} = event;
        setSelectedClient(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeExpeditor = (event) => {
        const {target: {value}} = event;
        setSelectedExpeditor(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const getClient = () => {
        User.getUsers("", 'client', 1, 100, '', '', '', '')
            .then(response => {
                setClients([{label: 'все', value: 'all'}])
                response.data.rows.forEach((item) => {
                    setClients(oldValue => [...oldValue, {
                        label: `${item.name} (${item.email})`,
                        value: item.email
                    }]);
                });
            })
            .catch(error => {
                console.log(error)
            })
    }

    const getExpeditors = () => {
        User.getUsers("", 'expeditor', 1, 100, '', '', '', '')
            .then(response => {

                setExpeditors([{label: 'все', value: 'all'}])
                response.data.rows.forEach((item) => {
                    setExpeditors(oldValue => [...oldValue, {
                        label: `${item.name} (${item.email})`,
                        value: item.email
                    }]);
                });
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleSave = () => {

        // Convert editor content to HTML
        const contentState = editorState.getCurrentContent();
        const htmlContent = convertToRaw(contentState);
        const rawContentState = convertToRaw(contentState);
        const markup = draftToHtml(rawContentState);
        // Output or save the HTML content

        const _cleints = [];
        if (selectedClient.length > 0) {
            selectedClient.forEach(str => {
                const item = clients.find(x => x.label === str);
                _cleints.push(item.value);
            });
        }

        const _expeditors = [];
        if (selectedExpeditor.length > 0) {
            selectedExpeditor.forEach(str => {
                const item = expeditors.find(x => x.label === str);
                _expeditors.push(item.value);
            });
        }

        setLoading(true);
        const body = {tema: tema, content: markup, clients: _cleints, expeditors: _expeditors};
        NotifyService.sendEmail(body).then(response => {
            toast.info('Успешно отправлено');
            setTema('');
            setSelectedClient([]);
            setSelectedExpeditor([]);
            setEditorState('');
            setLoading(false);
        }).catch(err => {
            toast.info('Ошибка выходит');
            setLoading(false);
        })
    };

    return (
        <div className='container'>
            <div className='container-select'>
                <FormControl sx={{m: 1, flex: 1, marginLeft: 0}}>
                    <InputLabel id="select-client">Грузоотправитель</InputLabel>
                    <Select
                        labelId="select-client"
                        id="select-client"
                        multiple
                        value={selectedClient}
                        onChange={handleChangeClient}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        className='multiple-selesct-h'
                        placeholder='Выберите значение'
                        label='Грузоотправитель'>

                        {clients.map((item) => (
                            <MenuItem key={item.value} value={item.label} className='select-menu-item'>
                                <Checkbox checked={selectedClient.indexOf(item.label) > -1}/>
                                <ListItemText primary={item.label}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{m: 1, flex: 1, marginLeft: 0}}>
                    <InputLabel id="select-expeditor">Транспортная компания</InputLabel>
                    <Select
                        labelId="select-expeditor"
                        id="select-expeditor"
                        multiple
                        value={selectedExpeditor}
                        onChange={handleChangeExpeditor}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        className='multiple-selesct-h'
                        placeholder='Выберите значение'
                        label='Транспортная компания'>
                        {expeditors.map((item) => (
                            <MenuItem key={item.value} value={item.label} className='select-menu-item'>
                                <Checkbox checked={selectedExpeditor.indexOf(item.label) > -1}/>
                                <ListItemText primary={item.label}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='container-select'>
                <Form.Control sx={{m: 1, flex: 1, marginLeft: 0}} type="text" value={tema}
                              onChange={(e) => setTema(e.target.value)}
                              placeholder="Введите тему"/>
            </div>
            <div style={{
                backgroundColor: '#fff',
                width: '100%',
            }}>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                />
            </div>
            <div className='container-btn'>
                <button className='btn-send' onClick={handleSave}>Отправить</button>
            </div>
            {loading === true && <Loader/>}
        </div>
    );
}

export default PDriversPage;
