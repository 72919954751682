import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge';

const CLIENT_PAYMENTS_STATUS_TITLE = {
    'awaitingpayment': 'Ожидает оплаты',
    'paid': 'Оплачен'
}

const ClientPaymentListItem = ({item, selectInvoice, deleteInvoice, role}) => {
    return (
        <tr>
            <td onClick={() => selectInvoice({invoice: {...item}})}>
                <div style={{padding: '5px 0'}}>№ {item.number}</div>
            </td>
            <td onClick={() => selectInvoice({invoice: {...item}})}>
                <div style={{padding: '5px 0'}}>{moment(item.createdAt).format('DD.MM.YYYY')}</div>
            </td>
            <td onClick={() => selectInvoice({invoice: {...item}})}>
                <div style={{padding: '5px 0'}}>{item.buyerName}</div>
            </td>
            <td onClick={() => selectInvoice({invoice: {...item}})}>
                <div style={{padding: '5px 0'}}>{item.buyerPhoneNumber}</div>
            </td>
            <td onClick={() => selectInvoice({invoice: {...item}})}>
                <div style={{padding: '5px 0'}}>{'Договор № ' + item.orderNumber}</div>
            </td>
            <td onClick={() => selectInvoice({invoice: {...item}})}>
                <div style={{padding: '5px 0'}}>{item.price + ' тг'}</div>
            </td>
            <td onClick={() => selectInvoice({invoice: {...item}})}>
                <div style={{padding: '5px 0'}}><Badge bg="primary">{CLIENT_PAYMENTS_STATUS_TITLE[item.status]}</Badge>
                </div>
            </td>
            <td>
                {
                    role == "superAdmin" ?
                        <div
                            style={{
                                width: "30px",
                                textAlign: "center",
                                padding: "5px 0",
                                cursor: "pointer",
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                deleteInvoice(item.id);
                            }}
                        >
                            <img height="17px" src="/icons/delete.png"/>
                        </div>
                        : ''
                }
            </td>
        </tr>
    );
}

export default ClientPaymentListItem;
