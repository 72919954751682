import React, {useState} from "react";
import moment from "moment";
import Table from "react-bootstrap/Table";
const PriceList = ({role, list, onDelete, onUpdate}) => {

    const renderList = () => {
        if (list) {
            return list.map(item => {
                return (<tr>
                    <td>
                        {moment(item.createdAt).format('DD.MM.yyyy')}
                    </td>
                    <td>
                        {item.cityNameFrom} - {item.cityNameTo}
                    </td>
                    <td>
                        {item.price}
                    </td>
                    <td>
                        {item.vehicleTypeName}
                    </td>
                    <td>
                        {item.moderator}
                    </td>

                    <td>
                        <div className='action'>
                            {/*<img height="24px" src="/icons/edit.svg" onClick={() => onUpdate(item)}/>*/}
                            <img height="22px" src="/icons/delete.png" onClick={(e) => {
                                e.preventDefault();
                                onDelete(item.id);
                            }}/>

                        </div>
                    </td>
                </tr>);
            });
        }
    }
    return (
        <Table bordered>
            <thead style={{textAlign: 'center'}}>
            <tr style={{backgroundColor: '#ededed'}}>
                <th>Дата</th>
                <th>Направления</th>
                <th>Цена</th>
                <th>Тип</th>
                <th>Модератор</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {renderList()}
            </tbody>
        </Table>
    )
}
export default PriceList;
