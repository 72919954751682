import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const DistancePage = () => {
    
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'left', fontWeight: '700', fontSize: '24px' }}>Расстояния</div>
                <div>
                    <Button size="sm" style={{ backgroundColor: '#A3195B', border: '#A3195B' }}>Добавить расстояние</Button>
                </div>
            </div>
            <div style={{ marginTop: '16px' }}>
                <Card>
                    <Card.Body>
                        <div className='row'>
                            <div className='col-6'>
                                <div>Откуда</div>
                                <div>Страна</div>
                                <div>Город</div>
                            </div>
                            <div className='col-6'>2</div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default DistancePage;