import React, {useEffect, useState} from 'react';
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Invoice, SystemRoles} from '../../../services';
import ClientPaymentListItem from './ClientPaymentListItem';
import ClientPaymentInfoModal from './ClientPaymentInfoModal';
import Pagination from 'react-responsive-pagination';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../../../utils/loader';

const ClientPaymentList = () => {

    const STATUSES = ['', 'paid', 'awaitingPayment'];
    const STATUS_TITLE = {
        '': 'все',
        'paid': 'Оплачен',
        'awaitingPayment': 'Ожидает оплаты',
    }

    const [status, setStatus] = useState('all');
    const [invoices, setInvoices] = useState();
    const [selectedInvoice, setSelectedInvoice] = useState();
    const [deleteId, setDeleteId] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    const [statusFilter, setStatusFilter] = useState('');
    const [contractNo, setContractNo] = useState('');
    const [paymentPrice, setPaymentPrice] = useState('');
    const [role, setRole] = useState();
    const {user} = useSelector(state => state);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    console.log('ROLE: ', response.data.name)
                    if (response.data) setRole(response.data.code);
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    useEffect(() => {
        getInvoices(statusFilter, contractNo, paymentPrice)
    }, [status, currentPage]);

    const getFilter = () => {
        getInvoices(statusFilter, contractNo, paymentPrice)
    }

    const clearFilter = () => {
        setStatusFilter('');
        setContractNo('');
        setPaymentPrice('');
        getInvoices('', '', '')
    }

    const getInvoices = (statusFilter, contractNo, paymentPrice) => {
        if (statusFilter === undefined)
            statusFilter = '';
        if (contractNo === undefined)
            contractNo = '';
        if (paymentPrice === undefined)
            paymentPrice = '';

        Invoice.getInvoices(currentPage, limit, statusFilter, contractNo, paymentPrice)
        .then(response => {
          if (response.status === 200) {
            setInvoices(response.data.rows);
            setTotalPages(Math.ceil(response.data.total / limit));
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const handleDelete = (id) => {
        setDeleteId(id);
    }

    const handleDeleteYes = () => {

        Invoice.deleteInvoice(deleteId)
            .then((response) => {
                if (response.status == 200) {
                    setDeleteId(undefined);
                    getFilter();
                } else {
                    setDeleteId(undefined)
                    alert("Произошла ошибка, попробуйте позже")
                }
            })
            .catch((error) => {
                console.log(error);
                alert("Произошла ошибка, попробуйте позже")
                setDeleteId(undefined)
            });
    }

    const renderMadalDelete = () => {
        return (
            <div className="modal-overlay">
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        height: "120px",
                        width: "450px",
                        margin: "200px auto",
                        borderRadius: "6px",
                    }}
                >
                    <div
                        style={{
                            padding: "14px 24px",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#ffffff",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eeeeee",
                            borderTopLeftRadius: "6px",
                            borderTopRightRadius: "6px",
                        }}
                    >
                        <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                            <span style={{fontWeight: "600"}}>Вы действительно хотите удалить? </span>
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={() => {
                            setDeleteId(undefined)
                        }}>X
                        </div>
                    </div>
                    <div style={{marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button onClick={() => {
                            setDeleteId(undefined)
                        }} variant="light" style={{marginRight: '20px'}}>Нет</Button>
                        <Button onClick={() => {
                            handleDeleteYes()
                        }} style={{marginRight: '20px'}} variant="primary">Да</Button>
                    </div>
                </div>
            </div>
        );
    };

    const renderList = () => {
        if (invoices) {
            if (invoices.length > 0) {
                const list = invoices.map(item => {
                    return <ClientPaymentListItem key={item.id} item={item}
                                                  selectInvoice={(item) => setSelectedInvoice(item)}
                                                  deleteInvoice={(item) => handleDelete(item)} role={role}/>
                });

                return list;
            } else {
                return (
                    <tr>
                        <td colSpan={7}>
                            <div style={{padding: '32px', textAlign: 'center'}}>По данному статусу счетов на оплату
                                нет
                            </div>
                        </td>
                    </tr>
                )
            }

        }
    }

    return (
        <>
            <div style={{marginTop: '20px'}}>
                <div>
                    <div style={{textAlign: 'left', fontWeight: '600', fontSize: '18px', marginTop: '10px'}}>Фильтр
                        счета клиентов
                    </div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: '100%', display: 'flex'}}>
                            <Form.Group style={{marginRight: '20px'}}>
                                <Form.Label style={{
                                    color: 'rgb(163, 172, 182)',
                                    fontSize: '12px',
                                    marginBottom: '5px'
                                }}>Статус</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={statusFilter}
                                    onChange={e => {
                                        setStatusFilter(e.target.value)
                                    }}
                                    style={{
                                        fontSize: '14px',
                                        border: 'none',
                                        boxShadow: '0 2px 4px -2px rgb(228, 228, 228)',
                                        width: '200px'
                                    }}
                                >
                                    {STATUSES.map(item => {
                                        return (
                                            <option key={item} value={item}>{STATUS_TITLE[item]}</option>
                                        );
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group style={{marginRight: '20px'}}>
                                <Form.Label
                                    style={{color: 'rgb(163, 172, 182)', fontSize: '12px', marginBottom: '5px'}}>Номер
                                    договора</Form.Label>
                                <Form.Control value={contractNo} onChange={(event) => setContractNo(event.target.value)}
                                              style={{fontSize: '14px', width: '200px'}} placeholder='Номер договора'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label
                                    style={{color: 'rgb(163, 172, 182)', fontSize: '12px', marginBottom: '5px'}}>Сумма к
                                    оплату</Form.Label>
                                <Form.Control value={paymentPrice}
                                              onChange={(event) => setPaymentPrice(event.target.value)}
                                              style={{fontSize: '14px', width: '200px'}} placeholder='Сумма к оплату'/>
                            </Form.Group>
                        </div>
                        <div style={{marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant="primary" onClick={clearFilter} style={{
                                backgroundColor: 'rgb(167 163 165)',
                                padding: '0px 15px 6px 15px',
                                border: 'none',
                                borderRadius: '8px',
                                color: '#ffffff',
                                marginRight: '20px'
                            }}>Очистить фильтр</Button>
                            <Button variant="primary" onClick={getFilter} style={{
                                backgroundColor: '#A3195B',
                                padding: '0px 15px 6px 15px',
                                border: 'none',
                                borderRadius: '8px',
                                color: '#ffffff'
                            }}>Применить фильтр</Button>
                        </div>
                    </div>
                </div>
                {loading ? <Loader /> : (
                    <Table bordered
                        style={{fontSize: '14px', textAlign: 'left', backgroundColor: '#ffffff', marginTop: '20px'}}>
                        <thead style={{textAlign: 'center'}}>
                        <tr style={{backgroundColor: '#ededed'}}>
                            <th>Номер</th>
                            <th>Дата создания</th>
                            <th>Покупатель</th>
                            <th>Номер телефона</th>
                            <th>Номер договора</th>
                            <th>Сумма к оплату</th>
                            <th>Статус</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </Table>
                )}
            </div>
            {
                invoices &&
                (!loading &&
                    <Pagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={page => handlePageChange(page)}
                    />)
            }
            {
                selectedInvoice && <ClientPaymentInfoModal item={selectedInvoice}
                                                           onClose={() => setSelectedInvoice()}
                                                           getInvoices={() => {
                                                               setSelectedInvoice();
                                                               getInvoices();
                                                           }}/>
            }
            {
                deleteId && renderMadalDelete()
            }
        </>
    );
}

export default ClientPaymentList;
