import { setRoleDataAction } from '../actions/roleAction';

const initialState = {};

export const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case setRoleDataAction:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
