import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {useSelector} from "react-redux";
import Loader from "../../../utils/loader";
import LogService from "../../../services/LogService";
import Table from "react-bootstrap/Table";
import PushLogDetailsItem from "./PushLogDetailsItem";

const PushLogDetails = ({ item,role, onClose}) => {
    const {user} = useSelector(state => state);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (item) {
            getList();
        }
    }, [item])

    const getList = () => {
        setLoading(true);
        LogService.getDetials(item.id)
            .then(res => res.data)
            .then(data => {
                setList(data)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const renderList = () => {
        if (list && role) {
            const _list = list.map(item => {
                return (<PushLogDetailsItem key={item.id} item={item} role={role}/>);
            });
            return _list;
        }
    }

    return (
        <div className='modal-overlay'>
            <div className='news-modal'  style={{width: '800px'}}>
                <div className='news-modal__header'>
                    <h3>
                        Журнал логирования
                    </h3>
                    <span onClick={() => onClose(false)}>X</span>
                </div>
                <div className='news-modal__content'>
                    <span>Количество: {list.length}</span>
                    <Table bordered className='news-table'>
                        <thead style={{textAlign: 'center'}}>
                        <tr style={{backgroundColor: '#ededed'}}>
                            <th style={{verticalAlign:'middle'}}>Дата</th>
                            <th style={{verticalAlign:'middle'}}>Номер</th>
                            <th style={{verticalAlign:'middle'}}>Ошибка</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </Table>

                    <div className='btn-bottom'>
                        <Button onClick={() => onClose(false)} variant="secondary">Отменить</Button>
                    </div>
                </div>
                {loading && <Loader/>}
            </div>
        </div>
    );
}

export default PushLogDetails;
