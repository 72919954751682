import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import Pagination from 'react-responsive-pagination';
import {SystemRoles} from "../../services";
import Loader from "../../utils/loader";
import LogService from "../../services/LogService";
import PushListItem from "./Components/PushListItem";
import PushLogDetails from "./Components/PushLogDetails";

const PushLogPage = ({status, title}) => {
    const {user} = useSelector(state => state);
    const [logList, setLogList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [totalPages, setTotalPages] = useState();

    const [deleteId, setDeleteId] = useState();
    const [role, setRole] = useState();
    const [editMode, setEditMode] = useState(undefined);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [isDetails, setIsDetails] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    if (response.data) setRole(response.data.code)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    useEffect(() => {
        getList();
    }, [currentPage]);

    const getList = () => {
        setLoading(true);
        LogService.list(currentPage, limit).then(response => {
            setLogList(response.data.rows);
            setTotalPages(Math.ceil(response.data.total / limit));
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })

    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const renderList = () => {
        if (logList && role) {
            const list = logList.map(item => {
                return (<PushListItem key={item.id} item={item} role={role} onSelect={handleSelect}/>);
            });
            return list;
        }
    }

    const onClose = (isSuccess) => {
        setSelectedItem(undefined);
        setIsDetails(false);
    }

    const handleSelect = (item) => {
        setSelectedItem(item);
        setIsDetails(true);
    }

    return (
        <>
            <div style={{textAlign: 'left', fontWeight: '700', fontSize: '24px'}}>{title}</div>
            <div style={{marginTop: '20px'}}>
                <div style={{marginBottom: '20px'}}>
                    <div style={{textAlign: 'left', fontWeight: '600', fontSize: '18px', marginTop: '10px'}}>
                        Журнал логирования
                    </div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{
                            marginTop: '20px',
                            marginLeft: '20px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                        </div>
                    </div>
                </div>
                {loading ? <Loader/> : (
                    <Table bordered className='news-table'>
                        <thead style={{textAlign: 'center'}}>
                        <tr style={{backgroundColor: '#ededed'}}>
                            <th style={{verticalAlign: 'middle'}}>Дата</th>
                            <th style={{verticalAlign: 'middle'}}>№ заказа</th>
                            <th style={{verticalAlign: 'middle'}}>Total</th>
                            <th style={{verticalAlign: 'middle'}}>Success</th>
                            <th style={{verticalAlign: 'middle'}}>Error</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </Table>
                )}
            </div>
            {
                logList &&
                (!loading &&
                    <Pagination current={currentPage} total={totalPages}
                                onPageChange={page => handlePageChange(page)}/>)
            }
            {isDetails && <PushLogDetails item={selectedItem} role={role} onClose={onClose}/>}

        </>
    );
}

export default PushLogPage;
