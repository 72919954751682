import {toast} from "react-toastify";

export const numberToFinanceFormat = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const phoneNumberFormat = (number) => {
    number = number.indexOf('+') !== -1 ? number : `+${number}`;
    return number;
}

export const requestErrorDisplay = (e, defaultText = "Произошла ошибка, попробуйте позже") => {
    console.log(e)
    if (e.errors) {
        for (const eKey in e.errors) {
            toast.error(e.errors[eKey][0])
        }
    }
    if (e.errorText) toast.error(e.errorText)
    else if (e.message) toast.error(e.message)
    else toast.error(defaultText)
}
