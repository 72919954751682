import React, {useEffect, useRef, useState} from "react";
import {File} from "../../services";

const FileUploader = ({orderId, onClose}) => {
    const [fileList, setFileList] = useState([]);
    const [file, setFile] = useState({});
    const inputRef = useRef(null);

    useEffect(() => {

        if (file && file.size > 0) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("type", 5);
            formData.append("orderId", orderId)
            Promise.all([
                File.addOrderDoc(formData)
            ]).then(responses => {
                resetFileInput();
                getFiles();
            }).catch(error => {

            });


        }
    }, [file]);

    useEffect(() => {
        getFiles();
    }, [orderId]);

    const getFiles = () => {
        if (orderId) {
            File.getOrderDocs(orderId).then((response) => {
                if (response.status === 200) {
                    setFileList(response.data);
                } else setFileList([]);
            }).catch(err => {
                console.log('err', err);
            })
        }
    }

    const resetFileInput = () => {
        // 👇️ reset input value
        inputRef.current.value = null;
    };

    const handleDelete = (id) => {
        File.deleteOrderDocs(id).then((response) => {
            if (response.status === 200) {
                getFiles();
            }
        }).catch(err => {
            console.log('err', err);
        })
    }
    return (<>
        <div style={{
            width: '100%',
            display: 'flex',
            marginTop: '15px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px'
        }}>
            <div>
                <h3>Список файлов</h3>
                <ul>
                    {
                        fileList.map(item => (
                            <li>
                                <span>{item.fileName}</span>
                                <img style={{cursor: 'pointer', marginLeft: '10px'}} height="14px"
                                     src="/icons/delete.png" onClick={() => handleDelete(item.id)}/>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div style={{display: 'flex', alignItems: 'flex-start'}}>
                <label className="custom-file-upload" style={{marginTop: '-15px'}}>
                    <input ref={inputRef} style={{display: 'none'}} type="file"
                           onChange={(event) => setFile(event.target.files[0])}/>
                    <div style={{
                        backgroundColor: '#E5EAF0',
                        padding: '6px 7px 7px',
                        borderRadius: '7px'
                    }}>
                        Прикрепить файл
                    </div>
                </label>
                {
                    file.name ?
                        <div style={{
                            color: '#A3ACB6',
                            marginLeft: '20px',
                            marginTop: '-10px'
                        }}>{file && `${file.name}`}</div>
                        :
                        <div style={{color: '#A3ACB6', marginLeft: '20px', marginTop: '-10px'}}>Файл
                            не выбран</div>
                }
            </div>

        </div>

    </>)
}

export default FileUploader;
