import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "../../../utils/loader";
import {Directory} from "../../../services";
import ClientContract from "../../../services/ClientContract";
import {useSelector} from "react-redux";
import {Autocomplete, TextField} from "@mui/material";

const PriceModal = ({contractId, item, onClose, countryList, vehicleTypes}) => {

    const {user} = useSelector(state => state);
    const [countryIdFrom, setCountryIdFrom] = useState('');
    const [countryIdTo, setCountryIdTo] = useState('');
    const [cityIdFrom, setCityIdFrom] = useState(item?.cityIdFrom || '');
    const [cityIdTo, setCityIdTo] = useState(item?.cityIdTo || '');
    const [price, setPrice] = useState(item?.price || 0);
    const [vehicleType, setVehicleType] = useState(item?.vehicleType || 'refrigerator');

    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [cityFromList, setCityFromList] = useState([]);
    const [cityToList, setCityToList] = useState([]);

    useEffect(() => {
        getCities(countryIdFrom, 'from');
    }, [countryIdFrom]);

    useEffect(() => {
        getCities(countryIdTo, 'to');
    }, [countryIdTo]);

    const getCities = (countryId, type: string = 'from' | 'to') => {
        Directory.getCities(countryId).then(response => {
            response.data.forEach((city) => {
                city.label = city.name;
            })
            if (type === 'from') {
                setCityFromList(response.data);
            } else {
                setCityToList(response.data);
            }
        }).catch(error => {
            console.log(error)
        });
    }

    const onSave = () => {

        setErrorText('');
        if (!cityIdFrom || !cityIdTo || (!price || price === 0)) {
            setErrorText('Заполните все обязательные поля');
        }

        let body = {
            cityIdFrom: cityIdFrom,
            cityIdTo: cityIdTo,
            price: price,
            contractId: contractId,
            vehicleType: vehicleType,
            moderator: user.name
        }

        setLoading(true);
        ClientContract.createPrice(body).then(response => {
            onClose(true);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.log('contract price ', error);
        })
    }

    const onChangeCity = (type, value) => {

        if (type === 'from') {
            if (value && value.id) setCityIdFrom(value.id);
            else setCityIdFrom('');
        } else {
            if (value && value.id) setCityIdTo(value.id);
            else setCityIdTo('');
        }
    }

    return (
        <>
            <div className="modal-overlay">
                <div style={{
                    backgroundColor: "#ffffff",
                    height: "500px",
                    width: '500px',
                    margin: "20px auto",
                    borderRadius: "6px",
                }}>
                    <div style={{
                        padding: "14px 24px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                        <div className="row" style={{width: '100%', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <span style={{cursor: 'pointer', textAlign: 'end'}} onClick={() => onClose(false)}>X</span>
                        </div>

                        <div className="row" style={{width: '100%'}}>
                            <div className="col-6">
                                <label>Погрузка</label>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Укажите страну</Form.Label>
                                    <Form.Control as="select" value={countryIdFrom}
                                                  onChange={e => setCountryIdFrom(e.target.value)}
                                                  style={{
                                                      border: '1px solid #ced4da',
                                                      width: '100%',
                                                      fontSize: '1rem'
                                                  }}>
                                        {countryList.map(item => {
                                            return (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>
                                        Название города
                                    </Form.Label>
                                    {/*<Form.Control as="select" value={cityIdFrom}*/}
                                    {/*              onChange={e => setCityIdFrom(e.target.value)}*/}
                                    {/*              style={{*/}
                                    {/*                  border: '1px solid #ced4da',*/}
                                    {/*                  width: '100%',*/}
                                    {/*                  fontSize: '1rem'*/}
                                    {/*              }}>*/}
                                    {/*    {cityFromList.map(item => {*/}
                                    {/*        return (*/}
                                    {/*            <option key={item.id} value={item.id}>{item.name}</option>*/}
                                    {/*        );*/}
                                    {/*    })}*/}
                                    {/*</Form.Control>*/}
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-city-from"
                                        options={cityFromList}
                                        value={cityFromList.find((option) => option.id === cityIdFrom)}
                                        sx={{width: '100%'}}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            );
                                        }}
                                        onChange={(event: any, newValue: any | null) => onChangeCity('from', newValue)}
                                        renderInput={(params) => <TextField {...params} label="Выберите"/>}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <label>Выгрузка</label>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Укажите страну</Form.Label>
                                    <Form.Control as="select" value={countryIdTo} onChange={e => {
                                        setCountryIdTo(e.target.value)
                                    }}>
                                        {countryList.map(item => {
                                            return (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>
                                        Название города
                                    </Form.Label>
                                    {/*<Form.Control as="select" value={cityIdTo}*/}
                                    {/*              onChange={e => setCityIdTo(e.target.value)}*/}
                                    {/*              style={{*/}
                                    {/*                  border: '1px solid #ced4da',*/}
                                    {/*                  width: '100%',*/}
                                    {/*                  fontSize: '1rem'*/}
                                    {/*              }}>*/}
                                    {/*    {cityToList.map(item => {*/}
                                    {/*        return (*/}
                                    {/*            <option key={item.id} value={item.id}>{item.name}</option>*/}
                                    {/*        );*/}
                                    {/*    })}*/}
                                    {/*</Form.Control>*/}
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-city-to"
                                        options={cityToList}
                                        value={cityToList.find((option) => option.id === cityIdTo)}
                                        sx={{width: '100%'}}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            );
                                        }}
                                        onChange={(event: any, newValue: any | null) => onChangeCity('to', newValue)}
                                        renderInput={(params) => <TextField {...params} label="Выберите"/>}
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row" style={{width: '100%'}}>
                            <Form.Group className="mb-3 w-100">
                                <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Тип</Form.Label>
                                <Form.Control as="select" value={vehicleType}
                                              onChange={e => setVehicleType(e.target.value)}
                                              style={{
                                                  border: '1px solid #ced4da',
                                                  width: '100%',
                                                  fontSize: '1rem'
                                              }}>
                                    {vehicleTypes.map(item => {
                                        return (
                                            <option key={item.id} value={item.value}>{item.name}</option>
                                        );
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="row" style={{width: '100%'}}>
                            <Form.Group className="mb-3 w-100">
                                <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Цена</Form.Label>
                                <Form.Control type="text" value={price}
                                              onChange={(e) => setPrice(e.target.value)}
                                              placeholder="Введите"/>
                            </Form.Group>
                        </div>

                        {errorText !== '' && <div className="row" style={{width: '100%'}}>
                            <span style={{color: 'red'}}>{errorText}</span>
                        </div>}
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        marginTop: '10px'
                    }}>
                        <Button style={{width: '120px'}} variant="outline-primary" onClick={onSave}>
                            Добавить
                        </Button>
                        <Button style={{width: '120px'}} variant="outline-primary" onClick={() => onClose(false)}>
                            Закрыть
                        </Button>
                    </div>

                    {loading === true && <Loader/>}
                </div>
            </div>
        </>
    );
};

export default PriceModal;
