import { get, post, put, destroy } from './adminConfig';

const SystemRoles = {
    getAll: () =>
        get('v1/roles'),
    getById: (id) =>
        get(`v1/roles/${id}`),
    addRole: (data) =>
        post('v1/roles', data),
    updateRole: (data) =>
        put('v1/roles', data)
}

export default SystemRoles;