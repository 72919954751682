import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
// import PhotoViewer from "./PhotoViewer";
import {File, User} from "../../../services";
import PhotoViewerCl from "./PhotoViewerCl";
import Loader from "../../../utils/loader";
import PdfViewer from "./PdfViewer";

const LoadingClientPhotos = ({item, role}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isPdfOpen, setIsPdfOpen] = useState(false);
    const [imgUrl, setImgUrl] = useState();
    const [loading, setLoading] = useState(false);
    const [fFile, setFFile] = useState();

    const openViewPhoto = (fileType) => {
        setLoading(true);
        setFFile(null);
        File.getByUserId(fileType, item.id)
            .then((response) => {
                console.log("response Bir client ===>", response);
                setLoading(false);
                const file = new Blob([response.data], {
                    type: response.data.type,
                });

                setFFile({file: file, type: response.data.type});

                const fileURL = URL.createObjectURL(file);
                if (response.data.type === 'application/pdf') {
                    window.open(fileURL);
                    // setImgUrl(fileURL);
                    // setIsPdfOpen(true);
                } else {
                    setImgUrl(fileURL);
                    setIsOpen(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log("Произошла ошибка, попробуйте позже", error);
                alert("Произошла ошибка, попробуйте позже");
            });
    };

    const onDownload = () => {

        let downloadElement = document.createElement('a');
        downloadElement.href = URL.createObjectURL(fFile.file);
        downloadElement.download = "driversByOrderCount";
        downloadElement.click();
    }

    const handleClose = () => {
        setIsOpen(false);
        setIsPdfOpen(false);
    }

    return (
        <>
            <div className="col-6" style={{paddingBottom: "12px"}}>
                <Button
                    onClick={() => openViewPhoto("0")}
                    variant="outline-primary"
                    style={{width: "100%", fontSize: "14px", minHeight: "56px"}}
                >
                    Свидетельство о регистрации
                </Button>
            </div>
            {/* <div className='col-4' style={{ paddingBottom: '12px' }}>
                                <Button onClick={() => openUserFile('1')} variant="outline-primary" style={{ width: '100%', fontSize: '14px', minHeight: '56px' }}>Договор</Button>
                            </div> */}
            <div className="col-6" style={{paddingBottom: "12px"}}>
                <Button onClick={() => openViewPhoto("2")}
                        variant="outline-primary"
                        style={{width: "100%", fontSize: "14px", minHeight: "56px"}}>
                    Приказ о назначении
                </Button>
            </div>
            {isOpen && <PhotoViewerCl imgUrl={imgUrl} onClose={handleClose} onDownload={onDownload} role={role}/>}
            {isPdfOpen && <PdfViewer imgUrl={imgUrl} onClose={handleClose} role={role}/>}
            {loading && <Loader/>}
        </>
    );
};

export default LoadingClientPhotos;
