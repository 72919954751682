import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {SystemUsers, SystemRoles} from '../../services';

const UserResetPasswordModal = ({onClose, onSuccess, currentItem}) => {

    const [username, setUsername] = useState(currentItem ? currentItem.username : null);
    const [password, setPassword] = useState();

    const [errorTitle, setErrorTitle] = useState();

    const onSubmit = () => {

        if (password) {
            setErrorTitle();
            SystemUsers.updatePassword(currentItem.id,password).then(response => {
                console.log("SYSTEM USER create response ---->", response)
                if (response.status == 200) {
                    console.log('role add success');
                    onSuccess();
                } else alert('Произошла ошибка, попробуйте позже');

            }).catch(error => {
                console.log(error)
                alert('Произошла ошибка, попробуйте позже')
            });


        } else {
            setErrorTitle('Необходимо заполнить пароль')
        }
    }

    const renderContent = () => {
        return (
            <div style={{padding: '24px'}}>

                <Form.Group className="mb-3">
                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Пароль</Form.Label>
                    <Form.Control type="text" value={password} onChange={(e) => setPassword(e.target.value)}
                                  placeholder="Введите пароль пользователя"/>
                </Form.Group>

                {errorTitle ? <div style={{color: 'red'}}>{errorTitle}</div> : null}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '30px', gap: '10px'}}>
                    <div><Button onClick={onSubmit} variant="success">Сохранить</Button></div>
                    <div><Button onClick={onClose} variant="secondary">Отменить</Button></div>
                </div>
            </div>
        );
    }

    return (
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '500px',
                margin: '24px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff', fontWeight: '600'}}>
                        Cбросить пароль
                    </div>
                    <div onClick={onClose}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default UserResetPasswordModal;
