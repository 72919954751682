import {destroy, get, post, put} from "./config";

const FeedbackRequest = {
    list: (page, limit) =>
        get(`v1/feedbackrequest/list?page=${page}&limit=${limit}`),
    delete: (id) => destroy(`v1/feedbackrequest/${id}`),
    seen: (ids) => post(`v1/feedbackrequest/seen`, ids),
    saveComment: (id, body) => put(`v1/feedbackrequest/comment/${id}`, body)
}

export default FeedbackRequest;
