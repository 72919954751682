import React, {useEffect, useState} from "react";
import moment from "moment";

const PushLogDetailsItem = ({item, role}) => {

    return (
        <tr>
            <td style={{width: '80px'}}>
                {moment(item.createdAt).format('DD.MM.yyyy HH:mm')}
            </td>
            <td style={{width: '40px'}}>
                {item.phoneNumber}
            </td>
            <td style={{width: '180px'}}>
                {item.errorMessage}
            </td>
        </tr>
    )
}
export default PushLogDetailsItem;
