import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "../../../utils/loader";
import {Directory} from "../../../services";
import {LANG_LIST} from "../../news/models";

const CheckCities = ({order, onClose, newCitiesCount, cities}) => {

    const [countryIds, setCountryIds] = useState();
    const [countryObj, setCountryObj] = useState();
    const cityFrom = cities.find(x => x.id === order.cityIdFrom);
    const [isActualFrom, setIsActualFrom] = useState(cityFrom.isActual);
    const [countryIdFrom, setCountryIdFrom] = useState(order.countryIdFrom);
    const [nameFrom, setNameFrom] = useState(cityFrom.name);
    const [latitudeFrom, setLatitudeFrom] = useState();
    const [longitudeFrom, setLongitudeFrom] = useState();

    const cityTo = cities.find(x => x.id === order.cityIdTo);
    const [isActualTo, setIsActualTo] = useState(cityTo.isActual);
    const [countryIdTo, setCountryIdTo] = useState('');
    const [nameTo, setNameTo] = useState(cityTo.name);

    const [latitudeTo, setLatitudeTo] = useState(cityTo.latitude || undefined);
    const [longitudeTo, setLongitudeTo] = useState(cityTo.longitude || undefined);

    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [countryList, setCountryList] = useState([]);

    useEffect(() => {

        Directory.getCountries().then(response => {
            setCountryList(response.data);
            setCountryIdTo(order.countryIdTo);
            // if (response.data.length > 0) {
            //
            //     let arr = [];
            //     let obj = [];
            //
            //     for (let i = 0; i < response.data.length; i++) {
            //         arr.push(response.data[i].id);
            //         obj[response.data[i].id] = response.data[i].name
            //     }
            //
            //     setCountryIdFrom(response.data[0].id);
            //     setCountryIdTo(response.data[0].id);
            //
            //     setCountryIds(arr);
            //     setCountryObj(obj);
            // } else {
            //     setCountryIds([]);
            //     setCountryObj({});
            // }

        }).catch(error => {
            console.log(error)
        });

    }, []);

    const onSave = () => {

        setErrorText('');
        if (!isActualFrom && !isActualTo) {

            if (!latitudeFrom || !longitudeFrom || !latitudeTo || !longitudeTo) {
                setErrorText('Заполните широта долгота');
                return
            }

            saveCity(order.cityIdFrom, {
                countryId: countryIdFrom,
                name: nameFrom,
                latitude: latitudeFrom,
                longitude: longitudeFrom
            }, false);
            saveCity(order.cityIdTo, {
                countryId: countryIdTo,
                name: nameTo,
                latitude: latitudeTo,
                longitude: longitudeTo
            }, true);
        } else if (!isActualFrom) {
            if (!latitudeFrom || !longitudeFrom) {
                setErrorText('Заполните широта долгота');
                return
            }
            saveCity(order.cityIdFrom, {
                countryId: countryIdFrom,
                name: nameFrom,
                latitude: latitudeFrom,
                longitude: longitudeFrom
            }, true)
        } else if (!isActualTo) {
            if (!latitudeTo || !longitudeTo) {
                setErrorText('Заполните широта долгота');
                return
            }
            saveCity(order.cityIdTo, {
                countryId: countryIdTo,
                name: nameTo,
                latitude: latitudeTo,
                longitude: longitudeTo
            }, true);
        }
    }

    const saveCity = (id, body, isClose) => {
        setLoading(true);
        Directory.updateCityFromModerator(id, body).then(response => {
            setLoading(false);
            if (isClose) onClose(true);
        }).catch(error => {
            setLoading(false);
            console.log('update city error', error);
        })
    }

    return (
        <>
            <div className="modal-overlay">
                <div style={{
                    backgroundColor: "#ffffff",
                    height: "500px",
                    width: !isActualTo && !isActualFrom ? '600px' : '400px',
                    margin: "20px auto",
                    borderRadius: "6px",
                }}>
                    <div style={{
                        padding: "14px 24px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eeeeee",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                    }}>
                        <div className="row" style={{width: '100%', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <span style={{cursor: 'pointer', textAlign: 'end'}} onClick={() => onClose(false)}>X</span>
                        </div>

                        <div className="row" style={{width: '100%'}}>
                            {!isActualFrom && <div className={!isActualFrom && !isActualTo ? "col-6" : 'col-12'}>
                                <label>Погрузка</label>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Укажите страну</Form.Label>
                                    {
                                        countryList && countryList.length > 0 ?
                                            <Form.Control as="select" value={countryIdFrom}
                                                          onChange={e => setCountryIdFrom(e.target.value)}
                                                          style={{
                                                              border: '1px solid #ced4da',
                                                              width: '100%',
                                                              fontSize: '1rem'
                                                          }}>
                                                {countryList.map(item => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    );
                                                })}
                                            </Form.Control> :
                                            <Loader/>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>
                                        Название города
                                    </Form.Label>
                                    <Form.Control type="text" value={nameFrom}
                                                  onChange={(e) => setNameFrom(e.target.value)}
                                                  placeholder="Введите название"/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Latitude</Form.Label>
                                    <Form.Control type="text" value={latitudeFrom}
                                                  onChange={(e) => setLatitudeFrom(e.target.value)}
                                                  placeholder="Введите координату"/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Longitude</Form.Label>
                                    <Form.Control type="text" value={longitudeFrom}
                                                  onChange={(e) => setLongitudeFrom(e.target.value)}
                                                  placeholder="Введите коррдинату"/>
                                </Form.Group>
                            </div>}
                            {!isActualTo && <div className={!isActualFrom && !isActualTo ? "col-6" : 'col-12'}>
                                <label>Выгрузка</label>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Укажите страну</Form.Label>
                                    {
                                        countryList && countryList.length > 0 ?
                                            <Form.Control as="select" value={countryIdTo} onChange={e => {
                                                setCountryIdTo(e.target.value)
                                            }}>
                                                {countryList.map(item => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    );
                                                })}
                                            </Form.Control> : <Loader/>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>
                                        Название города
                                    </Form.Label>
                                    <Form.Control type="text" value={nameTo} onChange={(e) => setNameTo(e.target.value)}
                                                  placeholder="Введите название"/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Latitude</Form.Label>
                                    <Form.Control type="text" value={latitudeTo}
                                                  onChange={(e) => setLatitudeTo(e.target.value)}
                                                  placeholder="Введите координату"/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{color: '#A3ACB6', fontSize: '15px'}}>Longitude</Form.Label>
                                    <Form.Control type="text" value={longitudeTo}
                                                  onChange={(e) => setLongitudeTo(e.target.value)}
                                                  placeholder="Введите коррдинату"/>
                                </Form.Group>
                            </div>}
                        </div>
                        {errorText !== '' && <div className="row" style={{width: '100%'}}>
                            <span style={{color: 'red'}}>{errorText}</span>
                        </div>}
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        marginTop: '10px'
                    }}>
                        <Button style={{width: '120px'}} variant="outline-primary" onClick={onSave}>
                            Подтвердить
                        </Button>
                        <Button style={{width: '120px'}} variant="outline-primary" onClick={() => onClose(false)}>
                            Закрыть
                        </Button>
                    </div>

                    {loading === true && <Loader/>}
                </div>
            </div>
        </>
    );
};

export default CheckCities;
