import {destroy, get, post, put} from "./config";

const Insurance = {
    list: (page, limit, orderNumber) => get(`v1/insurance/list?page=${page}&limit=${limit}&number=${orderNumber}`),
    delete: (id) => destroy(`v1/insurance/${id}`),
    contracts: (externalId) => get(`/v1/insurance/contracts/${externalId}`),
    contractFromTo:(from,to)=>get(`/v1/insurance/contracts?from=${from}&to=${to}`),
    downloadContracts: (no: string) => get(`/v1/insurance/contracts/file/${no}`, {responseType: 'blob'}),
}
export default Insurance;
