import React, {useEffect, useState} from "react";
import {numberToFinanceFormat} from "../../../utils";

const CargoDetails = ({cargoDetails, order}) => {
    const [role, setRole] = useState();

    return (
        <>
            <div style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid rgba(190, 190, 182, 0.3)",
                paddingBottom: "10px",
                marginTop: "24px",
                width:'100%'
            }}>
                <div style={{
                    fontSize: "15px",
                    lineHeight: "1.3",
                    fontWeight: "600",
                }}>
                    Детали груза
                </div>
            </div>
            {cargoDetails ? (
                <div className="row" style={{marginTop: "12px"}}>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>Вес</div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {cargoDetails.weight} т
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Объем
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {cargoDetails.volume} м<sup>3</sup>
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Габариты
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {cargoDetails.width}x{cargoDetails.height}x
                            {cargoDetails.length} см
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Категория
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {cargoDetails.categoryName}
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Тип погрузки
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {cargoDetails.loadingTypeName}
                        </div>
                    </div>
                    <div className="col-4" style={{paddingBottom: "12px"}}>
                        <div style={{color: "#A3ACB6", fontSize: "12px"}}>
                            Описание
                        </div>
                        <div style={{fontSize: "14px", marginTop: "2px"}}>
                            {cargoDetails.details}
                        </div>
                    </div>
                    <div className="col-8" style={{paddingBottom: "12px"}}>
                        <div style={{
                            color: "#A3ACB6",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}>
                            <span>Страховка</span>
                            <input type="checkbox" checked={order.isInsurance} readOnly/>
                        </div>
                        {order.isInsurance && (
                            <div style={{
                                fontSize: "14px",
                                marginTop: "2px",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                            }}>
                                <span>стоимость страховки:</span>
                                <span> {" "}{numberToFinanceFormat(order.insurancePrice)} &#8376;</span>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div style={{color: "#A3ACB6", size: "14px", padding: "16px 0"}}>
                    Загрузка..
                </div>
            )}

        </>
    );
};

export default CargoDetails;
