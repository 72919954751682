import React, {useEffect, useState} from 'react';
import {Order} from '../../../services';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge';

export const ORDER_STATUSES = {
    all: "Все заказы",
    moderation: "На модерации",
    awaitingpayment: "Ожидает оплаты",
    published: "Опубликован",
    booked: "Забронирован водителем",
    confirmed: "Ожидание начала погрузки",
    processloadingcargo: "Идет погрузка груза",
    loadingcargomoderation: "Ожидает модерации погрузка",
    rejectedloadingcargomoderation: "Модератор отклонил погрузка",
    waitstarttrip: "Ожидание начала поездки",
    onthetrip: "В пути",
    processunloadingcargo: "Идет разгрузка",
    unloadingcargomoderation: "Ожидает модерации разгрузка",
    rejectedunloadingcargomoderation: "Модератор отклонил разгрузка",
    completed: "Завершен",
    rejected: "Отклонён",
    canceled: "Отменен"
};

const PAYMENTS_STATUS_TITLE = {
    'awaitingpayment': 'Ожидает оплаты',
    'paid': 'Оплачен'
}

const DriverPaymentListItem = ({item, selectPayment, onOpenOrderModal}) => {

    const handleOpenOrderModal = (event) => {
        event.stopPropagation();
        Order.getById(item.orderId)
            .then(response => {
                onOpenOrderModal(response.data);
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <tr onClick={() => selectPayment(item)}>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>№ {item.number}</div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>{moment(item.createdAt).format('DD.MM.YYYY')}</div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0', textDecoration: 'underline',cursor:'pointer'}}
                     onClick={(event)=>handleOpenOrderModal(event)}>{item.orderNumber}
                </div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>
                    <Badge bg="primary">{ORDER_STATUSES[item.orderStatus]}</Badge>
                </div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>{item.countryFromName}, {item.cityFromName}</div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>{item.countryToName}, {item.cityToName}</div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>{item.sender}</div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>{item.driverFIO}</div>
                <div style={{padding: '5px 0'}}>{item.driverIIN}</div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>{item.iban}</div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>{item.distance ? item.distance + 'км' : <Skeleton/>}</div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}>{item.netPrice ? item.netPrice + 'тг' : <Skeleton/>}</div>
            </td>
            <td className={item.status === 'awaitingpayment' && item.orderStatus === 'completed' ? 'warning' : ''}>
                <div style={{padding: '5px 0'}}><Badge bg="primary">{PAYMENTS_STATUS_TITLE[item.status]}</Badge></div>
            </td>
        </tr>
    );
}

export default DriverPaymentListItem
