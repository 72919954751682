import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../utils/loader';
import NotifyService from '../../services/Notification';
import Pagination from "react-responsive-pagination";
import NotificationItemCard from './notificationCard/NotificationItemCard';


const NotificationPage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [notificationsTotal, setNotificationsTotal] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const [isUpdateList, setUpdateList] = useState(false)

    const { user } = useSelector(state => state);
    const ITEMS_LIMIT = 10;

    const getNotifications = async () => {
        setLoading(true)
        try {
            const response = await NotifyService.getNotificationsList({
                page: currentPage,
                limit: ITEMS_LIMIT
            });
            const responseData = response.data;

            setNotifications(responseData.rows || []);
            setNotificationsTotal(Math.ceil(responseData.total / ITEMS_LIMIT));
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        setUpdateList(false);
        getNotifications();
    }, [currentPage, isUpdateList])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const formatRussianDate = (inputDate) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate = new Date(inputDate).toLocaleDateString('ru-RU', options);
        return formattedDate;
    };

    const groupedNotifications = Array.isArray(notifications)
        ? notifications.reduce((result, notification) => {
            const date = formatRussianDate(notification.createdAt); // Ensure formatRussianDate is correctly imported or defined
            if (!result[date]) {
                result[date] = [];
            }
            result[date].push(notification);
            return result;
        }, {})
        : {};

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <h3 className='insurance__title'>Уведомления</h3>
            {Object.entries(groupedNotifications).map(([date, notificationsForDate]) => (
                <div key={date}>
                    <span>{date}</span>
                    {notificationsForDate.map(notification => (
                        <NotificationItemCard
                            orderId={notification.orderId}
                            key={notification.id}
                            type={notification.type}
                            notification={notification}
                            userName={user.name}
                            UpdateList={setUpdateList}
                        />
                    ))}
                </div>
            ))}

            <Pagination
                current={currentPage}
                total={notificationsTotal}
                onPageChange={page => handlePageChange(page)}
            />
        </>
    );
}

export default NotificationPage;
