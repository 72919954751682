import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {User} from "../../../services";

const DriverConfirmModal = ({driver, driverInfo, user, onClose}) => {
    const [iin, setIin] = useState(driverInfo.iin || '');
    const [idNumber, setIdNumber] = useState(driverInfo.idNumber || '');
    const [issuedBy, setIssuedBy] = useState(driverInfo.issuedBy || '');
    const [issuedDate, setIssuedDate] = useState(driverInfo.issuedDate || '');
    const [carNumber, setCarNumber] = useState(driverInfo.carNumber || '');
    const [carModel, setCarModel] = useState(driverInfo.carModel || '');
    const [trailerNumber, setTrailerNumber] = useState(driverInfo.trailerNumber || '');
    const [trailerModel, setTrailerModel] = useState(driverInfo.trailerModel || '');

    const confirmUser = () => {
        const data = {
            iin: iin,
            idNumber: idNumber,
            issuedBy: issuedBy,
            issuedDate: issuedDate,
            carNumber: carNumber,
            carModel: carModel,
            trailerNumber: trailerNumber,
            trailerModel: trailerModel,
            user: user.name,
        };

        User.confirmDriver(driver.id, data)
            .then((response) => {
                onClose(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <div className="modal-overlay">
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        maxHeight: "calc(100vh - 50px)",
                        width: "340px",
                        margin: "30px auto",
                        borderRadius: "6px",
                    }}
                >
                    <div
                        style={{
                            padding: "14px 24px",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#ffffff",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #eeeeee",
                            borderTopLeftRadius: "6px",
                            borderTopRightRadius: "6px",
                        }}
                    >
                        <div style={{fontSize: "16px", backgroundColor: "#ffffff"}}>
                            Информация о водителе
                        </div>
                        <div style={{cursor: "pointer"}} onClick={() => {
                            onClose(false);
                        }}> X
                        </div>
                    </div>
                    <div
                        style={{
                            overflowY: "scroll",
                            maxHeight: "calc(100vh - 103px)",
                            textAlign: "left",
                        }}
                    >
                        <div style={{padding: "16px"}}>
                            <div>
                                <Form.Control
                                    value={iin}
                                    onChange={(e) => setIin(e.target.value)}
                                    size="lg"
                                    type="text"
                                    placeholder="ИИН"
                                    style={{fontSize: "16px", padding: "12px 16px"}}
                                />
                                <Form.Control
                                    value={idNumber}
                                    onChange={(e) => setIdNumber(e.target.value)}
                                    size="lg"
                                    type="text"
                                    placeholder="Номер удостоверения"
                                    style={{
                                        fontSize: "16px",
                                        padding: "12px 16px",
                                        marginTop: "16px",
                                    }}
                                />
                                <Form.Control
                                    value={issuedBy}
                                    onChange={(e) => setIssuedBy(e.target.value)}
                                    size="lg"
                                    type="text"
                                    placeholder="Кем выдан"
                                    style={{
                                        fontSize: "16px",
                                        padding: "12px 16px",
                                        marginTop: "16px",
                                    }}
                                />
                                <Form.Control
                                    value={issuedDate}
                                    onChange={(e) => setIssuedDate(e.target.value)}
                                    size="lg"
                                    type="date"
                                    placeholder="Когда выдан"
                                    style={{
                                        fontSize: "16px",
                                        padding: "12px 16px",
                                        marginTop: "16px",
                                    }}
                                />
                                <Form.Control
                                    value={carNumber}
                                    onChange={(e) => setCarNumber(e.target.value)}
                                    size="lg"
                                    type="text"
                                    placeholder="Номер авто"
                                    style={{
                                        fontSize: "16px",
                                        padding: "12px 16px",
                                        marginTop: "16px",
                                    }}
                                />
                                <Form.Control
                                    value={carModel}
                                    onChange={(e) => setCarModel(e.target.value)}
                                    size="lg"
                                    type="text"
                                    placeholder="Модель авто"
                                    style={{
                                        fontSize: "16px",
                                        padding: "12px 16px",
                                        marginTop: "16px",
                                    }}
                                />
                                <Form.Control
                                    value={trailerNumber}
                                    onChange={(e) => setTrailerNumber(e.target.value)}
                                    size="lg"
                                    type="text"
                                    placeholder="Гос номер прицепа"
                                    style={{
                                        fontSize: "16px",
                                        padding: "12px 16px",
                                        marginTop: "16px",
                                    }}
                                />
                                <Form.Control
                                    value={trailerModel}
                                    onChange={(e) => setTrailerModel(e.target.value)}
                                    size="lg"
                                    type="text"
                                    placeholder="Марка, модель прицепа"
                                    style={{
                                        fontSize: "16px",
                                        padding: "12px 16px",
                                        marginTop: "16px",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    marginTop: "20px",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >

                                <Button onClick={() => {
                                    onClose(false);
                                }}
                                        variant="light"
                                        style={{marginRight: "20px"}}>
                                    Отменить
                                </Button>
                                {iin ? (
                                    <Button onClick={confirmUser} variant="primary">
                                        Отправить
                                    </Button>
                                ) : (
                                    <Button
                                        variant="primary"
                                        style={{
                                            backgroundColor: "rgb(107 104 104)",
                                            borderColor: "rgb(107 104 104)",
                                            cursor: "default",
                                        }}
                                    >
                                        Отправить
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DriverConfirmModal;
