import { get, post, put, destroy } from './config';

const Vehicle = {
    getById: (id) =>
        get(`v1/vehicle/${id}`),
    getByDriverId: (id) =>
        get(`v1/vehicle/by-driver-${id}`)
}

export default Vehicle;
