import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Directory } from '../../services';
import Button from 'react-bootstrap/Button';
import TypeCreateModal from './TypeCreateModal';
import Badge from 'react-bootstrap/Badge';
import Loader from '../../utils/loader';

const TypesPage = () => {

    const [types, setTypes] = useState();
    const [showCreateModal, setShowCreateModal] = useState(false);

    useEffect(() => {
        getTypes()
    }, []);

    const getTypes = () => {
        Directory.getTypes().then(response => {
            console.log("TYPES", response)
            if(response.status == 200) {
                if(response.data.length > 0) setTypes(response.data)
                else setTypes([]);
            }else {
                setTypes([])
            }
          })
          .catch(error => {
            console.log(error)
          })
    }

    const closeUpdate = () => {
        getTypes();
        setShowCreateModal(false);
    }

    const renderList = () => {
        if(types) {
            if(types.length > 0) {
                const list = types.map(item => {
                    return (
                        <ListGroup.Item style={{ padding: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{item.name}</div>
                                <div style={{ marginLeft: '12px', flex: '1' }}><Badge bg="secondary">{item.value}</Badge></div>
                                <div>
                                    <Button size="sm" variant="outline-danger" style={{ fontSize: '11px' }}>Удалить</Button>
                                    <Button size="sm" variant="outline-secondary" style={{ fontSize: '11px', marginLeft: '12px' }}>Редактировать</Button>
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                });

                return list;
            }else {
                return <div>Типов траснпорта нет</div>
            }
        }else {
            return <Loader />
        }
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'left', fontWeight: '700', fontSize: '24px' }}>Типы транспорта</div>
                <div>
                    <Button onClick={() => setShowCreateModal(true)} size="sm">Добавить тип</Button>
                </div>
            </div>
            <div style={{ marginTop: '20px' }}>
                <ListGroup>
                    {renderList()}
                </ListGroup>
            </div>

            {
                showCreateModal && <TypeCreateModal onClose={() => setShowCreateModal(false)} onSubmitExit={closeUpdate} />
            }
        </>
    );
}

export default TypesPage;