import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SelectModal from "../../custom/select/SelectModal";
import React, {useEffect, useState} from "react";
import {Driver, File, Order, User} from "../../../services";
import {useSelector} from "react-redux";
import moment from "moment/moment";
import driverInfoModal from "../DriverInfoModal";
import {ChangeEvent} from "react";

const DriverUpdateModal = ({onClose, driver, vehicle, vtypes}) => {

    const {user} = useSelector((state) => state);
    const [showTypeSelect, setShowTypeSelect] = useState(false);
    const [types, setTypes] = useState();
    const [vehicleType, setVehicleType] = useState();

    const [idCardFront, setIdCardFront] = useState('');
    const [idCardBack, setIdCardBack] = useState('');
    const [carPhoto, setCarPhoto] = useState('');
    const [carCardFront, setCarCardFront] = useState('');
    const [carCardBack, setCarCardBack] = useState('');
    const [trailerCardFront, setTrailerCardFront] = useState('');
    const [trailerCardBack, setTrailerCardBack] = useState('');

    const [driverInfo, setDriverInfo] = useState({});
    const [docList, setDocList] = useState([]);
    const [docDescriptions, setDocDescriptions] = useState([]);
    const [changedFile, setChangedFile] = useState({
        idCardFront: false,
        idCardBack: false,
        carPhoto: false,
        carCardFront: false,
        carCardBack: false,
        trailerCardFront: false,
        trailerCardBack: false
    });

    const setDocuments = (listDoc) => {
        let docs = [];
        for (let i = 0; i < listDoc.length; i++) {
            let doc = listDoc[i];

            let imageDescription = [doc.typeName, " - ", doc.sideName].join("");

            if (imageDescription === "Удостоверение личности - лицевая") {
                setIdCardFront(doc.id);
            } else if (imageDescription === "Удостоверение личности - обратная") {
                setIdCardBack(doc.id);
            } else if (imageDescription === "Автомобиль - лицевая") {
                setCarPhoto(doc.id);
            } else if (imageDescription === "Тех. паспорт - лицевая") {
                setCarCardFront(doc.id);
            } else if (imageDescription === "Тех. паспорт - обратная") {
                setCarCardBack(doc.id);
            } else if (imageDescription === "Тех. паспорт прицепа - лицевая") {
                setTrailerCardFront(doc.id);
            } else if (imageDescription === "Тех. паспорт прицепа - обратная") {
                setTrailerCardBack(doc.id);
            }

            docs.push(imageDescription);

            // FIXME: set the documents that are already uploaded to their own fields so that they can be rendered in the modal form
        }
        return docs;
    };

    useEffect(() => {

        let _info = {
            firstname: driver.name,
            surname: driver.surname,
            patronymic: driver.patronymic,
            phoneNumber: driver.phoneNumber,
            iin: driver.iin,
            issuedBy: driver.issuedBy,
            idNumber: driver.idNumber,
            vehicleTypeId: vehicle.vehicleTypeId,
            vehicleId: vehicle.id,
            carNumber: vehicle.carNumber,
            carModel: vehicle.carModel,
            trailerNumber: vehicle.trailerNumber,
            trailerModel: vehicle.trailerModel
        }

        if (driver.issuedDate) {
            const dt = moment(driver.issuedDate).format("YYYY-MM-DD");
            _info.issuedDate = dt;
        }

        setDriverInfo(_info);
        File.getDocList(driver.id)
            .then((response) => {
                setDocList(response.data);
                setDocDescriptions(setDocuments(response.data));
            })
            .catch((error) => {
                console.log(error);
                alert("Произошла ошибка, попробуйте позже");
            });

    }, [driver]);

    const handleSend = () => {
        const formDataIdFront = new FormData();
        formDataIdFront.append("file", idCardFront);
        formDataIdFront.append("type", 3);
        formDataIdFront.append("side", 0);
        formDataIdFront.append("userId", driver.id);
        const formDataIdBack = new FormData();
        formDataIdBack.append("file", idCardBack);
        formDataIdBack.append("type", 3);
        formDataIdBack.append("side", 1);
        formDataIdBack.append("userId", driver.id);
        const formDataCarFront = new FormData();
        formDataCarFront.append("file", carCardFront);
        formDataCarFront.append("type", 4);
        formDataCarFront.append("side", 0);
        formDataCarFront.append("userId", driver.id);
        const formDataCarBack = new FormData();
        formDataCarBack.append("file", carCardBack);
        formDataCarBack.append("type", 4);
        formDataCarBack.append("side", 1);
        formDataCarBack.append("userId", driver.id);
        const formDataTrailerFront = new FormData();
        formDataTrailerFront.append("file", trailerCardFront);
        formDataTrailerFront.append("type", 7);
        formDataTrailerFront.append("side", 0);
        formDataTrailerFront.append("userId", driver.id);
        const formDataTrailerBack = new FormData();
        formDataTrailerBack.append("file", trailerCardBack);
        formDataTrailerBack.append("type", 7);
        formDataTrailerBack.append("side", 1);
        formDataTrailerBack.append("userId", driver.id);
        const formDataCarPhoto = new FormData();
        formDataCarPhoto.append("file", carPhoto);
        formDataCarPhoto.append("type", 5);
        formDataCarPhoto.append("side", 0);
        formDataCarPhoto.append("userId", driver.id);
        const formDataVehicleType = {
            "driverId": driver.id,
            "vehicleTypeId": vehicle.vehicleTypeId,
        };

        const files = [];
        if (idCardFront && changedFile.idCardFront)
            files.push(File.addDocDriver(formDataIdFront));

        if (idCardBack && changedFile.idCardBack)
            files.push(File.addDocDriver(formDataIdBack));

        if (carCardFront && changedFile.carCardFront)
            files.push(File.addDocDriver(formDataCarFront));

        if (carCardBack && changedFile.carCardBack)
            files.push(File.addDocDriver(formDataCarBack));

        if (trailerCardFront && changedFile.trailerCardFront)
            files.push(File.addDocDriver(formDataTrailerFront));

        if (trailerCardBack && changedFile.trailerCardBack)
            files.push(File.addDocDriver(formDataTrailerBack));

        if (carPhoto && changedFile.carPhoto)
            files.push(File.addDocDriver(formDataCarPhoto));

        Promise.all(files).then(responses => {
            let summary200 = true;

            if (responses && responses.length) {
                for (let i = 0; i < files.length; i++) {
                    if (responses[i].status != 200) {
                        summary200 = false;
                    }
                }
            }

            if (summary200 === true) {
                const body = {...driverInfo, user: user.name};
                User.updateDriver(driver.id, body).then(response => {
                    alert('Успешно сохранено');
                    onClose(true);
                }).catch(error => {
                    console.log(error);
                    alert('Произошла ошибка, попробуйте позже');
                });

            } else {
                alert('При загрузке файлов произошла ошибка');
            }
        }).catch(error => {
            console.log(error);
            alert('Произошла ошибка, попробуйте позже');
        })
    }

    const onChangeField = (fieldName, value) => {
        setDriverInfo({...driverInfo, [fieldName]: value});
    }

    const renderUploadForm = (placeholder_text, getFile, setFile, id_prefix, fieldName) => {
        return (
            <div style={{
                marginTop: '16px',
                border: '1px solid #ced4da',
                borderRadius: '8px',
                textAlign: 'left',
                padding: '12px 16px'
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{flex: '1'}}>
                        <div style={{fontSize: '15px'}}>{placeholder_text}</div>
                        <div style={{fontSize: '13px', color: '#A3ACB6'}}>Загрузите фото, скан документа</div>
                    </div>
                    {
                        !getFile
                            ?
                            <div>
                                <label htmlFor={`upload-photo-${id_prefix}`}>
                                    <div style={{
                                        backgroundColor: 'rgba(187, 187, 187, 0.1)',
                                        padding: '6px 12px 12px',
                                        borderRadius: '12px'
                                    }}>
                                        <div style={{height: '20px'}}>
                                            <img src="/icons/upload.svg" height="10px"/>
                                        </div>
                                    </div>
                                </label>
                                <input type="file" name="photo" id={`upload-photo-${id_prefix}`}
                                       style={{opacity: "0", position: "absolute", zIndex: "-1"}}
                                       onChange={(event) => {
                                           setFile(event.target.files[0]);
                                           setChangedFile(cur => ({...cur, [fieldName]: true}));
                                       }}/>
                            </div>
                            :
                            <div>
                                <div style={{display: 'flex', gap: '4px'}}>
                                    <div style={{
                                        backgroundColor: 'rgba(19, 182, 94, 0.1)',
                                        padding: '4px 8px',
                                        borderRadius: '12px',
                                        cursor: 'pointer'
                                    }}>
                                        <img src="/icons/tick-success.svg" height="10px"/>
                                    </div>
                                    <div style={{
                                        backgroundColor: 'rgba(19, 182, 94, 0.1)',
                                        padding: '4px 8px',
                                        borderRadius: '12px',
                                        cursor: 'pointer'
                                    }} onClick={(e) => {
                                        setChangedFile(cur => ({...cur, [fieldName]: true}));
                                        setFile(null);
                                        e.stopPropagation();
                                    }}>
                                        <img src="/icons/delete.png" height="10px"/>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>

        )
    }

    return <>
        <div className='modal-overlay'>
            <div style={{
                backgroundColor: '#ffffff',
                maxHeight: 'calc(100vh - 50px)',
                width: '400px',
                margin: '30px auto',
                borderRadius: '6px'
            }}>
                <div style={{
                    padding: '14px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #eeeeee',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px'
                }}>
                    <div style={{fontSize: '16px', backgroundColor: '#ffffff'}}>Информация о водителе</div>
                    <div style={{cursor: 'pointer'}} onClick={onClose}>X</div>
                </div>
                <div style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 103px)', textAlign: 'left'}}>
                    <div style={{padding: '16px'}}>
                        {driverInfo && <div>
                            <Form.Control value={driverInfo.firstname}
                                          onChange={(e) => onChangeField('firstname', e.target.value)}
                                          size="lg"
                                          type="text" placeholder="Имя"
                                          style={{fontSize: '16px', padding: '12px 16px'}}/>
                            <Form.Control value={driverInfo.surname}
                                          onChange={(e) => onChangeField('surname', e.target.value)}
                                          size="lg"
                                          type="text" placeholder="Фамилия"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={driverInfo.patronymic}
                                          onChange={(e) => onChangeField('patronymic', e.target.value)}
                                          size="lg"
                                          type="text" placeholder="Отчество"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={driverInfo.phoneNumber}
                                          onChange={(e) => onChangeField('phoneNumber', e.target.value)} size="lg"
                                          type="number" placeholder="Номер телефона"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <div className='col-12'>
                                <div className="mb-2">
                                    <div onClick={() => setShowTypeSelect(true)} style={{
                                        color: `${vehicle.vehicleTypeId ? '#212529' : '#A3ACB6'}`,
                                        fontSize: '16px',
                                        padding: '12px 16px',
                                        marginTop: '16px',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.5rem'
                                    }}>
                                        {vehicle ? vehicle.vehicleTypeName : 'Выберите тип транспорта'}
                                    </div>
                                </div>
                            </div>
                            {renderUploadForm("Удостоверение личности - лицевая", idCardFront, setIdCardFront, "01", "idCardFront")}
                            {renderUploadForm("Удостоверение личности - обратная", idCardBack, setIdCardBack, "02", "idCardBack")}
                            {renderUploadForm("Автомобиль", carPhoto, setCarPhoto, "03", "carPhoto")}
                            {renderUploadForm("Тех. паспорт - лицевая", carCardFront, setCarCardFront, "04", "carCardFront")}
                            {renderUploadForm("Тех. паспорт - обратная", carCardBack, setCarCardBack, "05", "carCardBack")}
                            {renderUploadForm("Тех. паспорт прицепа - лицевая", trailerCardFront, setTrailerCardFront, "06", "trailerCardFront")}
                            {renderUploadForm("Тех. паспорт прицепа - обратная", trailerCardBack, setTrailerCardBack, "07", "trailerCardBack")}
                            <Form.Control value={driverInfo.iin}
                                          onChange={(event: any) => onChangeField('iin', event.target.value)}
                                          size="lg"
                                          type="text" placeholder="ИИН"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={driverInfo.idNumber}
                                          onChange={(e) => onChangeField('idNumber', e.target.value)} size="lg"
                                          type="text" placeholder="Номер удостоверения"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={driverInfo.issuedBy}
                                          onChange={(e) => onChangeField('issuedBy', e.target.value)} size="lg"
                                          type="text" placeholder="Кем выдан"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={driverInfo.issuedDate}
                                          onChange={(e) => onChangeField('issuedDate', e.target.value)}
                                          size="lg" type="date" placeholder="Когда выдан"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={driverInfo.carNumber}
                                          onChange={(e) => onChangeField('carNumber', e.target.value)} size="lg"
                                          type="text" placeholder="Номер авто"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={driverInfo.carModel}
                                          onChange={(e) => onChangeField('carModel', e.target.value)} size="lg"
                                          type="text" placeholder="Модель авто"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={driverInfo.trailerNumber}
                                          onChange={(e) => onChangeField('trailerNumber', e.target.value)}
                                          size="lg" type="text" placeholder="Гос номер прицепа"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                            <Form.Control value={driverInfo.trailerModel}
                                          onChange={(e) => onChangeField('trailerModel', e.target.value)}
                                          size="lg" type="text" placeholder="Марка, модель прицепа"
                                          style={{fontSize: '16px', padding: '12px 16px', marginTop: '16px'}}/>
                        </div>}
                        <div style={{marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={onClose} variant="light" style={{marginRight: '20px'}}>Отменить</Button>
                            <Button onClick={() => handleSend()} variant="primary">Сохранить</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            showTypeSelect &&
            <SelectModal
                onClose={() => setShowTypeSelect(false)}
                header="Укажите тип транспорта"
                options={vtypes}
                onSubmit={(item) => {
                    setDriverInfo({...driverInfo, vehicleTypeId: item.id});
                    setShowTypeSelect(false)
                }}
            />
        }
    </>;
}
export default DriverUpdateModal
